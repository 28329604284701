import axios from "axios";
import { URIs } from "../Common/Constants";
import { EmailModel } from "../Models/EmailModel";

import GenericAPIService from "./GenericAPIService";

const { SEND_EMAIL, GET_ALL_EMAIL } = URIs;
class EmailService extends GenericAPIService {
  async sendEmail(clientId: number, providerId: number, emailBody: EmailModel,formData:any) {
    const data = await axios.post(
      `https://connektivitycrm.genxalign.com/api/${SEND_EMAIL}?client_id=${clientId}&provider_id=${providerId}&recipient_email=${
        emailBody.recipient_email
      }&cc_email=${emailBody.cc_email ? emailBody.cc_email : ""}&subject=${
        emailBody.subject
      }&message_body=${emailBody.message_body}`,
      formData
    );

    //console.log("data",data)
    return data;
  }
  async getAllEmail(id: number) {
    const data: any = await this.getAsync(`${GET_ALL_EMAIL}?client_id=${id}`);
    //console.log("data",data)
    return data;
  }
}

export default EmailService;
