import { URIs } from "../Common/Constants";

import GenericAPIService from "./GenericAPIService";

const { GET_FUND_BY_CLIENT } = URIs;
class FundTrackingService extends GenericAPIService {
  async getFundByClientId(id: number,providerId: number) {
    const data: any = await this.getAsync(
      `${GET_FUND_BY_CLIENT}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
}

export default FundTrackingService;
