import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import ClientServices from "../../Services/ClientServices";

import {
  RosterStaffInfoModel,
  ClientCompletedRosterModel,
} from "../../Models/ClientCompletedRosterModel";
import LinkButton from "../../Components/Button/LinkButton";
import ModalDialog from "../../Components/Modal/ModelDialog";
import NoRecords from "../../Components/NoRecords/NoRecords";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import FeedbackModal from "./FeedbackModal";
import { FeedbackModel } from "../../Models/FeedbackModel";
const ClientService = new ClientServices();

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  clientId: number | null;
  tableData: any;
  page: number;
  rows: number;
  headCellData: any;
  isOpen: boolean;
  staffInfo: RosterStaffInfoModel;
  response: any;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  userInfo: any;
  isFeedback: boolean;
  shiftDate: string;
  comments: string;
  staffId: number;
}
const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
class ShiftPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      page: 0,
      rows: 5,
      clientId: null,
      tableData: [],
      headCellData: [],
      isOpen: false,
      staffInfo: {} as RosterStaffInfoModel,
      response: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      userInfo: {},
      isFeedback: false,
      shiftDate: "",
      comments: "",
      staffId: 0,
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var clientid = parseInt(match[1]);
        const allDataResponse = await ClientService.getAllclientRoster(
          clientid,
          JSON.parse(userInfo).provider_id
        );
        if (allDataResponse) {
          //console.log(allDataResponse.data);
          const data = allDataResponse.data
            .filter((val: any) => val.status === "complete")
            .map((el: ClientCompletedRosterModel) => {
              return {
                Id: el.roster_line_id,
                staffName: el.staff_name,
                shiftItemName: el.shift_item_name,
                startDate: new Date(el.start_date).toDateString(),
                startTime: el.start_time,
                daysOfWeek: el.day_of_week,
                endTime: el.end_time,
                instruction: el.instructions,
                status: el.status,
              };
            });
          this.setState({
            tableData: data,
            response: allDataResponse.data,
            clientId: clientid,
            userInfo: JSON.parse(userInfo),
          });
        }
      }
    }
  }

  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  handleChangePage = (_event: any, page: any) => {
    this.setState({ page: page });
    this.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rows: event.target.value });
    this.onChangeRow(event.target.value);
  };
  handleOpenClick = (index: number) => {
    const { response, isOpen } = this.state;

    const filterClientInfo = response[index];
    console.log(filterClientInfo);
    this.setState({ staffInfo: filterClientInfo }, () => {
      this.setState({ isOpen: true });
    });
  };
  onMarkCompleteClick = async (event: React.FormEvent, index: number) => {
    event.preventDefault();
    const { response, clientId, userInfo } = this.state;
    try {
      const filterInfo = response.find(
        (el: any) => el.roster_line_id === index
      );
      //console.log(filterInfo);
      const updateResponse = await ClientService.updateStaffRosterStatus(
        filterInfo.roster_id,
        filterInfo.roster_line_id,
        "approved",
        filterInfo.last_day
      );
      if (updateResponse) {
        const allDataResponse = await ClientService.getAllclientRoster(
          clientId,
          userInfo.provider_id
        );
        if (allDataResponse) {
          //console.log(allDataResponse.data);
          const data = allDataResponse.data
            .filter((val: any) => val.status === "complete")
            .map((el: ClientCompletedRosterModel) => {
              return {
                Id: el.roster_line_id,
                staffName: el.staff_name,
                shiftItemName: el.shift_item_name,
                startDate: new Date(el.start_date).toDateString(),
                startTime: el.start_time,
                daysOfWeek: el.day_of_week,
                endTime: el.end_time,
                instruction: el.instructions,
                status: el.status,
              };
            });

          this.setState({
            staffId: filterInfo.staff_id,
            shiftDate: new Date(filterInfo.start_date).toLocaleDateString('en-CA'),
            isFeedback: true,
            tableData: data,
            response: allDataResponse.data,
            openAlert: true,
            alertMsg: "Approved successfully",
            alertType: "success",
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  handleSubmitFeedback = async (feedback: any) => {
    const { userInfo, clientId, shiftDate, comments, staffId } = this.state;
    const feedbackObj: FeedbackModel = {
      client_id: clientId!,
      comment: comments,
      overall_rank:
        feedback === "happy"
          ? 1
          : feedback === "none"
          ? 2
          : feedback === "sad"
          ? 3
          : 0,
      provider_id: userInfo.provider_id,
      shift_date: shiftDate,
      staff_id: staffId,
    };
    //console.log(feedback);
    const response = await ClientService.createFeedback(feedbackObj);
    if (response) {
      this.setState({
        openAlert: true,
        alertMsg: "Feedback submitted successfully",
        alertType: "success",
      });
    }
  };
  onTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ comments: event.target.value });
  };
  render() {
    const {
      tableData,
      rows,
      page,
      isOpen,
      staffInfo,
      alertMsg,
      alertType,
      openAlert,
      isFeedback,
      comments,
    } = this.state;
    //console.log(headCellData, tableData[0]);
    const tableCellStyle: React.CSSProperties = {
      fontSize: "16px",
      borderBottom: "2px solid #0086ff",
      width: "15rem",
    };

    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <FeedbackModal
          comments={comments}
          onTextAreaInputChange={this.onTextAreaInputChange}
          open={isFeedback}
          onClose={() => this.setState({ isFeedback: false })}
          onSubmitFeedback={this.handleSubmitFeedback}
        />
        <ModalDialog
          isOpen={isOpen}
          dialogWidth="xs"
          title="View Staff Information"
          onClose={() => {
            this.setState({
              isOpen: false,
            });
          }}
        >
          <Grid item>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>Name:</span>
              {staffInfo.staff_name}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>Email:</span>
              {staffInfo.staff_email}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>Phone:</span>
              {staffInfo.staff_phone}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>
                Address:
              </span>
              {staffInfo.staff_address}
            </Typography>
          </Grid>
        </ModalDialog>

        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Typography
              marginBottom={1}
              style={{ fontSize: "1rem", fontWeight: 550 }}
            >
              Shift
            </Typography>
            <hr />
            {tableData.length > 0 ? (
              <Box mt={2}>
                <TableContainer component={Paper}>
                  <Table
                    stickyHeader
                    sx={{ minWidth: "90rem" }}
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid #0086ff",
                          width: "5rem",
                        }}
                      >
                        Id
                      </TableCell>
                      <TableCell style={tableCellStyle}>Staff Name</TableCell>
                      <TableCell style={tableCellStyle}>
                        Shift Item Name
                      </TableCell>
                      <TableCell style={tableCellStyle}>Days Of Week</TableCell>
                      <TableCell style={tableCellStyle}>Start Date</TableCell>
                      <TableCell style={tableCellStyle}>Start Time</TableCell>
                      <TableCell style={tableCellStyle}>End Time</TableCell>
                      {/* <TableCell style={tableCellStyle}>Instruction</TableCell> */}
                      <TableCell style={tableCellStyle}>Status</TableCell>
                      <TableCell style={tableCellStyle}>Action</TableCell>
                    </TableHead>

                    <TableBody>
                      {tableData.length > 0
                        ? (rows > 0
                            ? tableData.slice(page * rows, page * rows + rows)
                            : tableData
                          ).map((el: any, ind: number) => {
                            return (
                              <StyledTableRow key={ind}>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.Id}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                    wordWrap: "break-word",
                                    hyphens: "auto",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  <LinkButton
                                    label={el.staffName}
                                    style={{ textTransform: "none" }}
                                    onClick={() => this.handleOpenClick(ind)}
                                  />
                                </TableCell>

                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.shiftItemName}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.daysOfWeek}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.startDate}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.startTime}
                                </TableCell>

                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.endTime}
                                </TableCell>
                                {/* <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.instruction}
                                </TableCell> */}
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  {el.status}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    style={{ textTransform: "none" }}
                                    onClick={(event) =>
                                      this.onMarkCompleteClick(event, el.Id)
                                    }
                                  >
                                    Approve
                                  </Button>
                                </TableCell>
                              </StyledTableRow>
                            );
                          })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={tableData.length}
                  page={page}
                  showFirstButton
                  showLastButton
                  labelRowsPerPage={`${
                    tableData.length > rows ? rows : tableData.length
                  } of ${tableData.length} records per page`}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={rows}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Box>
            ) : (
              <Box style={{ marginTop: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(ShiftPage);
