import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Utils from "../../Common/Utils";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import DataLoader from "../../Components/Table/DataLoader";
import { NewRosterModel } from "../../Models/NewRosterModel";
import NewRosterService from "./../../Services/NewRosterService";
interface Props extends RouteComponentProps<any, any, any> {}
interface State {
  isDropDown: boolean;
  dropDownData: SelectModel[];
  label: string;
  page: number;
  rows: number;
  totalRecordsCount: number;
  isEdit: boolean;
  filterData: any;
  searchText: string;
  rosterData: NewRosterModel[];
  userInfo: any;
}

const RosterService = new NewRosterService();

class Roster extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDropDown: false,
      dropDownData: [],
      label: "",
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      isEdit: false,
      filterData: [],
      searchText: "",
      rosterData: [],
      userInfo: {},
    };
  }

  selectDataDropDown1: SelectModel[] = [
    {
      value: "Staff",
      text: "Staff",
    },
    {
      value: "Client",
      text: "Client",
    },
  ];
  componentDidMount() {
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      this.setState({ userInfo: JSON.parse(userInfo) });
    }
  }
  onDropDown1Change = async (selected: SelectModel, targetId: any) => {
    // console.log(targetId);
    // console.log(selected)
    if (selected.value === "Staff") {
      const response = await RosterService.getStaffDropDownValue(
        this.state.userInfo.provider_id
      );
      let d = response.data.map((el: any, i: any) => {
        return {
          text: el.staff_name + "_" + i,
          value: el.staff_name,
          id: el.staff_id,
        };
      });
      this.setState({
        isDropDown: true,
        dropDownData: d,
        label: "staff",
        rosterData: [],
        totalRecordsCount: d.length,
      });
    } else if (selected.value === "Client") {
      const response = await RosterService.getClientDropDownValue(1);
      console.log(response);
      let d = response.data.map((el: any, i: any) => {
        return {
          text: el.client_name + "_" + i,
          value: el.client_name,
          id: el.client_id,
        };
      });
      this.setState({
        isDropDown: true,
        dropDownData: d,
        label: "client",
        rosterData: [],
        totalRecordsCount: d.length,
      });
    }
  };

  onDropDown2Change = async (selected: SelectModel, targetId: any) => {
    const id: number = selected.id || 0;
    const isValidDate = Utils.isValidDate;

    if (targetId === "staff_id") {
      const response = await RosterService.getStaffData(
        id,
        this.state.userInfo.provider_id
      );
      console.log(response);
      const data = response.data.map((el: NewRosterModel) => {
        console.log(isValidDate(el.start_time));
        // console.log(moment(el.start_time));
        // console.log(moment(el.start_time).format("h:mm:ss a"));

        return {
          client_name: el.client_name,
          client_email: el.client_email,
          support_item_name: el.shift_item_name,
          start_date: el.start_date,
          // end_date:el.end_date,
          start_time: isValidDate(el.start_time)
            ? moment(el.start_time).format("h:mm:ss a")
            : el.start_time,
          end_time: isValidDate(el.end_time)
            ? moment(el.end_time).format("h:mm:ss a")
            : el.end_time,
          status: el.status,
        };
      });
      this.setState({
        rosterData: data,
      });
    } else if (targetId === "client_id") {
      const response = await RosterService.getClientData(
        id,
        this.state.userInfo.provider_id
      );
      console.log(response);
      const data = response.data.map((el: NewRosterModel) => {
        return {
          staff_name: el.staff_name,
          staff_email: el.staff_email,
          shift_item_name: el.shift_item_name,
          start_date: el.start_date,
          end_date: el.end_date,
          start_time: isValidDate(el.start_time)
            ? moment(el.start_time).format("h:mm:ss a")
            : el.start_time,
          end_time: isValidDate(el.end_time)
            ? moment(el.end_time).format("h:mm:ss a")
            : el.end_time,
          status: el.status,
        };
      });
      this.setState({
        rosterData: data,
      });
    }
  };
  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  render() {
    const {
      isDropDown,
      dropDownData,
      label,
      page,
      rows,
      totalRecordsCount,
      isEdit,
      filterData,
      rosterData,
      searchText,
    } = this.state;
    console.log(rosterData, "Roster page");

    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ display: "flex" }}>
          <Typography
            marginBottom={5}
            style={{ fontSize: "1.7rem", fontWeight: 550 }}
          >
            Roster
          </Typography>
        </Box>
        <Grid container marginBottom={4}>
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <SingleSelect
              // value={clientInfo.lead_status}
              id={label}
              size="small"
              values={this.selectDataDropDown1}
              onChange={this.onDropDown1Change}
            />
          </Grid>
          {isDropDown && (
            <Grid
              item
              xs={3}
              display="flex"
              justifyContent="flex-end"
              sx={{ marginLeft: "20px" }}
            >
              <SingleSelect
                // value={clientInfo.lead_status}
                id={`${label}_id`}
                size="small"
                values={dropDownData}
                onChange={this.onDropDown2Change}
              />
            </Grid>
          )}
        </Grid>
        <Box>
          <DataLoader
            totalRecordsCount={
              searchText ? filterData.length : totalRecordsCount
            }
            onChangePage={this.onChangePage}
            onChangeRow={this.onChangeRow}
            //   onActionClick={this.onActionClick}
            tableData={
              searchText
                ? rows > 0
                  ? filterData.slice(page * rows, page * rows + rows)
                  : filterData
                : rows > 0
                ? rosterData.slice(page * rows, page * rows + rows)
                : rosterData
            }
            //   onClickLink={this.onClickName}
            // actionArray={["Edit", "Delete"]}
          />
        </Box>
      </Box>
    );
  }
}

export default withRouter(Roster);
