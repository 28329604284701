import moment from "moment";
import Environment from "../config.json";
//import { decode } from 'jsonwebtoken';

class Utils {
  static isIE() {
    const isEdge = window.navigator.userAgent.indexOf("Edge") !== -1;
    const isIE = window.navigator.userAgent.indexOf("Trident") !== -1 && isEdge;
    return isIE;
  }
  static arraysAreIdentical(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) return false;
    let sortedArr1 = arr1.sort();
    let sortedArr2 = arr2.sort();
    for (let i = 0, len = sortedArr1.length; i < len; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }

  static getDaysBetweenDates(dateOne: Date, dateTwo: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      (dateTwo.getTime() - dateOne.getTime()) / oneDay
    );

    return diffDays;
  }

  static isEqualDates(dateOne?: Date | null, dateTwo?: Date | null): boolean {
    if (dateOne && dateTwo) {
      return (
        dateOne.getFullYear() === dateTwo.getFullYear() &&
        dateOne.getDate() === dateTwo.getDate() &&
        dateOne.getMonth() === dateTwo.getMonth()
      );
    }

    return false;
  }

  static getCurrentEnv(): any {
    if (process.env.APP_ENV) return process.env.APP_ENV;
    // Production
    if (
      window.location.hostname.includes("prod") ||
      window.location.hostname === "" ||
      window.location.hostname === ""
    ) {
      return "prod";
    }
    // Staging
    if (
      window.location.hostname.includes("qa") ||
      window.location.hostname === "" ||
      window.location.hostname === ""
    ) {
      return "qa";
    }
    // Develop
    return "dev";
  }

  static getEnvVars(): typeof Environment.Enviroment.dev {
    const env = Utils.getCurrentEnv();
    const envVariables: any = Object.assign({}, Environment);
    return envVariables[env];
  }

  static isInt(value: string): boolean {
    return !isNaN(parseInt(value));
  }

  static getQueryParam(
    querySearchString: string,
    param: string
  ): string | null {
    const query = new URLSearchParams(querySearchString);
    return query.get(param);
  }

  // static decodeJWT(token: string | null) {
  //   return token ? decode(token) : null;
  // }

  static getEnumKeyByEnumValue(
    myEnum: any,
    enumValue: number | string
  ): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : "";
  }

  static paginate<T>(array: T[], pageSize: number, pageNumber: number): T[] {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  static getFormattedDateTime(dateTime: Date | string | null | undefined) {
    if (dateTime) {
      try {
        return moment(dateTime).format("MM-DD-YYYY h:mm a");
      } catch (error) {
        console.error(error);
      }
    }

    return "";
  }

  static getDate(dateTime: Date | string | null | undefined, format: string) {
    if (dateTime) {
      try {
        return moment(dateTime).format(format);
      } catch (error) {
        console.error(error);
      }
    }

    return "";
  }

  static openUrlParent(url?: string | null) {
    if (url) {
      window.open(url, "_parent");
    }
  }

  static camelToTitle(string: string) {
    return string.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  // static encrypt(key:string, password:any){
  //   const enc = [];
  //   const encoder = new TextEncoder();
  
  //   for (let i = 0; i < password.length; i++) {
  //     const keyChar = key[i % key.length];
  //     const passwordChar = encoder.encode(password[i]);
  //     const keyCharEncoded = encoder.encode(keyChar);
  //     const encryptedChar = (passwordChar[0] + keyCharEncoded[0]) % 256;
  //     const encryptedCharString = String.fromCharCode(encryptedChar);
  //     enc.push(encryptedCharString);
  //   }
  
  //   const encryptedData = enc.join('');
  //   const base64EncodedData = btoa(encryptedData);
  //   return base64EncodedData;
  // }
  static encrypt(key:any, clear:any) {
    let enc = [];
    console.log(key);
    for (let i = 0; i < clear.length; i++) {
      let key_c = key[i % key.length];
      let enc_c = String.fromCharCode((clear.charCodeAt(i) + key_c.charCodeAt(0)) % 256);
      enc.push(enc_c);
    }
    
    let encodedString = btoa(enc.join(""));
    return encodedString;
  }

   static isValidDate(d:any) {
    if(!isNaN(new Date(d).getTime())){
        return new Date(d);

    }else{
        return false;
    }
  }
}

export default Utils;
