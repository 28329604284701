import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import { CaseNotesModel } from "../../Models/CaseNotesModel";
import CaseNotesService from "../../Services/CaseNotesService";
import DataLoader from "../../Components/Table/DataLoader";

const CaseNotesServices = new CaseNotesService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  clientId: string | null;
  CaseNotesInfo: CaseNotesModel;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  userInfo: any;
}

class CaseNotes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      clientId: "",
      CaseNotesInfo: {} as CaseNotesModel,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      userInfo: {},
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        this.setState({
          CaseNotesInfo: {
            ...this.state.CaseNotesInfo,
            client_id: clientId,
            created_by: JSON.parse(userInfo).staff_name
            ? JSON.parse(userInfo).staff_name
            : JSON.parse(userInfo).provider_name,
            staff_id: 1,
            provider_id:JSON.parse(userInfo).provider_id
          },
          clientId: clientId.toString(),
          userInfo: JSON.parse(userInfo),
        });

        const response = await CaseNotesServices.getAllCaseNotes(
          clientId,
          JSON.parse(userInfo).provider_id
        );
        const data = response.data.map((el: CaseNotesModel) => {
          return {
            Id: el.client_casenotes_id,
            CaseNotes: el.client_notes,
            CreatedBy: el.created_by,
            CreatedOn: new Date(
              el.created_on ? el.created_on : new Date()
            ).toDateString(),
          };
        });
        this.setState({
          tableData: data,
          totalRecordsCount: data.length,
        });
      }
    }
  }

  onTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      CaseNotesInfo: {
        ...this.state.CaseNotesInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  onCaseNotesSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { CaseNotesInfo, clientId, userInfo } = this.state;

      const response = await CaseNotesServices.createCaseNotes(CaseNotesInfo);
      if (response) {
        const response = await CaseNotesServices.getAllCaseNotes(
          Number(clientId),
          userInfo.provider_id
        );
        const data = response.data.map((el: CaseNotesModel) => {
          return {
            Id: el.client_casenotes_id,
            CaseNotes: el.client_notes,
            CreatedBy: el.created_by,
            CreatedOn: new Date(
              el.created_on ? el.created_on : new Date()
            ).toDateString(),
          };
        });

        this.setState({
          tableData: data,
          totalRecordsCount: data.length,
          openAlert: true,
          alertMsg: "Case Notes Created Successfully",
          alertType: "success",
        });
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onCancelClick = () => {
    this.setState({
      CaseNotesInfo: { ...this.state.CaseNotesInfo, client_notes: "" },
    });
  };

  render() {
    const {
      CaseNotesInfo,
      isEdit,
      openAlert,
      alertMsg,
      alertType,
      page,
      rows,
      tableData,
      totalRecordsCount,
    } = this.state;
    //console.log(this.state.NDISInfo.ndisplan);
    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Typography
              marginBottom={1}
              style={{ fontSize: "1rem", fontWeight: 550 }}
            >
              Case Notes
            </Typography>
            <hr />
            <form onSubmit={this.onCaseNotesSave}>
              <Grid container marginTop={5}>
                <TextAreaInputField
                  minRows={4}
                  isRequired={false}
                  id="client_notes"
                  value={CaseNotesInfo.client_notes}
                  onChange={this.onTextAreaInputChange}
                  placeholder="Type Here..."
                />
              </Grid>
              <Grid container item marginTop={5} justifyContent="flex-start">
                {isEdit ? (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                )}
                <Button
                  onClick={this.onCancelClick}
                  style={{ textTransform: "capitalize", marginLeft: 7 }}
                  variant="outlined"
                >
                  Clear
                </Button>
              </Grid>
            </form>
            <Box marginTop={2}>
              <DataLoader
                totalRecordsCount={totalRecordsCount}
                onChangePage={this.onChangePage}
                onChangeRow={this.onChangeRow}
                tableData={
                  rows > 0
                    ? tableData.slice(page * rows, page * rows + rows)
                    : tableData
                }
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(CaseNotes);
