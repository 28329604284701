import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OauthAzure = () => {
  const [authCode, setAuthCode] = useState(null);

  useEffect(() => {
    // Handle redirect from Microsoft login
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setAuthCode(code);
    }
  }, []);

  const handleLogin = () => {
    window.location.href = 'https://localhost:5000/oauth-azure';
  };

  const handleFetchEmails = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const accessCode = urlParams.get('code');
      const response = await axios.get(`https://localhost:5000/fetch-emails?code=${accessCode}`); // Replace with your Flask API endpoint
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching emails:', error);
    }
  };

  return (
    <div>
      <h1>Microsoft OAuth Example</h1>
      {!authCode ? (
        <button onClick={handleLogin}>Login with Microsoft</button>
      ) : (
        <>
          <p>Authenticated!</p>
          <button onClick={handleFetchEmails}>Fetch Emails</button>
        </>
      )}
    </div>
  );
};

export default OauthAzure;
