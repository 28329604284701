import { URIs } from "../Common/Constants";
import GenericAPIService from "./GenericAPIService";

const { CALENDAR_CLIENT, CALENDAR_STAFF } = URIs;

class CalendarService extends GenericAPIService {
  async getClientCalendar(client_id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${CALENDAR_CLIENT}?client_id=${client_id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  async getStaffCalendar(staff_id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${CALENDAR_STAFF}?staff_id=${staff_id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
}

export default CalendarService;
