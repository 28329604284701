import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IconType } from "../../Components/Button/IconButton";

import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import DataLoader from "../../Components/Table/DataLoader";

import DatePicker from "../../Components/DatePicker/DatePicker";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import TimePickerComponent from "../../Components/TimePicker/TimePicker";
import { RosterModel } from "../../Models/RosterModel";
import { ServiceAgreementModel } from "../../Models/ServiceAgreementModel";
import AgreementService from "../../Services/AgreementService";
import ClientServices from "../../Services/ClientServices";
import RosterService from "../../Services/RosterService";
import StaffServices from "../../Services/StaffService";
import { StaffModel } from "../../Models/StaffModel";
const ClientService = new ClientServices();
const StaffService = new StaffServices();
const agreement = new AgreementService();
const rosterService = new RosterService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  showEdit: boolean;
  isAddRoster: boolean;
  recordMarked: any;
  searchText: string;
  rosterInfo: RosterModel;
  countryData: SelectModel[];
  stateData: SelectModel[];
  cityData: SelectModel[];
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isEdit: boolean;
  staffData: SelectModel[];
  shiftTypeData: SelectModel[];
  shiftItemNameData: SelectModel[];
  clientAgreement: ServiceAgreementModel[];
  filterAgreementData: ServiceAgreementModel[];
  staffInfo: StaffModel;
  viewStaffInfo: boolean;
  staffId: number;
  userInfo: any;

}

class ContactData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      showEdit: false,
      isAddRoster: false,
      recordMarked: {},
      searchText: "",
      rosterInfo: {} as RosterModel,
      countryData: [],
      stateData: [],
      cityData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isEdit: false,
      staffData: [],
      shiftTypeData: [],
      shiftItemNameData: [],
      clientAgreement: [],
      filterAgreementData: [],
      staffInfo: {} as StaffModel,
      viewStaffInfo: false,
      staffId: 0,
      userInfo: {},
     
    };
  }

  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");
      if (match && userInfo) {
        var number = parseInt(match[1]);
        const response = await rosterService.getRosterByClient(number,JSON.parse(userInfo).provider_id);

        const data = response.data.map((el: RosterModel) => {
          return {
            Id: el.roster_id,
            name: el.staff_name,
            start_date: el.start_date,
            start_time:
              el.start_time.split(" ")[1] + el.start_time.split(" ")[2],
            end_date: el.end_date,
            end_time: el.end_time.split(" ")[1] + el.end_time.split(" ")[2],
            email: el.staff_email,
          };
        });
        //console.log(data);
        this.setState({
          tableData: data,
          totalRecordsCount: data.length,
        });
        const clientData = await ClientService.getSelectedClient(number, JSON.parse(userInfo).provider_id);
        this.setState({
          rosterInfo: {
            ...this.state.rosterInfo,
            client_id: number,
            client_address: clientData.data.client_address,
            client_email: clientData.data.client_email,
            client_name: clientData.data.client_name,
            provider_id:JSON.parse(userInfo).provider_id
          },
          
        });
        const agreementData = await agreement.getServiceAgreement(number,JSON.parse(userInfo).provider_id);
        if (agreementData.data.length > 0) {
          this.setState({
            clientAgreement: agreementData.data,
            shiftTypeData: agreementData.data.map((el: any) => {
              return {
                text: el.registration_group_name,
                value: el.registration_group_name,
              };
            }),
            shiftItemNameData: agreementData.data.map((el: any) => {
              return {
                id: el.srvagreementline_id,
                text: el.support_item_name,
                value: el.support_item_name,
              };
            }),
          });
        }
      }
      //const searchParams = new URLSearchParams(this.props.location.search);
      // if (searchParams.get("client_id")) {
      //   const response = await rosterService.getRosterByClient(
      //     Number(searchParams.get("client_id"))
      //   );

      //   const data = response.data.map((el: RosterModel) => {
      //     return {
      //       Id: el.roster_id,
      //       name: el.staff_name,
      //       start_date: el.start_date,
      //       start_time:
      //         el.start_time.split(" ")[1] + el.start_time.split(" ")[2],
      //       end_date: el.end_date,
      //       end_time: el.end_time.split(" ")[1] + el.end_time.split(" ")[2],
      //       email: el.staff_email,
      //     };
      //   });
      //   //console.log(data);
      //   this.setState({
      //     tableData: data,
      //     totalRecordsCount: data.length,
      //   });
      //   const clientData = await ClientService.getSelectedClient(
      //     Number(searchParams.get("client_id"))
      //   );
      //   this.setState({
      //     rosterInfo: {
      //       ...this.state.rosterInfo,
      //       client_id: Number(searchParams.get("client_id")),
      //       client_address: clientData.data.client_address,
      //       client_email: clientData.data.client_email,
      //       client_name: clientData.data.client_name,
      //     },
      //   });

      //   const agreementData = await agreement.getServiceAgreement(
      //     Number(searchParams.get("client_id"))
      //   );
      //   if (agreementData.data.length > 0) {
      //     this.setState({
      //       clientAgreement: agreementData.data,
      //       shiftTypeData: agreementData.data.map((el: any) => {
      //         return {
      //           text: el.registration_group_name,
      //           value: el.registration_group_name,
      //         };
      //       }),
      //       shiftItemNameData: agreementData.data.map((el: any) => {
      //         return {
      //           id: el.srvagreementline_id,
      //           text: el.support_item_name,
      //           value: el.support_item_name,
      //         };
      //       }),
      //     });
      //   }
      // }
    }
    const loginInfo = sessionStorage.getItem("userInfo");
    if (loginInfo) {
      const getAllStaff = await StaffService.getAllStaffs(
        JSON.parse(loginInfo).provider_id
      );

      this.setState({
        staffData: getAllStaff.data
          .filter((el: any) => el.Role === "Carer")
          .map((val: any) => {
            return {
              id: val.Id,
              text: val.Email,
              value: val.Name,
            };
          }),
        userInfo: JSON.parse(loginInfo),
      });
    }
  }

  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        const response = await rosterService.getRosterById(record.Id,this.state.userInfo.provider_id);
        const filterData = this.state.clientAgreement.filter(
          (el: any) =>
            el.srvagreementline_id === response.data.srvagreementline_id
        );
        this.setState({
          filterAgreementData: filterData,
          rosterInfo: response.data,
          isAddRoster: true,
          isEdit: true,
        });
        break;
      case "Delete":
        const deleteResponse = await rosterService.deleteRoster(record.Id,this.state.userInfo.provider_id);
        if (deleteResponse) {
          const updatedTableData = await rosterService.getRosterByClient(
            this.state.rosterInfo.client_id,this.state.userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: RosterModel) => {
            return {
              Id: el.roster_id,
              name: el.staff_name,
              start_date: el.start_date,
              start_time:
                el.start_time.split(" ")[1] + el.start_time.split(" ")[2],
              end_date: el.end_date,
              end_time: el.end_time.split(" ")[1] + el.end_time.split(" ")[2],
              email: el.staff_email,
            };
          });
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Roster Deleted Successfully",
            alertType: "success",
          });
        }
        break;
      case "Clone":
        break;
    }
  };

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      rosterInfo: {
        ...this.state.rosterInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      rosterInfo: {
        ...this.state.rosterInfo,
        [event.target.id]: event.target.value,
      },
    });
  };

  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    //console.log(targetId);
    if (targetId) {
      this.setState({
        rosterInfo: {
          ...this.state.rosterInfo,
          [targetId]: selected.value,
        },
      });
    }
    if (targetId === "shift_item_name" && selected.id) {
      const filterData = this.state.clientAgreement.filter(
        (el: any) => el.srvagreementline_id === selected.id
      );

      this.setState({
        filterAgreementData: filterData,
        rosterInfo: {
          ...this.state.rosterInfo,
          srvagreementline_id: selected.id,
          shift_item_name: selected.value,
          start_date: filterData[0].start_date,
          start_time: filterData[0].start_time,
          end_date: filterData[0].end_date,
          end_time: filterData[0].end_time,
        },
      });
    }
    if (targetId === "staff_name" && selected.id) {
      this.setState({
        rosterInfo: {
          ...this.state.rosterInfo,
          staff_id: selected.id,
          staff_name: selected.value,
          staff_email: selected.text,
          shift_type: "",
          status: "assigned",
        },
      });
    }
  };

  getState = async (country: string) => {
    const response = await ClientService.getState(country);
    this.setState({
      stateData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };
  getCity = async (state: string, country: string) => {
    const response = await ClientService.getCity(state, country);
    this.setState({
      cityData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };

  onStartDateChange = (value: Date | null) => {
    const { rosterInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        rosterInfo: {
          ...rosterInfo,
          start_date: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };

  onStartTimeChange = (value: any | null) => {
    const { rosterInfo } = this.state;
    if (value) {
      this.setState({
        rosterInfo: {
          ...rosterInfo,
          start_time: new Date(value).toLocaleString(),
        },
      });
    }
  };

  onEndDateChange = (value: Date | null) => {
    const { rosterInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        rosterInfo: {
          ...rosterInfo,
          end_date: value.toLocaleDateString("en-CA"),
        },
      });
    }
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
  };

  onEndTimeChange = (value: any | null) => {
    const { rosterInfo } = this.state;
    //console.log(value?.toLocaleTimeString());
    if (value) {
      this.setState({
        rosterInfo: {
          ...rosterInfo,
          end_time: new Date(value).toLocaleString(),
        },
      });
    }
  };

  onRosterSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { rosterInfo, isEdit,userInfo } = this.state;

      if (isEdit) {
        const updatedResponse = await rosterService.updateRoster({...rosterInfo,provider_id:userInfo.provider_id});
        if (updatedResponse) {
          const updatedTableData = await rosterService.getRosterByClient(
            rosterInfo.client_id,this.state.userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: RosterModel) => {
            return {
              Id: el.roster_id,
              name: el.staff_name,
              start_date: el.start_date,
              start_time:
                el.start_time.split(" ")[1] + el.start_time.split(" ")[2],
              end_date: el.end_date,
              end_time: el.end_time.split(" ")[1] + el.end_time.split(" ")[2],
              email: el.staff_email,
            };
          });
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Roster Updated Successfully",
            alertType: "success",
            isAddRoster: false,
            filterAgreementData: [],
            rosterInfo: {
              ...this.state.rosterInfo,
              shift_item_name: "",
              instructions: "",
              staff_name: "",
              start_date: "",
              start_time: "",
              end_date: "",
              end_time: "",
            },
          });
        }
      } else {
        const response = await rosterService.createRoster({...rosterInfo,provider_id:userInfo.provider_id});
        if (response) {
          const response = await rosterService.getRosterByClient(
            rosterInfo.client_id,this.state.userInfo.provider_id
          );
          const data = response.data.map((el: RosterModel) => {
            return {
              Id: el.roster_id,
              name: el.staff_name,
              start_date: el.start_date,
              start_time:
                el.start_time.split(" ")[1] + el.start_time.split(" ")[2],
              end_date: el.end_date,
              end_time: el.end_time.split(" ")[1] + el.end_time.split(" ")[2],
              email: el.staff_email,
            };
          });
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Roster Created Successfully",
            alertType: "success",
            isAddRoster: false,
            filterAgreementData: [],
            rosterInfo: {
              ...this.state.rosterInfo,
              shift_item_name: "",
              instructions: "",
              staff_name: "",
              start_date: "",
              start_time: "",
              end_date: "",
              end_time: "",
            },
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  handleCancelClick = (event: React.FormEvent) => {
    event.preventDefault();
    this.setState({
      filterAgreementData: [],
      rosterInfo: {
        ...this.state.rosterInfo,
        shift_item_name: "",
        instructions: "",
        staff_name: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
      },
      isAddRoster: false,
    });
  };
  onClickName = async (_fieldClicked: string, record: any) => {
    const { staffData, userInfo } = this.state;
    const staffRecord = staffData.filter((el) => el.text === record.email);
    if (staffRecord[0].id) {
      const response = await StaffService.getSelectedStaff(
        staffRecord[0].id,
        userInfo.provider_id
      );
      this.setState({
        staffInfo: response.data,
        viewStaffInfo: true,
      });
    }
  };
  render() {
    const {
      tableData,
      totalRecordsCount,
      rows,
      page,
      isAddRoster,
      rosterInfo,
      openAlert,
      alertMsg,
      isEdit,
      alertType,
      staffData,
      filterAgreementData,
      shiftItemNameData,
      staffInfo,
      viewStaffInfo,
    } = this.state;
    //console.log(filterAgreementData);
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <ModalDialog
          isOpen={viewStaffInfo}
          dialogWidth="xs"
          title="View Staff Information"
          onClose={() => {
            this.setState({
              viewStaffInfo: false,
            });
          }}
        >
          <Grid item>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>Name:</span>
              {staffInfo.Name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>Email:</span>
              {staffInfo.Email}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>Phone:</span>
              {staffInfo.Phone}
            </Typography>
          </Grid>
        </ModalDialog>
        <ModalDialog
          isOpen={isAddRoster}
          dialogWidth="sm"
          title={isEdit ? "Edit Roster" : "Add New Roster"}
          onClose={() => {
            this.setState({
              isAddRoster: false,
              isEdit: false,
              rosterInfo: {} as RosterModel,
            });
          }}
        >
          <form onSubmit={this.onRosterSave}>
            <Grid container item flexDirection={"column"}>
              <Grid container item rowSpacing={3}>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={12}>
                    <FormLabel sx={{ fontSize: "1em" }}>
                      Attach Employee
                    </FormLabel>
                    <SingleSelect
                      isRequired={true}
                      value={rosterInfo.staff_name}
                      id="staff_name"
                      size="small"
                      values={staffData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={12}>
                    <FormLabel sx={{ fontSize: "1em" }}>
                      Shift Item name
                    </FormLabel>
                    <SingleSelect
                      isRequired={true}
                      value={rosterInfo.shift_item_name}
                      id="shift_item_name"
                      size="small"
                      values={shiftItemNameData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                {filterAgreementData.map((el: ServiceAgreementModel) => {
                  return (
                    <>
                      <Grid container item columnSpacing={7}>
                        {el.mon_flag ? (
                          <Grid item xs={4}>
                            <Grid>
                              <FormLabel sx={{ fontSize: "1em" }}>
                                Monday
                              </FormLabel>
                            </Grid>
                            <Grid>
                              <Typography style={{ fontSize: "0.9em" }}>
                                {el.mon_hrs} Hours
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                        {el.Tue_flag ? (
                          <Grid item xs={4}>
                            <Grid>
                              <FormLabel sx={{ fontSize: "1em" }}>
                                Tuesday
                              </FormLabel>
                            </Grid>
                            <Grid>
                              <Typography style={{ fontSize: "0.9em" }}>
                                {el.Tue_hrs} Hours
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                        {el.wed_flag ? (
                          <Grid item xs={4}>
                            <Grid>
                              <FormLabel sx={{ fontSize: "1em" }}>
                                Wednesday
                              </FormLabel>
                            </Grid>
                            <Grid>
                              <Typography style={{ fontSize: "0.9em" }}>
                                {el.wed_hrs} Hours
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                        {el.thrus_flag ? (
                          <Grid item xs={4}>
                            <Grid>
                              <FormLabel sx={{ fontSize: "1em" }}>
                                Thursday
                              </FormLabel>
                            </Grid>
                            <Grid>
                              <Typography style={{ fontSize: "0.9em" }}>
                                {el.thrus_hrs} Hours
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                        {el.fri_flag ? (
                          <Grid item xs={4}>
                            <Grid>
                              <FormLabel sx={{ fontSize: "1em" }}>
                                Friday
                              </FormLabel>
                            </Grid>
                            <Grid>
                              <Typography style={{ fontSize: "0.9em" }}>
                                {el.fri_hrs} Hours
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}

                        {el.sat_flag ? (
                          <Grid item xs={4}>
                            <Grid>
                              <FormLabel sx={{ fontSize: "1em" }}>
                                Saturday
                              </FormLabel>
                            </Grid>
                            <Grid>
                              <Typography style={{ fontSize: "0.9em" }}>
                                {el.sat_hrs} Hours
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                        {el.sun_flag ? (
                          <Grid item xs={4}>
                            <Grid>
                              <FormLabel sx={{ fontSize: "1em" }}>
                                Sunday
                              </FormLabel>
                            </Grid>
                            <Grid>
                              <Typography style={{ fontSize: "0.9em" }}>
                                {el.sun_hrs} Hours
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                    </>
                  );
                })}
                <Grid container item columnSpacing={7}>
                  <Grid item xs={6}>
                    <Grid>
                      <FormLabel sx={{ fontSize: "1em" }}>Start Date</FormLabel>
                    </Grid>
                    <Grid>
                      <DatePicker
                        value={new Date(rosterInfo.start_date)}
                        onChange={(value) => this.onStartDateChange(value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid>
                      <FormLabel sx={{ fontSize: "1em" }}>Start Time</FormLabel>
                    </Grid>
                    <Grid>
                      <TimePickerComponent
                        value={new Date(rosterInfo.start_time)}
                        onChange={(value) => this.onStartTimeChange(value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={6}>
                    <Grid>
                      <FormLabel sx={{ fontSize: "1em" }}>End Date</FormLabel>
                    </Grid>
                    <Grid>
                      <DatePicker
                        value={new Date(rosterInfo.end_date)}
                        onChange={(value) => this.onEndDateChange(value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid>
                      <FormLabel sx={{ fontSize: "1em" }}>End Time</FormLabel>
                    </Grid>
                    <Grid>
                      <TimePickerComponent
                        value={new Date(rosterInfo.end_time)}
                        onChange={(value) => this.onEndTimeChange(value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={12}>
                    <FormLabel sx={{ fontSize: "1em" }}>Instructions</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextAreaInputField
                      isRequired={true}
                      id="instructions"
                      value={rosterInfo.instructions}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter Instructions"
                    />
                  </Grid>
                </Grid>

                <Grid container item justifyContent="flex-end">
                  {isEdit ? (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    onClick={this.handleCancelClick}
                    style={{ textTransform: "capitalize", marginLeft: 5 }}
                    variant="outlined"
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container>
              <Grid item xs={9}>
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  Roster
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                <Button
                  style={{ textTransform: "capitalize", marginTop: 15 }}
                  variant="contained"
                  onClick={() => {
                    this.setState({ isAddRoster: true });
                  }}
                >
                  + Add New Roster
                </Button>
              </Grid>
            </Grid>
            <hr />

            <Box>
              <DataLoader
                totalRecordsCount={totalRecordsCount}
                onChangePage={this.onChangePage}
                onChangeRow={this.onChangeRow}
                onActionClick={this.onActionClick}
                onClickLink={this.onClickName}
                tableData={
                  rows > 0
                    ? tableData.slice(page * rows, page * rows + rows)
                    : tableData
                }
                actionArray={["Edit", "Delete"]}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(ContactData);
