import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  TableContainer,
  Typography
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import StaffService from "../../Services/StaffService";

import moment from "moment";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import TextInputField from "../../Components/TextInputField/TextInputField";
import {
  ClientDetailsModel,
  TimeSheetModel,
  WeekDatesModel,
} from "../../Models/StaffTimesheetModel";
const StaffServices = new StaffService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  staffId: number;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  week: string;
  weekData: WeekDatesModel;
  clientDetails: ClientDetailsModel[];
  timesheetInfo: TimeSheetModel[];
  userInfo: any;
}

class TimeSheet extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      staffId: 0,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      week: "",
      weekData: {} as WeekDatesModel,
      clientDetails: [],
      timesheetInfo: [],
      userInfo: {},
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      //console.log(match);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var staffId = parseInt(match[1]);

        const weekDatesResponse = await StaffServices.getWeek("This Week");

        this.setState(
          {
            week: "This Week",
            weekData: weekDatesResponse.data,
            userInfo: JSON.parse(userInfo),
          },
          () => {
            this.getAllTimeSheet(staffId);
          }
        );
      }
    }
  }
  getAllTimeSheet = async (staffId: number) => {
    const { weekData, userInfo } = this.state;
    const clientDetailsResponse = await StaffServices.getClientsForStaff(
      staffId,
      userInfo.provider_id
    );
    const newData: TimeSheetModel[] = clientDetailsResponse.data.map(
      (el: ClientDetailsModel) => {
        return {
          staff_id: staffId,
          client_id: el.client_id,
          provider_id: el.provider_id,
          client_name: el.client_name,
          mon_date: moment(weekData.mon_date).format("YYYY-MM-DD"),
          mon_hrs: 0,
          tue_date: moment(weekData.tue_date).format("YYYY-MM-DD"),
          tue_hrs: 0,
          wed_date: moment(weekData.wed_date).format("YYYY-MM-DD"),
          wed_hrs: 0,
          thur_date: moment(weekData.thurs_date).format("YYYY-MM-DD"),
          thur_hrs: 0,
          fri_date: moment(weekData.fri_date).format("YYYY-MM-DD"),
          fri_hrs: 0,
          sat_date: moment(weekData.sat_date).format("YYYY-MM-DD"),
          sat_hrs: 0,
          sun_date: moment(weekData.sun_date).format("YYYY-MM-DD"),
          sun_hrs: 0,
          week_ending: moment(weekData.weekending_date).format("YYYY-MM-DD"),
          total_hrs: 0,
          status: "",
          comment: "",
        };
      }
    );
    console.log(moment(weekData.mon_date).format("YYYY-DD-MM"));

    const getTimesheetData = await StaffServices.getAllTimesheet(
      staffId,
      userInfo.provider_id
    );

    if (getTimesheetData.data.length > 0) {
      const newArray = newData.filter(
        (item) =>
          !getTimesheetData.data.some(
            (obj: any) => obj.client_id === item.client_id
          )
      );

      const mergedArray = [...newArray, ...getTimesheetData.data];
      this.setState({
        timesheetInfo: mergedArray,
        clientDetails: clientDetailsResponse.data,
        staffId,
      });
    } else {
      this.setState({
        timesheetInfo: newData,
        clientDetails: clientDetailsResponse.data,
        staffId,
      });
    }
  };

  onDropDownChange = async (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      const weekDatesResponse = await StaffServices.getWeek(selected.value);

      this.setState(
        { week: selected.value, weekData: weekDatesResponse.data },
        () => {
          this.getAllTimeSheet(this.state.staffId);
        }
      );
    }
  };

  onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { timesheetInfo: timesheetField } = this.state;
    console.log(event.target.id.includes("_hrs"));

    this.setState({
      timesheetInfo: timesheetField.map((el: TimeSheetModel, ind) => {
        if (ind === index) {
          const daysOfWeek = [
            "mon_hrs",
            "tue_hrs",
            "wed_hrs",
            "thur_hrs",
            "fri_hrs",
            "sat_hrs",
            "sun_hrs",
          ];
          let totalHoursData = 0;
          daysOfWeek.forEach((day) => {
            if (event.target.id.includes(day)) {
              totalHoursData += Number(event.target.value);
            }
          });
          let hoursData =
            Number(el.mon_hrs) +
            Number(el.tue_hrs) +
            Number(el.wed_hrs) +
            Number(el.thur_hrs) +
            Number(el.fri_hrs) +
            Number(el.sat_hrs) +
            Number(el.sun_hrs) +
            totalHoursData;
          return {
            ...el,
            [event.target.id]: Number(event.target.value),
            total_hrs: hoursData,
          };
        }

        return el;
      }),
    });
  };
  onTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { timesheetInfo: timesheetField } = this.state;
    this.setState({
      timesheetInfo: timesheetField.map((el: TimeSheetModel, ind) => {
        if (ind === index) {
          return {
            ...el,
            [event.target.id]: event.target.value,
          };
        }
        return el;
      }),
    });
  };

  onSubmit = async (event: React.FormEvent, index: number) => {
    event.preventDefault();
    const { timesheetInfo } = this.state;
    try {
      const valueArr: any = [];
      timesheetInfo.map((el: TimeSheetModel, ind) => {
        if (ind === index) {
          valueArr.push(el);
        }
        return el;
      });
      if (!valueArr[0].timesheet_id) {
        const response = await StaffServices.createTimesheet({
          ...valueArr[0],
          status: "submitted",
        });
        if (response) {
          this.getAllTimeSheet(this.state.staffId);
          this.setState({
            openAlert: true,
            alertMsg: "Timesheet Added Successfully",
            alertType: "success",
          });
        }
      } else {
        const response = await StaffServices.updateTimesheet({
          ...valueArr[0],
          status: "updated",
        });
        if (response) {
          this.getAllTimeSheet(this.state.staffId);
          this.setState({
            openAlert: true,
            alertMsg: "Timesheet updated Successfully",
            alertType: "success",
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  render() {
    const { openAlert, alertMsg, alertType, weekData, week, timesheetInfo } =
      this.state;
    console.log(this.state.timesheetInfo);
    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />

        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container>
              <Grid item xs={2}>
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  TimeSheet
                </Typography>
              </Grid>
              <Grid item xs={8} display="flex" justifyContent="center">
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  Week Ending{" "}
                  {new Date(weekData.weekending_date).toDateString()}
                </Typography>
              </Grid>
              <Grid item xs={2} justifyContent="flex-end">
                <SingleSelect
                  value={week}
                  id="week"
                  size="small"
                  values={[
                    { text: "This Week", value: "This Week" },
                    { text: "Previous Week", value: "Previous Week" },
                  ]}
                  onChange={this.onDropDownChange}
                />
              </Grid>
            </Grid>

            <hr />
            <TableContainer component={Paper}>
             
                <Grid
                  container
                  item
                  flexDirection={"column"}
                  marginTop={3}
                  paddingLeft={2}
                  paddingRight={2}
                >
                  <Grid container item columnSpacing={5}>
                    <Grid item xs={1.5}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        Client Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        {new Date(weekData.mon_date).toDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        {new Date(weekData.tue_date).toDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        {new Date(weekData.wed_date).toDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        {new Date(weekData.thurs_date).toDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        {new Date(weekData.fri_date).toDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        {new Date(weekData.sat_date).toDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        {new Date(weekData.sun_date).toDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        Total Hours
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        Comment
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "0.95rem", fontWeight: 550 }}
                      >
                        Action
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <hr />
                {timesheetInfo.map((el: TimeSheetModel, index: number) => {
                  return (
                    <Grid
                      container
                      item
                      flexDirection={"column"}
                      paddingLeft={2}
                      paddingRight={2}
                      key={index}
                    >
                      <Grid container item mt={5} columnSpacing={5}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{ fontSize: "1rem", fontWeight: 400 }}
                          >
                            {el.client_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="mon_hrs"
                            value={el.mon_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="tue_hrs"
                            value={el.tue_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="wed_hrs"
                            value={el.wed_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="thur_hrs"
                            value={el.thur_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="fri_hrs"
                            value={el.fri_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="sat_hrs"
                            value={el.sat_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="sun_hrs"
                            value={el.sun_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextInputField
                            id="total_hrs"
                            value={el.total_hrs}
                            onChange={(event: any) =>
                              this.onInputChange(event, index)
                            }
                            placeholder="hrs"
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <TextAreaInputField
                            minRows={1}
                            isRequired={false}
                            id="comment"
                            value={el.comment}
                            onChange={(event: any) =>
                              this.onTextInputChange(event, index)
                            }
                            placeholder="comment"
                          />
                        </Grid>
                        {el.status === "completed" ? null : (
                          <Grid item xs={1}>
                            <Button
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) => this.onSubmit(event, index)}
                            >
                              Save
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
            
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(TimeSheet);
