import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertColor, DialogTitle, FormLabel, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { Component } from "react";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { EmailModel } from "../../Models/EmailModel";

import EmailService from "../../Services/EmailService";
import { ClientModel } from "../../Models/ClientModel";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import RichTextEditor from "../../Components/RichTextEditor/RichTextEditor";
const EmailServices = new EmailService();
interface Props {
  clientInfo: ClientModel;
  getAllEmail: () => void;
}
interface State {
  right: boolean;
  emailInfo: EmailModel;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
}
type Anchor = "right";
class EmailDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      right: false,
      emailInfo: {} as EmailModel,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
    };
  }

  toggleDrawer = () => {
    this.setState(
      {
        emailInfo: {
          ...this.state.emailInfo,
          recipient_email: this.props.clientInfo.client_email,
        },
      },
      () => {
        this.setState({ ...this.state, right: true });
      }
    );
  };
  onDrawerClose = () => {
    this.setState({ ...this.state, right: false });
  };
  onTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      emailInfo: {
        ...this.state.emailInfo,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleChange = (content: string) => {
    console.log(content);
    this.setState({
      emailInfo: {
        ...this.state.emailInfo,
        message_body: content,
      },
    });
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      emailInfo: {
        ...this.state.emailInfo,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    if (target.files && target.files.length > 0) {
      this.setState({
        emailInfo: { ...this.state.emailInfo, file: target.files[0] },
      });
      //console.log(target.files[0]);
    }
  };
  onSendEmail = async (event: React.FormEvent) => {
    event.preventDefault();
    if (this.state.emailInfo.file) {
      const formData = new FormData();
      formData.append("file", this.state.emailInfo.file);
      const response = await EmailServices.sendEmail(
        Number(this.props.clientInfo.client_id),
        1,
        this.state.emailInfo,
        formData
      );
      if (response) {
        this.props.getAllEmail();
        this.setState({
          right: false,
          openAlert: true,
          alertMsg: "Email Sent Successfully",
          alertType: "success",
        });
      }
    } else {
      const formData = new FormData();
      formData.append("file", "");
      const response = await EmailServices.sendEmail(
        Number(this.props.clientInfo.client_id),
        1,
        this.state.emailInfo,
        formData
      );
      if (response) {
        this.props.getAllEmail();
        this.setState({
          right: false,
          openAlert: true,
          alertMsg: "Email Sent Successfully",
          alertType: "success",
        });
      }
    }
    //console.log(response);
  };
  list = (anchor: Anchor) => (
    <Box sx={{ width: 550, marginTop: 10 }}>
      <Box style={{ backgroundColor: "#ebebeb" }}>
        <Grid item container>
          <Grid item xs={11}>
            <DialogTitle> Compose Email</DialogTitle>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-start">
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              onClick={this.onDrawerClose}
              style={{ cursor: "pointer", marginTop: 22 }}
            />
          </Grid>
        </Grid>
      </Box>

      <form onSubmit={this.onSendEmail}>
        <Grid container paddingLeft={5} marginTop={5}>
          <Grid container item>
            <Grid item xs={12}>
              <FormLabel sx={{ fontSize: "0.9em", color: "black" }}>
                To :
              </FormLabel>
            </Grid>
            <Grid item xs={11}>
              <TextInputField
                id="recipient_email"
                value={this.state.emailInfo.recipient_email}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>
          <Grid container item marginTop={4}>
            <Grid item xs={12}>
              <FormLabel sx={{ fontSize: "0.9em", color: "black" }}>
                CC :
              </FormLabel>
            </Grid>
            <Grid item xs={11}>
              <TextInputField
                id="cc_email"
                value={this.state.emailInfo.cc_email}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>
          <Grid container item marginTop={4}>
            <Grid item xs={12}>
              <FormLabel sx={{ fontSize: "0.9em", color: "black" }}>
                Subject :
              </FormLabel>
            </Grid>
            <Grid item xs={11}>
              <TextInputField
                id="subject"
                placeholder="Enter Email Subject"
                value={this.state.emailInfo.subject}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>
          <Grid container item marginTop={4}>
            <Grid item xs={12}>
              <FormLabel sx={{ fontSize: "0.9em", color: "black" }}>
                Message :
              </FormLabel>
            </Grid>
            <Grid item xs={11}>
              {/* <TextAreaInputField
                minRows={12}
                isRequired={false}
                id="message_body"
                value={this.state.emailInfo.message_body}
                onChange={this.onTextAreaInputChange}
                placeholder="Type Here..."
              /> */}
              <RichTextEditor
                value={this.state.emailInfo.message_body}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container item marginTop={8}>
            <input type="file" id="my_file" onChange={this.handleFileChange} />
          </Grid>
          <Grid container item marginTop={4} mb={2}>
            <Button
              type="submit"
              style={{ textTransform: "capitalize" }}
              variant="contained"
            >
              Send Email
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );

  render() {
    //console.log(this.props.clientInfo);
    const { alertMsg, alertType, openAlert } = this.state;
    return (
      <>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <div>
          {(["right"] as const).map((anchor) => (
            <React.Fragment key={anchor}>
              <Button
                style={{ textTransform: "capitalize" }}
                variant="contained"
                onClick={this.toggleDrawer}
              >
                Send Email
              </Button>
              <Drawer
                anchor={anchor}
                open={this.state.right}
                onClose={this.toggleDrawer}
              >
                {this.list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      </>
    );
  }
}

export default EmailDrawer;
