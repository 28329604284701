import React from 'react';
import Reassign from './ReAssign.svg'
import Edit from './Edit.svg'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Grid, IconButton as IButton, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCloudUploadAlt as CloudUploadIcon,
    faPen as EditIcon,
    faTrash as DeleteForeverIcon,
    faPlus as AddIcon,
    faExternalLinkAlt as PopupIcon,
    faCheck as checkIcon,
    faXmark as cancelIcon,
    faEnvelopeCircleCheck as EmailApprover,
    faUserPen as AssignForCorrection,
    faUserPlus as AssigntoApprover,
    faClone,
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

import ModalDialog from "../Modal/ModelDialog";
import RedButton from "./RedButton";
import WhiteButton from "./WhiteButton";
import BlueButton from "./BlueButton";

export type IconType = 'Add' | 'Download'  | 'Edit' | 'AssigntoApprover' | 'Delete' | 'EmailOwner' | 'EmailApprover' | 'DeleteDialog' | 'CloudUpload' | 'Popup' | 'ReAssign' | 'Approve' | 'Cancel' | 'AssignForCorrection'|'Clone'|'Save';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 15,
        maxWidth: "none",
        cursor: "pointer",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "white",
        fontSize: 20,
    },
}));
interface Props {
    id?: string;
    IconType: IconType;
    isFR: boolean;
    isDisabled: boolean;
    onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}
interface State {
    isDialogOpen: boolean;
}

class IconButton extends React.Component<Props, State> {
    public static defaultProps = {
        isFR: false,
        isDisabled: true
    }
    constructor(props: Props | Readonly<Props>) {
        super(props)

        this.state = {
            isDialogOpen: false
        }
    };
    render() {
        const { id, IconType, isFR, onClick, isDisabled } = this.props;
        const { isDialogOpen } = this.state;

        const buttonStyle: React.CSSProperties = { color: '#0086ff',fontSize:"2rem" };
        const btnStyle: React.CSSProperties = { color: '#0086ff' };
        let CloudIconSize: SizeProp = 'lg';

        if (isFR) {
            buttonStyle.backgroundColor = '#004693';
            CloudIconSize = '2x';
        }

        if (isDisabled) {
            btnStyle.color = '#616161';
        }
        switch (IconType) {
            case 'Add':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Add"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={AddIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'Edit':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Edit"
                            arrow
                        >
                          <FontAwesomeIcon id={id} icon={EditIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
                
            case 'Delete':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Delete"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
                case 'Clone':
                    return (
                        <IButton style={buttonStyle} onClick={onClick}>
                            <LightTooltip
                                placement="bottom"
                                title="Clone"
                                arrow
                            >
                                <FontAwesomeIcon id={id} icon={faClone} size="xs" />
                            </LightTooltip>
                        </IButton>
                 );
                case 'Download':
                    return (
                        <IButton style={buttonStyle} onClick={onClick}>
                            <LightTooltip
                                placement="bottom"
                                title="Download"
                                arrow
                            >
                                <FontAwesomeIcon id={id} icon={faDownload} size="xs" />
                            </LightTooltip>
                        </IButton>
                 );
                
            case 'ReAssign':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="ReAssign"
                            arrow
                        >
                            <img src={Reassign} alt="Reassign" width='80%' />
                        </LightTooltip>
                    </IButton>
                );
            case 'AssigntoApprover':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Assign to Approver"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={AssigntoApprover} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'Approve':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Approve"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={checkIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'EmailOwner':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        {/* <FontAwesomeIcon id={id} icon={EmailOwner} size="xs" /> */}
                        <LightTooltip
                            placement="bottom"
                            title="Email Owner"
                            arrow
                        >
                            <ContactMailIcon />
                        </LightTooltip>
                    </IButton>
                );
            case 'EmailApprover':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Email Approver"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={EmailApprover} size="sm" />
                        </LightTooltip>
                    </IButton>
                );
            case 'AssignForCorrection':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Assign For Correction"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={AssignForCorrection} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'Cancel':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Reject"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={cancelIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'DeleteDialog':
                return (
                    <>
                        <ModalDialog title={`Delete Action`} dialogWidth="sm"
                            isOpen={isDialogOpen} onClose={this.handleCancel}
                            dialogAction={
                                <Grid container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{ marginRight: 30 }}>
                                    <Grid item >
                                        <WhiteButton label="Cancel" onClick={this.handleCancel} />
                                    </Grid>
                                    <Grid item >
                                        {isFR ? <BlueButton label="Delete" onClick={this.onDeleteClick} />
                                            : <RedButton label="Delete" onClick={this.onDeleteClick} />}
                                    </Grid>
                                </Grid>
                            }>
                            <Typography style={{ fontSize: 16, fontWeight: 400, color: '#383838' }}>
                                Are you sure you want to delete ?
                            </Typography>
                        </ModalDialog>
                        <IButton style={buttonStyle} onClick={this.handleDelete}>
                            <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
                        </IButton>
                    </>
                );
            case 'CloudUpload':
                return (
                    <IButton style={btnStyle} disabled={isDisabled} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={CloudUploadIcon} size={CloudIconSize} />
                    </IButton>
                );
               
                    
            case 'Popup':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={PopupIcon} size='xs' />
                    </IButton>
                );
            default:
                return <></>;

        }
    }

    onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.handleCancel();
        this.props.onClick(event);
    }

    handleDelete = () => {
        this.setState({ isDialogOpen: true });
    }

    handleCancel = () => {
        this.setState({ isDialogOpen: false });
    }
}

export default IconButton;