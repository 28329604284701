 import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import './CustomTabPanel.css';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Profiles from "./profiles"
import Roles from "./roles.js"
import AddNewRole from "./add-new-role.js"
import NewProfile from "./new-profile.js"
import { useHistory, useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [showAddNewRole, setShowAddNewRole] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const routes = ['/settings/profiles', '/settings/roles', '/settings/data-sharing'];

    // Navigate to the selected tab's route
    history.push(routes[newValue]);
  };
  const toggleAddNewRoleClick = () => {
    setShowAddNewRole(!showAddNewRole);
  };
  const history = useHistory();
  const location = useLocation();

  const handleNewRoleClick = () => {
    history.push('/settings/create-roles');
  };
  const handleNewProfileClick = () => {
    history.push('/settings/create-profile');
  }

  return (
    <Box className = "custom-tab-panel-main-container">
    <Box className = "custom-tab-panel-white-box">
      <Box  sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Profiles" {...a11yProps(0)} />
          <Tab label="Roles" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Switch>
          <Route exact path="/settings">
            <Redirect to="/settings/profiles" />
          </Route>
          <Route path="/settings/profiles">
            <Profiles onNewProfileClick = {handleNewProfileClick}/>
          </Route>
          <Route path="/settings/create-profile">
            <NewProfile/>
          </Route>
        </Switch>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Switch>
            <Route path="/settings/roles">
              <Roles onAddNewRoleClick = {handleNewRoleClick}/>
            </Route>
            <Route path="/settings/create-roles">
              <AddNewRole />
            </Route>
          </Switch>
      </CustomTabPanel>

    </Box>
    </Box>
  );
}
