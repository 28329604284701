import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

// import Layout from "./Components/Layout/Index";
import Contact from "./Pages/Contact/Contact";
import ViewClient from "./Pages/ViewClient/ViewClient";
import Staff from "./Pages/Staff/Staff";
import ViewStaff from "./Pages/Staff/ViewStaff";
import Layout from "./Components/Layout/Menu/Drawer";
import Login from "./Pages/Login/Login";
import ForgotPasswordPage from "./Pages/Login/ForgotPasswordPage";
import ResetPasswordPage from "./Pages/Login/ResetPasswordPage";
import SignUpPage from './Pages/Provider/SignUp';
import Leads from './Pages/Leads/Leads';
import ViewLead from "./Pages/ViewLead/ViewLead";
import ServiceAgreement from "./Pages/ServiceAgreement/ServiceAgreement";
import CalenderView from "./Pages/Roster/Calender";
import CustomTabPanel from "./Pages/App Settings/CustomTabPanel"
import AdministratorRoles from "./Pages/App Settings/administrator-roles"
import EmailIntegration from "./Pages/Email/email-integration.js"
import OauthAzure from "./Pages/Email/Oauth-azure.js"
import Inbox from "./Pages/Email/inbox.js"
import Roles from "./Pages/App Settings/roles.js"
import AddNewRole from "./Pages/App Settings/add-new-role.js"
import InboxAzure from "./Pages/Email/inbox-azure.js"
import FullPageEmailForm from"./Pages/Email/compose-email"

import Roster from "./Pages/Roster/Roster";
function PrivateRoute({ ...rest }) {
  const Auth = () => {
    const accessStatus = window.sessionStorage.getItem("token");
    if (accessStatus) {
      return true;
    } else {
      return false;
    }
  };
  return <>{Auth() ? <Route {...rest} /> : <Redirect to="/login" />}</>;
}

function PublicRoute({ ...rest }) {
  const Auth = () => {
    const accessStatus = window.sessionStorage.getItem("token");
    if (!accessStatus) {
      return true;
    } else {
      return false;
    }
  };
  return <>{Auth() ? <Route {...rest} /> : <Redirect to="/contacts" />}</>;
}

function App() {
  return (
    <Router>
        <Switch>
        <PublicRoute exact path="/" component={Login} />
        
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/forgetpassword" component={ForgotPasswordPage} />
        <PublicRoute path="/resetpassword" component={ResetPasswordPage} />
        <PublicRoute path="/signup" component={SignUpPage} />
        <PublicRoute path="/oauth-azure" component={OauthAzure} />
        <PublicRoute path="/roles" component={Roles} />
        <PublicRoute path="/roles/create" component={AddNewRole} />
        


        
        <Layout>
          {/* <Redirect exact from="/" to="/dashboard" /> */}
          <PrivateRoute path="/contacts"  component={Contact} />
          <PrivateRoute path="/viewclient/:id" component={ViewClient} />
          <PrivateRoute path= "/compose-email" component = {FullPageEmailForm}/>
          <PrivateRoute path="/settings" component={CustomTabPanel} />
          <PrivateRoute path="/profile/:profileId" component={AdministratorRoles} />
          <PrivateRoute path="/email" component={EmailIntegration} />
          <PrivateRoute path="/viewlead/:id" component={ViewLead} />
          <PrivateRoute path="/staff" component={Staff} />
          <PrivateRoute path="/viewstaff/:id" component={ViewStaff} />
          <PrivateRoute path="/leads" component={Leads} />
          <PrivateRoute path = "/serviceagreement" component = {ServiceAgreement}/>
          <PrivateRoute path = "/roster" component = {Roster}/>
          <PrivateRoute path="/settings/roles/create" component={AddNewRole} />
          <PrivateRoute path = "/calendar" component = {CalenderView}/>
          <PrivateRoute path="/inbox" component={Inbox} />
          <PrivateRoute path="/inbox-azure" component={InboxAzure} />

        </Layout>

        </Switch>
    </Router>
  );
}

export default App;
// shailesh
