import { URIs } from "../Common/Constants";

import { CaseNotesModel, LeadCaseNotesModel, StaffCaseNotesModel } from "../Models/CaseNotesModel";
import GenericAPIService from "./GenericAPIService";

const { GET_CASENOTES, CREATE_CASENOTES } = URIs;
class CaseNotesService extends GenericAPIService {
  async getAllCaseNotes(id: number,providerId:number) {
    const data: any = await this.getAsync(`${GET_CASENOTES}?client_id=${id}&provider_id=${providerId}`);
    //console.log("data",data)
    return data;
  }

  async createCaseNotes(body: CaseNotesModel) {
    const { data } = await this.postAsync<{ data: any }>(
      CREATE_CASENOTES,
      body
    );
    //console.log("data", data);
    return data;
  }
  async getAllStaffCaseNotes(id: number,providerId:number) {
    const data: any = await this.getAsync(`staffnotes_select?staff_id=${id}&provider_id=${providerId}`);
    //console.log("data",data)
    return data;
  }

  async createStaffCaseNotes(body: StaffCaseNotesModel) {
    const { data } = await this.postAsync<{ data: any }>(
      CREATE_CASENOTES,
      body
    );
    //console.log("data", data);
    return data;
  }
  async getAllLeadCaseNotes(id: number,providerId:number) {
    const data: any = await this.getAsync(`leadnotes_select?lead_id=${id}&provider_id=${providerId}`);
    //console.log("data",data)
    return data;
  }

  async createLeadCaseNotes(body: LeadCaseNotesModel) {
    const { data } = await this.postAsync<{ data: any }>(
      CREATE_CASENOTES,
      body
    );
    //console.log("data", data);
    return data;
  }
}

export default CaseNotesService;
