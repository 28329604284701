import React from "react";
import { Button } from "@mui/material";
import { red as redColor } from "@mui/material/colors";


interface Props {
    label: string;
    onClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    style?: React.CSSProperties;
    disabled?: boolean;
}
interface State {}

class BlueButton extends React.Component<Props, State> {
    render() {
        const { label, onClick, style, disabled } = this.props;

        const defaultStyle: any = {color: '#338BA8', borderColor: '#338BA8', fontWeight: '450' , textTransform: 'none'};
        
        let mergeStyle = {...defaultStyle, ...style};

        return(
            <Button size="small" variant="outlined" disabled={disabled} style={mergeStyle}
             onClick={onClick}>{label}</Button>
        );
    }
}

export default BlueButton;