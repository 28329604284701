import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import EmailDrawer from "./EmailDrawer";
import ClientService from "../../Services/ClientServices";
import { ClientModel } from "../../Models/ClientModel";
import EmailService from "../../Services/EmailService";
import DataLoader from "../../Components/Table/DataLoader";
import { AllEmailModel } from "../../Models/EmailModel";
import _, { isNull } from "lodash";
import ModalDialog from "../../Components/Modal/ModelDialog";
import NoRecords from "../../Components/NoRecords/NoRecords";
const ClientServices = new ClientService();
const EmailServices = new EmailService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  clientId: string;
  clientInfo: ClientModel;
  tableData: any;
  page: number;
  rows: number;
  headCellData: any;
  isOpen: boolean;
  messageBody: string;
  userInfo: any;
}
const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
class EmailPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      page: 0,
      rows: 5,
      clientId: "",
      clientInfo: {} as ClientModel,
      tableData: [],
      headCellData: [],
      isOpen: false,
      messageBody: "",
      userInfo: {},
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        const allDataResponse = await EmailServices.getAllEmail(clientId);
        if (allDataResponse) {
          const data = allDataResponse.data.map((el: AllEmailModel) => {
            return {
              recipientEmail: el.recipient_email,
              subject: el.subject,
              sentOn: new Date(el.sent_on).toDateString(),
              messageBody: el.message_body,
            };
          });
          this.setState({ tableData: data });
        }
        const response = await ClientServices.getSelectedClient(
          clientId,
          JSON.parse(userInfo).provider_id
        );
        if (response.data) {
          this.setState({
            clientInfo: response.data,
            clientId: clientId.toString(),
            userInfo: JSON.parse(userInfo),
          });
        }
      }
    }
  }
  getAllEmail = async () => {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      // console.log(match);
      if (match) {
        var clientId = parseInt(match[1]);
        const allDataResponse = await EmailServices.getAllEmail(clientId);
        if (allDataResponse) {
          const data = allDataResponse.data.map((el: AllEmailModel) => {
            return {
              recipientEmail: el.recipient_email,
              subject: el.subject,
              sentOn: new Date(el.sent_on).toDateString(),
              messageBody: el.message_body,
            };
          });
          this.setState({
            tableData: data,
            headCellData: Object.keys(data[0]),
          });
        }
      }
    }
  };
  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  handleChangePage = (_event: any, page: any) => {
    this.setState({ page: page });
    this.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rows: event.target.value });
    this.onChangeRow(event.target.value);
  };
  handleOpenClick = (message: string) => {
    const { tableData, isOpen } = this.state;

    const filterMessage = tableData.find(
      (val: any) => val.messageBody === message
    );

    this.setState({ messageBody: filterMessage.messageBody }, () => {
      this.setState({ isOpen: true });
    });
  };
  render() {
    const { clientInfo, tableData, rows, page, isOpen, messageBody } =
      this.state;
    //console.log(headCellData, tableData[0]);
    return (
      <Box>
        <ModalDialog
          isOpen={isOpen}
          dialogWidth="xs"
          title="View Message"
          onClose={() => {
            this.setState({
              isOpen: false,
            });
          }}
        >
          <Grid item>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>
                Message:
              </span>
              {messageBody}
            </Typography>
          </Grid>
        </ModalDialog>

        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Typography
              marginBottom={1}
              style={{ fontSize: "1rem", fontWeight: 550 }}
            >
              Email
            </Typography>
            <hr />
            <EmailDrawer
              clientInfo={clientInfo}
              getAllEmail={this.getAllEmail}
            />
            {tableData.length > 0 ? (
              <Box mt={2}>
                <TableContainer component={Paper}>
                  <Table
                    stickyHeader
                    sx={{ minWidth: "fit-content" }}
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid #0086ff",
                        }}
                      >
                        Recipient Email
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid #0086ff",
                        }}
                      >
                        Subject
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid #0086ff",
                        }}
                      >
                        Sent On
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid #0086ff",
                        }}
                      >
                        Message Body
                      </TableCell>
                    </TableHead>

                    <TableBody>
                      {tableData.length > 0
                        ? (rows > 0
                            ? tableData.slice(page * rows, page * rows + rows)
                            : tableData
                          ).map((el: any, ind: number) => {
                            return (
                              <StyledTableRow>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                    wordWrap: "break-word",
                                    hyphens: "auto",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {el.recipientEmail}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                    wordWrap: "break-word",
                                    hyphens: "auto",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {el.subject}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                    wordWrap: "break-word",
                                    hyphens: "auto",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {el.sentOn}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "16px",
                                    wordWrap: "break-word",
                                    hyphens: "auto",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  <Button
                                    variant="text"
                                    style={{ textTransform: "none" }}
                                    onClick={() =>
                                      this.handleOpenClick(el.messageBody)
                                    }
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </StyledTableRow>
                            );
                          })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={tableData.length}
                  page={page}
                  showFirstButton
                  showLastButton
                  labelRowsPerPage={`${
                    tableData.length > rows ? rows : tableData.length
                  } of ${tableData.length} records per page`}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={rows}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Box>
            ) : (
              <Box style={{ marginTop: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(EmailPage);
