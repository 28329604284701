import { URIs } from "../Common/Constants";

import { NDISPlanModel } from "../Models/NDISPlanModel";
import GenericAPIService from "./GenericAPIService";

const { GET_NDIS, CREATE_NDIS, DELETE_NDIS, UPDATE_NDIS } = URIs;
class NDISService extends GenericAPIService {
  async getSelectedNDIS(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_NDIS}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async deleteNDIS(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${DELETE_NDIS}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  // async getAssignedSources(formId?: number) {
  //     const { data } = await this.getAsync<{ data: AvailableFormValueModel }>(`${ASSIGNED_SOURCE}/${formId}`);
  //     return data.data;
  // }

  async createNDIS(body: NDISPlanModel) {
    const { data } = await this.postAsync<{ data: any }>(CREATE_NDIS, body);
    //console.log("data", data);
    return data;
  }
  async UpdateNDIS(body: NDISPlanModel) {
    const { data } = await this.postAsync<{ data: any }>(UPDATE_NDIS, body);
    //console.log("data", data);
    return data;
  }
}

export default NDISService;
