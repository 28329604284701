import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import DataLoader from "../../Components/Table/DataLoader";
import { FundTrackingModel } from "../../Models/FundTrackingModel";
import FundTrackingService from "../../Services/FundTrackingService";

const FundTrackingServices = new FundTrackingService();

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  clientId: number;
  fundTracking: FundTrackingModel[];
}

class FundTracking extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      clientId: 0,
      fundTracking: [],
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");
      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        const response = await FundTrackingServices.getFundByClientId(
          clientId,
          JSON.parse(userInfo).provider_id
        );

        const data = response.data.map((el: FundTrackingModel) => {
          return {
            supportNo: el.support_item_no,
            supporItemName: el.support_item_name,
            startDate: new Date(el.start_date).toDateString(),
            endDate: new Date(el.end_date).toDateString(),
            yearPrice: el.total_price_yearly,
            balance: el.balance_amount,
          };
        });
        this.setState({
          tableData: data,
          totalRecordsCount: data.length,
        });
      }
    }
  }

  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };

  render() {
    const { totalRecordsCount, page, rows, tableData } = this.state;

    return (
      <Box>
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container>
              <Grid item xs={9}>
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  Fund Tracking
                </Typography>
              </Grid>
            </Grid>
            <hr />

            <Box>
              <DataLoader
                totalRecordsCount={totalRecordsCount}
                onChangePage={this.onChangePage}
                onChangeRow={this.onChangeRow}
                tableData={
                  rows > 0
                    ? tableData.slice(page * rows, page * rows + rows)
                    : tableData
                }
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(FundTracking);
