export enum FileType {
    XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export enum EventType {
    ADD,
    REMOVE
}

export enum CustomEventType {
    SuccessAlert,
    ErrorAlert,
    ClearAlert,


}