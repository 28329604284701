import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import MenuModel from "./MenuModel";
import {
  ChevronLeft,
  ChevronRight,
  DashboardRounded,
  AssignmentRounded,
  ViewAgendaRounded,
  HandshakeRounded,
  ContactPageRounded,
  WorkHistoryRounded,
  PersonRounded,
  CalendarMonthRounded,
  ReceiptRounded,
  AutoAwesomeMosaicRounded,
  BarChartRounded,
  GridViewRounded,
  ForumRounded,
  Settings,
  Help,
} from "@mui/icons-material";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import ListItemIcon from '@mui/material/ListItemIcon';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
// import Settings from '@mui/icons-material/Settings';
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuStyle = {
  color: "#0086ff",
  fontSize: "2rem",
};

interface userInfoInterface {
  email: string;
  provider_name: string;
}

export default function MiniDrawer(props: any) {
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [isSelect, setIsSelect] = React.useState(false);
  const userInfoLocal = sessionStorage.getItem("userInfo");
  let userInfo: userInfoInterface = userInfoLocal && JSON.parse(userInfoLocal);
  // let userInfo1:userInfoInterface = JSON.parse(userInfo);
  const Menus: MenuModel[] = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <DashboardRounded sx={MenuStyle} />,
    },
    {
      name: "Leads",
      path: "/leads",
      icon: <AssignmentRounded sx={MenuStyle} />,
    },

    {
      name: "Contacts",
      path: "/contacts",
      icon: <ViewAgendaRounded sx={MenuStyle} />,
    },
    {
      name: "Staff",
      path: "/staff",
      icon: <ContactPageRounded sx={MenuStyle} />,
    },
    {
      name: "Service Agreement",
      path: "/serviceagreement",
      icon: <HandshakeRounded sx={MenuStyle} />,
    },
    {
      name: "Accounts",
      path: "/accounts",
      icon: <PersonRounded sx={MenuStyle} />,
    },
    {
      name: "Roster",
      path: "/roster",
      icon: <WorkHistoryRounded sx={MenuStyle} />,
    },
    {
      name: "Calendar",
      path: "/calendar",
      icon: <CalendarMonthRounded sx={MenuStyle} />,
    },
    {
      name: "Invoice",
      path: "/invoice",
      icon: <ReceiptRounded sx={MenuStyle} />,
    },
    {
      name: "Templates",
      path: "/templates",
      icon: <AutoAwesomeMosaicRounded sx={MenuStyle} />,
    },
    {
      name: "Reports",
      path: "/reports",
      icon: <BarChartRounded sx={MenuStyle} />,
    },
    {
      name: "Integrations",
      path: "/integrations",
      icon: <GridViewRounded sx={MenuStyle} />,
    },
    {
      name: "Email",
      path: "/email",
      icon: <ForumRounded sx={MenuStyle} />,
    },
    {
      name: "Settings",
      path: "/settings",
      icon: <Settings sx={MenuStyle} />,
    },
    {
      name: "Help",
      path: "/help",
      icon: <Help sx={MenuStyle} />,
    },
  ];
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenuClick = (menu: MenuModel) => {
    setIsSelect(true);
    history.push(menu.path);
    //history.go(0);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDialogue = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("clear storage");
    sessionStorage.clear();
    history.push("/login");
  };

  React.useEffect(() => {
    setIsSelect(false);
    // setOpen(false);
    return () => {
      setOpen(true);
    };
  }, [isSelect]);
  let selectedStyle = { backgroundColor: "#b1b1b1b0" };
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <CssBaseline />
      <AppBar
        sx={{ display: "flex" }}
        style={{ backgroundColor: "#FFFFFF" }}
        position="fixed"
        open={open}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Toolbar sx={{ display: "flex" }}>
            <IconButton
              style={{ color: "#0086ff" }}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Box sx={{ display: "flex", marginRight: "25px" }}>
            <Typography sx={{ minWidth: 100 }}>Contact</Typography>
            <Typography sx={{ minWidth: 100 }}>Profile</Typography>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2, color: "rgb(0, 134, 255)" }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    textTransform: "uppercase",
                    backgroundColor: "rgb(0, 134, 255)",
                  }}
                >
                  {userInfo.provider_name.substring(0, 2)}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openDialogue}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <List>
                <ListItem sx={{ padding: "0px" }}>
                  <ListItemAvatar sx={{ minWidth: "30px" }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        textTransform: "uppercase",
                        backgroundColor: "rgb(0, 134, 255)",
                      }}
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span style={{ fontWeight: "bold" }}>
                        {userInfo.provider_name}
                      </span>
                    }
                    secondary={userInfo.email}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem>
            <Divider />

            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" onClick={handleLogout} />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography
            style={{ color: "#0086ff", fontWeight: 600, fontSize: "1.5rem" }}
            marginRight={13}
          >
            CRM
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRight sx={MenuStyle} />
            ) : (
              <ChevronLeft sx={MenuStyle} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {Menus.map((menu) => {
            menu.isSelected = menu.path === window.location.pathname;
            return (
              <Box
                key={menu.name}
                style={menu.isSelected ? selectedStyle : undefined}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                onClick={() => handleMenuClick(menu)}
              >
                <ListItem
                  key={menu.name}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Box>
            );
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <DrawerHeader />
        <Card>
          <CardContent
            style={{
              minHeight: "90vh",
              overflowY: "scroll",
              overflowX: "auto",
            }}
          >
            {props.children}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
