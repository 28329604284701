import React, { useState, useEffect, useRef } from 'react';
import './email-integration.css';
import Enviroment from "./../../config.json";
import {useHistory} from 'react-router-dom';

const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";
const callBackUrl = baseURL + '/auth/google/callback';
const EmailIntegration: React.FC = () => {
	const [accessToken, setAccessToken] = useState("");
	const history = useHistory();
	const CLIENT_ID = "730459832571-ifab905ivp63a12df0fadnbne0drbevi.apps.googleusercontent.com";
	const [authCode, setAuthCode] = useState(null);


	const handleLoginAzure = () => {
	    window.location.href = baseURL + '/oauth-azure';

	  };
	useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessCodeAzure = urlParams.get('code');
    if (accessCodeAzure) {
      sessionStorage.setItem('code', accessCodeAzure);
      history.push("/inbox-azure");
      /*fetch(`https://localhost:5000/fetch-emails?access_code=${accessCodeAzure}`)
        .then(response => response.json())
        .then(emailData => {
          console.log("Fetched emails:", emailData);
        })
        .catch(error => {
          console.error("Error fetching emails:", error);
        });*/
    }
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessCode = urlParams.get('access_code');
    if (accessCode) {
      sessionStorage.setItem('access_code', accessCode);
      history.push("/inbox");
      
      /*fetch(`https://localhost:5000/get_emails?access_code=${accessCode}`)
        .then(response => response.json())
        .then(emailData => {
          console.log("Fetched emails:", emailData);
        })
        .catch(error => {
          console.error("Error fetching emails:", error);
        });*/
    }
  }, []);
  /*const handleFetchEmailsAzure = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const accessCode = urlParams.get('code');
      const response = await axios.get(`https://localhost:5000/fetch-emails?code=${accessCode}`); // Replace with your Flask API endpoint
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching emails:', error);
    }
  };*/
  

	 const handleGoogleLogin = () => {
	 	const oauthUrl = `https://accounts.google.com/o/oauth2/auth` +
      `?client_id=${CLIENT_ID}` +
      `&redirect_uri=${encodeURIComponent(callBackUrl)}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent('https://mail.google.com/')}`;
    // Redirect to Google login page
    window.location.href = oauthUrl;
  };

		return (
    		<div className="email-integration-main-container">
    			<div className = "email-integration-title-holder">
	    			<span className = "email-integration-title">Email Integration</span>
	    			<span className = "email-integration-subtitle">Connect your email inbox with Connektivity and transform the way you do sales.</span>
    			</div>
    			<span className = "email-integration-popular-mail-text">Popular Email Services</span>
    			<div className = "email-integration-popular-mail-holder">
    				<div className = "email-integration-mail-tile" onClick={handleGoogleLogin}>
    					<img className = "email-integration-mail-icon" src = "./gmail-logo.svg"/>
    					<span className = "email-integration-mail-text">Gmail</span>
    				</div>
    				<div className = "email-integration-mail-tile" onClick = {handleLoginAzure}>
    					<img className = "email-integration-mail-icon" src = "./outlook-icon.svg"/>
    					<span className = "email-integration-mail-text">Outlook</span>
    				</div>
    			</div>
    		</div>
    		);

}
export default EmailIntegration
