import { URIs } from "../Common/Constants";

import { ClientModel } from "../Models/ClientModel";
import { ServiceAgreementModel } from "../Models/ServiceAgreementModel";
import GenericAPIService from "./GenericAPIService";

const {
  GET_REGISTRATION_GROUP,
  GET_SUPPORT_ITEM,
  GET_PRICE_DETAILS,
  CREATE_SERVICEAGREEMENT,
  GET_SERVICEAGREEMENT,
} = URIs;
class AgreementService extends GenericAPIService {
  async getAllRegistrationGroup() {
    const data: any = await this.getAsync(
      `${GET_REGISTRATION_GROUP}`
    );
    //console.log("data",data)
    return data;
  }
  async getSupportItem(registrationGroup: string, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_SUPPORT_ITEM}?registration_group_name=${registrationGroup}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getPriceDetail(registrationGroup: string, supportItem: string) {
    const data: any = await this.getAsync(
      `${GET_PRICE_DETAILS}?registration_group_name=${registrationGroup}&support_item_name=${supportItem}`
    );
    //console.log("data",data)
    return data;
  }
  async createAgreement(
    body: ServiceAgreementModel[],
    clientId: any,
    providerId: number
  ) {
    const data = await this.postAsync(
      `${CREATE_SERVICEAGREEMENT}?client_id=${clientId}&provider_id=${providerId}`,
      body
    );
    //console.log("data", data);
    return data;
  }
  async getServiceAgreement(clientId: any, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_SERVICEAGREEMENT}?client_id=${clientId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
}

export default AgreementService;
