import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { EventType } from "../../Common/Enums";
import SingleCheckBox from "../../Components/CheckBox/CheckBox";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import { AssessmentModel } from "../../Models/AssessmentModel";
import AssessmentService from "../../Services/AssessmentService";

const AssessmentServices = new AssessmentService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  clientId: number;
  assessmentInfo: AssessmentModel;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isAdd: boolean;
  userInfo: any;
}

class Assessment extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      clientId: 0,
      assessmentInfo: {} as AssessmentModel,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isAdd: false,
      userInfo: {},
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        this.setState({
          assessmentInfo: {
            ...this.state.assessmentInfo,
            client_id: clientId,
            provider_id: JSON.parse(userInfo).provider_id,
          },
          clientId: clientId,
          userInfo: JSON.parse(userInfo),
        });

        const response = await AssessmentServices.getAssessmentById(
          clientId,
          JSON.parse(userInfo).provider_id
        );
        if (Object.keys(response.data).length > 0) {
          this.setState({
            assessmentInfo: response.data,
            isEdit: true,
            isAdd: true,
          });
        }
      }
    }
  }
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      assessmentInfo: {
        ...this.state.assessmentInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        assessmentInfo: {
          ...this.state.assessmentInfo,
          [targetId]: selected.value,
          client_id: this.state.clientId,
        },
      });
    }
  };

  onAssessmentSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { assessmentInfo, isEdit, userInfo } = this.state;
      //console.log(assessmentInfo);
      if (isEdit) {
        const updatedResponse = await AssessmentServices.UpdateAssessment({
          ...assessmentInfo,
          provider_id: userInfo.provider_id,
        });
        if (updatedResponse) {
          this.setState({
            openAlert: true,
            alertMsg: "Assessment Updated Successfully",
            alertType: "success",
            isEdit: true,
          });
        }
      } else {
        const response = await AssessmentServices.createAssessment(
          assessmentInfo
        );
        if (response) {
          this.setState({
            openAlert: true,
            alertMsg: "Assessment Created Successfully",
            alertType: "success",
            isEdit: true,
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onCancelClick = () => {
    this.setState({ assessmentInfo: {} as AssessmentModel });
  };
  onDeleteClick = async (event: React.FormEvent) => {
    event.preventDefault();
    const response = await AssessmentServices.deleteAssessmentById(
      this.state.assessmentInfo.client_assessmentid,
      this.state.assessmentInfo.client_id,
      this.state.userInfo.provider_id
    );
    if (response) {
      this.setState({
        isEdit: false,
        isAdd: false,
        assessmentInfo: {} as AssessmentModel,
        openAlert: true,
        alertMsg: "Assessment Deleted Successfully",
        alertType: "success",
      });
    }
  };
  onTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      assessmentInfo: {
        ...this.state.assessmentInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onCheckSelect = (selected: any, eventType: EventType, targetId?: string) => {
    const { assessmentInfo } = this.state;
    let flag = eventType ? "Yes" : "No";

    //console.log(selected, targetId);
    if (targetId) {
      this.setState({
        assessmentInfo: {
          ...assessmentInfo,
          [targetId]: flag,
        },
      });
    }
  };

  dropdownValue: SelectModel[] = [
    { text: "Yes-Full", value: "Yes-Full" },
    { text: "Yes-Partial", value: "Yes-Partial" },
    { text: "No", value: "No" },
  ];
  clarificationValue: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  render() {
    const { assessmentInfo, isEdit, openAlert, alertMsg, alertType, isAdd } =
      this.state;
    console.log(this.state.assessmentInfo);
    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container>
              <Grid item xs={9}>
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  Assessment
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                {isAdd ? (
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={() => {
                      this.setState({ isAdd: true });
                    }}
                  >
                    - Add Assessment
                  </Button>
                ) : (
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={() => {
                      this.setState({ isAdd: true });
                    }}
                  >
                    + Add Assessment
                  </Button>
                )}
              </Grid>
            </Grid>
            <hr />

            {isAdd ? (
              <form onSubmit={this.onAssessmentSave}>
                <Grid container marginTop={2} item flexDirection={"column"}>
                  <Grid container item rowSpacing={3}>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Living Arrangements (Current/Past)
                        </FormLabel>
                      </Grid>

                      <Grid item xs={8}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="clinet_living_arrangements_desc"
                          value={assessmentInfo.clinet_living_arrangements_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline any barriers/issues to Housing"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Health/Medical (Current/Past)
                        </FormLabel>
                      </Grid>

                      <Grid item xs={8}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_health_desc"
                          value={assessmentInfo.client_health_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline any barriers/issues to Housing"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Education/ Work /Training (Current/Past)
                        </FormLabel>
                      </Grid>

                      <Grid item xs={8}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_education_work_desc"
                          value={assessmentInfo.client_education_work_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline any barriers/issues to Housing"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Tick as applicable
                        </FormLabel>
                      </Grid>

                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_is_diabetic"
                          label="Diabetic"
                          isChecked={
                            assessmentInfo.client_is_diabetic ? true : false
                          }
                          value={assessmentInfo.client_is_diabetic}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_epilepsy"
                          label="Epilepsy"
                          isChecked={
                            assessmentInfo.client_epilepsy ? true : false
                          }
                          value={assessmentInfo.client_epilepsy}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_is_incontinence"
                          label="Incontinence"
                          isChecked={
                            assessmentInfo.client_is_incontinence ? true : false
                          }
                          value={assessmentInfo.client_is_incontinence}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SingleCheckBox
                          id="client_is_mental_health"
                          label="Mental Health"
                          isChecked={
                            assessmentInfo.client_is_mental_health
                              ? true
                              : false
                          }
                          value={assessmentInfo.client_is_mental_health}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}></Grid>

                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_at_risk_substanceabuse"
                          label="At risk of substance abuse"
                          isChecked={
                            assessmentInfo.client_at_risk_substanceabuse
                              ? true
                              : false
                          }
                          value={assessmentInfo.client_at_risk_substanceabuse}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_known_allergies"
                          label="Known Allergies"
                          isChecked={
                            assessmentInfo.client_known_allergies ? true : false
                          }
                          value={assessmentInfo.client_known_allergies}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_t_risk_homelessness"
                          label="At risk of homelessness"
                          isChecked={
                            assessmentInfo.client_t_risk_homelessness
                              ? true
                              : false
                          }
                          value={assessmentInfo.client_t_risk_homelessness}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SingleCheckBox
                          id="client_communication"
                          label="Communication"
                          isChecked={
                            assessmentInfo.client_communication ? true : false
                          }
                          value={assessmentInfo.client_communication}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}></Grid>

                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_is_hearing_impairment"
                          label="Hearing Impairment"
                          isChecked={
                            assessmentInfo.client_is_hearing_impairment
                              ? true
                              : false
                          }
                          value={assessmentInfo.client_is_hearing_impairment}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_is_mobility"
                          label="Mobility"
                          isChecked={
                            assessmentInfo.client_is_mobility ? true : false
                          }
                          value={assessmentInfo.client_is_mobility}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleCheckBox
                          id="client_is_vision_impairment"
                          label="Vision impairment"
                          isChecked={
                            assessmentInfo.client_is_vision_impairment
                              ? true
                              : false
                          }
                          value={assessmentInfo.client_is_vision_impairment}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SingleCheckBox
                          id="client_others"
                          label="Other (specify)"
                          isChecked={
                            assessmentInfo.client_others ? true : false
                          }
                          value={assessmentInfo.client_others}
                          onChange={(
                            selected: any,
                            eventType: EventType,
                            targetId?: string
                          ) =>
                            this.onCheckSelect(selected, eventType, targetId)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}></Grid>

                      <Grid item xs={8}>
                        <TextAreaInputField
                          minRows={4}
                          isRequired={false}
                          id="client_others_desc"
                          value={assessmentInfo.client_others_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible for all ticked including any staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Medication Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_is_medi_assist_req}
                          id="client_is_medi_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_is_medi_assist_req_desc"
                          value={assessmentInfo.client_is_medi_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Mealtime Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_mealtime_assist_req}
                          id="client_mealtime_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_mealtime_assist_req_desc"
                          value={assessmentInfo.client_mealtime_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Enteral Peg Feed Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_peg_assist_req}
                          id="client_peg_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_peg_assist_req_desc"
                          value={assessmentInfo.client_peg_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Bowel Care Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_bowel_assist_req}
                          id="client_bowel_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_bowel_assist_req_desc"
                          value={assessmentInfo.client_bowel_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Wound Care Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_wound_assist_req}
                          id="client_wound_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_wound_assist_req_desc"
                          value={assessmentInfo.client_wound_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Catheter Care Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_catheter_assist_req}
                          id="client_catheter_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_catheter_assist_req_desc"
                          value={assessmentInfo.client_catheter_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Ventilation Care Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_venti_assist_req}
                          id="client_venti_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_venti_assist_req_desc"
                          value={assessmentInfo.client_venti_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Tracheostomy Care Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_trach_assist_req}
                          id="client_trach_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_trach_assist_req_desc"
                          value={assessmentInfo.client_trach_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Dysphagia Mgt Care Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_dysphagia_assist_req}
                          id="client_dysphagia_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_dysphagia_assist_req_desc"
                          value={
                            assessmentInfo.client_dysphagia_assist_req_desc
                          }
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          BSP or RP support?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_bsrrp_support_req}
                          id="client_bsrrp_support_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_bsrrp_support_req_desc"
                          value={assessmentInfo.client_bsrrp_support_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Mobility Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_mobility_assist_req}
                          id="client_mobility_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_mobility_assist_req_desc"
                          value={assessmentInfo.client_mobility_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Communication Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_comm_assist_req}
                          id="client_comm_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_comm_assist_req_desc"
                          value={assessmentInfo.client_comm_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Psycho-Social Assistance Required?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_psycho_assist_req}
                          id="client_psycho_assist_req"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_psycho_assist_req_desc"
                          value={assessmentInfo.client_psycho_assist_req_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Current or past court orders
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_is_courtorders}
                          id="client_is_courtorders"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_is_courtorders_desc"
                          value={assessmentInfo.client_is_courtorders_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Current or previous Legal matters
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_is_legalmatters}
                          id="client_is_legalmatters"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_is_legalmatters_desc"
                          value={assessmentInfo.client_is_legalmatters_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Parole & Probation?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.client_is_parole_prob}
                          id="client_is_parole_prob"
                          size="small"
                          values={this.dropdownValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="client_is_parole_prob_desc"
                          value={assessmentInfo.client_is_parole_prob_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Is Futher clarification required ?
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.is_further_clarification_req}
                          id="is_further_clarification_req"
                          size="small"
                          values={this.clarificationValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="clarification_desc"
                          value={assessmentInfo.clarification_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Add to Waiting List
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.add_to_waitinglist}
                          id="add_to_waitinglist"
                          size="small"
                          values={this.clarificationValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="add_to_waitinglist_desc"
                          value={assessmentInfo.add_to_waitinglist_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Service Refused
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.service_refused}
                          id="service_refused"
                          size="small"
                          values={this.clarificationValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="service_refused_desc"
                          value={assessmentInfo.service_refused_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Alternative Support Identified
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.alternate_support_identified}
                          id="alternate_support_identified"
                          size="small"
                          values={this.clarificationValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="alternate_support_identified_desc"
                          value={
                            assessmentInfo.alternate_support_identified_desc
                          }
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={7}>
                      <Grid item xs={3}>
                        <FormLabel sx={{ fontSize: "0.9em" }}>
                          Accepted
                        </FormLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelect
                          value={assessmentInfo.accepted}
                          id="accepted"
                          size="small"
                          values={this.clarificationValue}
                          onChange={this.onDropDownChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="accepted_desc"
                          value={assessmentInfo.accepted_desc}
                          onChange={this.onTextAreaInputChange}
                          placeholder="Outline as much details as possible including staff competencies/skills required"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    marginTop={5}
                    justifyContent="flex-start"
                  >
                    {isEdit ? (
                      <Button
                        type="submit"
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      onClick={this.onDeleteClick}
                      style={{ textTransform: "capitalize", marginLeft: 7 }}
                      variant="outlined"
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(Assessment);
