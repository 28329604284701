import { URIs } from "../Common/Constants";

import { ClientModel } from "../Models/ClientModel";
import { FeedbackModel } from "../Models/FeedbackModel";
import { LeadModel } from "../Models/LeadModel";
import GenericAPIService from "./GenericAPIService";

const {
  CREATE_CLIENT,
  GETALL_CLIENT,
  GET_COUNTRY,
  GET_CITY,
  GET_STATE,
  GET_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GETALL_LEADS,
  UPDATE_LEAD,
  DELETE_LEAD,
  SELECT_LEAD,
  CREATE_LEAD,
  GETALL_CLIENT_ROSTER,
  STAFF_ROSTER_STATUS,
  CREATE_FEEDBACK,
} = URIs;
class ClientService extends GenericAPIService {
  async getAllLeads(providerId: number) {
    const data: any = await this.getAsync(
      `${GETALL_LEADS}?provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getAllClients(providerId: number) {
    const data: any = await this.getAsync(
      `${GETALL_CLIENT}?provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getCountry() {
    const data: any = await this.getAsync(GET_COUNTRY);
    //console.log("data",data)
    return data;
  }
  async getState(country: string) {
    const data: any = await this.getAsync(`${GET_STATE}?country=${country}`);
    //console.log("data",data)
    return data;
  }
  async getCity(state: string, country: string) {
    const data: any = await this.getAsync(
      `${GET_CITY}?state=${state}&country=${country}`
    );
    // console.log("data",data)
    return data;
  }
  async getSelectedClient(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_CLIENT}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async deleteClient(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${DELETE_CLIENT}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  // async getAssignedSources(formId?: number) {
  //     const { data } = await this.getAsync<{ data: AvailableFormValueModel }>(`${ASSIGNED_SOURCE}/${formId}`);
  //     return data.data;
  // }

  async createClient(body: ClientModel) {
    const { data } = await this.postAsync<{ data: any }>(CREATE_CLIENT, body);
    //console.log("data", data);
    return data;
  }
  async UpdateClient(body: ClientModel) {
    const { data } = await this.postAsync<{ data: any }>(UPDATE_CLIENT, body);
    //console.log("data", data);
    return data;
  }
  async UpdateLead(body: LeadModel) {
    const { data } = await this.postAsync<{ data: any }>(UPDATE_LEAD, body);
    //console.log("data", data);
    return data;
  }

  async deleteLead(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${DELETE_LEAD}?lead_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getSelectedLead(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${SELECT_LEAD}?lead_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  async createLead(body: LeadModel) {
    const { data } = await this.postAsync<{ data: any }>(CREATE_LEAD, body);
    //console.log("data", data);
    return data;
  }

  async getAllclientRoster(id: number | null, providerId: number) {
    const data: any = await this.getAsync(
      `${GETALL_CLIENT_ROSTER}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async updateStaffRosterStatus(
    rosterId: number | null,
    rosterLineId: number | null,
    status: string,
    lastDay: number | null
  ) {
    const data: any = await this.getAsync(
      `${STAFF_ROSTER_STATUS}?roster_id=${rosterId}&roster_line_id=${rosterLineId}&status=${status}&last_day=${lastDay}`
    );
    //console.log("data",data)
    return data;
  }
  async createFeedback(body: FeedbackModel) {
    const { data } = await this.postAsync<{ data: any }>(CREATE_FEEDBACK, body);
    //console.log("data", data);
    return data;
  }
}

export default ClientService;
