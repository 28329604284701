import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ClientModel } from "../../Models/ClientModel";
import ClientServices from "../../Services/ClientServices";
import BasicTabs from "./Tab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LinkButton from "../../Components/Button/LinkButton";
const ClientService = new ClientServices();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  clientId: string | null;
  clientInfo: ClientModel;
  isView: boolean;
}

class ContactData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      clientId: "",
      clientInfo: {} as ClientModel,
      isView: true,
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var number = parseInt(match[1]);
        const response = await ClientService.getSelectedClient(number, JSON.parse(userInfo).provider_id);

        this.setState({
          clientInfo: response.data,
          clientId: number.toString(),
        });
      }
      // const searchParams = new URLSearchParams(this.props.location.search);
      // if (searchParams.get("client_id")) {
      //   const response = await ClientService.getSelectedClient(
      //     Number(searchParams.get("client_id"))
      //   );
      //   this.setState({
      //     clientInfo: response.data,
      //     clientId: searchParams.get("client_id"),
      //   });
      // }
    }
  }
  render() {
    const { clientInfo } = this.state;
    return (
      <Box>
        <Grid item container>
          <Grid
            item
            display="flex"
            justifyContent="center"
            marginRight={4}
            marginTop={1}
          >
            <ArrowBackIosIcon
              style={{ marginTop: 4, fontSize: "0.8rem", fontWeight: 500 }}
            />
            <LinkButton
              label="Back"
              onClick={() => {
                this.props.history.push(`/contacts`);
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              marginBottom={5}
              style={{ fontSize: "1.7rem", fontWeight: 550 }}
            >
              {clientInfo.client_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item container xs={12} mb={2}>
            <Card
              style={{
                height: "auto",
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
              }}
            >
              <CardContent>
                <Grid item container justifyContent="space-between" mb={2}>
                  <Typography style={{ fontSize: "1.2rem", fontWeight: 550 }}>
                    Client Profile
                  </Typography>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    onClick={() =>
                      this.setState({ isView: !this.state.isView })
                    }
                  >
                    {this.state.isView ? "Hide" : "View"}
                  </Button>
                </Grid>

                <Divider />
                {this.state.isView ? (
                  <Grid container>
                    <Grid item container xs={4} mt={2}>
                      <Card
                        style={{
                          height: "auto",
                          width: "97%",
                          boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
                        }}
                      >
                        <CardContent>
                          <Grid item container xs={12} mt={2}>
                            <Grid item xs={2.5}>
                              <div
                                className="box"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "60px",
                                  height: "60px",
                                  border: "2px solid #862d2d",
                                  borderRadius: "15px",
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                  color: "white",
                                  backgroundColor: "#862d2d",
                                }}
                              >
                                {clientInfo.client_name
                                  ?.slice(0, 2)
                                  .toUpperCase()}
                              </div>
                            </Grid>
                            <Grid item xs={9.5}>
                              <Grid item>
                                <Typography>
                                  <span
                                    style={{
                                      color: "GrayText",
                                      marginRight: 10,
                                    }}
                                  >
                                    Name:
                                  </span>
                                  {clientInfo.client_name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  <span
                                    style={{
                                      color: "GrayText",
                                      marginRight: 10,
                                    }}
                                  >
                                    Email:
                                  </span>
                                  {clientInfo.client_email}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  <span
                                    style={{
                                      color: "GrayText",
                                      marginRight: 10,
                                    }}
                                  >
                                    Date Of Birth:
                                  </span>
                                  {clientInfo.client_dob}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  <span
                                    style={{
                                      color: "GrayText",
                                      marginRight: 10,
                                    }}
                                  >
                                    Gender:
                                  </span>
                                  {clientInfo.client_gender}
                                </Typography>
                              </Grid>
                              <Grid item marginBottom={5}>
                                <Typography>
                                  <span
                                    style={{
                                      color: "GrayText",
                                      marginRight: 10,
                                    }}
                                  >
                                    Status:
                                  </span>
                                  {clientInfo.client_status}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item container xs={4} mt={2}>
                      <Card
                        style={{
                          height: "auto",
                          width: "97%",
                          boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
                        }}
                      >
                        <CardContent>
                          <Grid item container xs={12}>
                            <Grid item xs={12}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  Address:
                                </span>
                                {clientInfo.client_address}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid item xs={12}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  City:
                                </span>
                                {clientInfo.client_city}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid item xs={12}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  State:
                                </span>
                                {clientInfo.client_state}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid item xs={12}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  Phone:
                                </span>
                                {clientInfo.client_phone}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item container xs={4} mt={2}>
                      <Card
                        style={{
                          height: "auto",
                          width: "80%",
                          boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
                        }}
                      >
                        <CardContent>
                          <Grid item container xs={12}>
                            <Grid item xs={12}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  Preferred Language:
                                </span>
                                {clientInfo.client_preferred_language}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  Interpreter Required:
                                </span>
                                {clientInfo.client_interpreter_required}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  ATSI Background:
                                </span>
                                {clientInfo.client_atsi_background}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} marginBottom={2}>
                              <Typography>
                                <span
                                  style={{ color: "GrayText", marginRight: 10 }}
                                >
                                  CALD Background:
                                </span>
                                {clientInfo.client_cald_background}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item container xs={12}>
            <Box
              style={{
                height: "3em",
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
              }}
            >
              <BasicTabs />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withRouter(ContactData);
