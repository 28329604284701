import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  Divider,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface Props {
  isOpen: boolean;
  title?: string;
  dialogAction?: JSX.Element;
  dialogWidth: DialogProps["maxWidth"];
  onClose: () => void;
  children?: React.ReactNode;
}
interface State {}

class ModalDialog extends React.Component<Props, State> {
  public static defaultProps = {
    isOpen: false,
    title: "Dialog Modal",
    dialogWidth: "lg",
  };

  render() {
    const { isOpen, onClose, title, children, dialogAction, dialogWidth } =
      this.props;
    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth={dialogWidth}
        onClose={onClose}
      >
        <Grid item container>
          <Grid item xs={11}>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-start">
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              onClick={onClose}
              style={{ cursor: "pointer", marginTop: 22 }}
            />
          </Grid>
        </Grid>

        <Divider
          orientation="horizontal"
          style={{ marginBottom: 10, marginLeft: 20, marginRight: 20 }}
        />
        <DialogContent>{children}</DialogContent>
        <DialogActions>{dialogAction}</DialogActions>
      </Dialog>
    );
  }
}

export default ModalDialog;
