import React, { useState, useEffect } from 'react';
import './profiles.css';
import { useHistory } from 'react-router-dom';
import Enviroment from "./../../config.json";
const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";

interface Role {
  role_name: string;
  role_description: string
}
interface ProfilesProps {
  onNewProfileClick: () => void; // Adjust the function signature as needed
}
const Profiles: React.FC<ProfilesProps> = ({onNewProfileClick}) => {
  const [roles, setRoles] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState<any>();
  const urlGetAllRoles: string = baseURL + "/get_all_roles";
  const history = useHistory();
  const headers: any = {
    'Content-Type': 'application/json'
  }
  const method: string = 'GET';
  
  useEffect(() => {
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('userInfo')) {
      const userInfoFromSession = sessionStorage.getItem('userInfo');
      setUserInfo(userInfoFromSession);
    }
  }, []);
  useEffect(() => {
    const fetchRoles = async () => {
      if (userInfo) {
          try {
          const { provider_id } = JSON.parse(userInfo);
          const queryParams = new URLSearchParams({ provider_id });
          const urlWithParams = `${urlGetAllRoles}?${queryParams}`;
          const response = await fetch(urlWithParams, { method, headers });
          const data = await response.json();
          console.log(data);
          setRoles(data);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchRoles();
  }, [userInfo]);
  return (
    <div className="profile-main-container">
      <span className="profile-title">Profiles</span>
      <span className="profile-subtitle">Profile is a set of permissions dealing with module access and operations, setup customizations, and access to various apps. You can provide
        different set of permissions to various users.
      </span>
      <button className="profile-add-profile-btn" onClick = {onNewProfileClick}>
        New Profile
      </button>
      <div className="profile-table">
        <table className = "table-profile">
          <thead>
            <tr>
              <th className="profile-table-column-black-text th-profile" style={{ width: '20%' }}>Profile Name</th>
              <th className="profile-table-column-black-text th-profile" style={{ width: '80%' }}>Profile Description</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role: any, index: number) => (
              <tr key={index}>
                <td className="profile-table-column-blue-text td-profile" style={{ width: '20%' }} onClick = {()=>{history.push(`/profile/${role.role_id}`)}}>{role.role_name}</td>
                <td className="profile-table-column-black-text td-profile" style={{ width: '80%' }}>{role.role_description}</td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>

    </div>
  );
};

export default Profiles;