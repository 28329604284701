import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "../../Components/Button/IconButton";
import DatePicker from "../../Components/DatePicker/DatePicker";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { UploadDocumentModel } from "../../Models/DocumentUploadModel";
import StaffService from "../../Services/StaffService";
import Environment from "../../config.json";
const StaffServices = new StaffService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  staffId: number;
  DocumentInfo: UploadDocumentModel;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  fileName: string;
  documentsName: any;
  uploadedFile: any;
  filesName: any;
  staffDocId: any;
  docStatus: any;
  uploadedDate: any;
  docNameObj: any;
  docPathObj: any;
  isTrainingOpen: boolean;
  issueDateObj: any;
  expiryDateObj: any;
  userInfo: any;
  isAddDoc: boolean;
  docname: string;
}

class Document extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      staffId: 0,
      DocumentInfo: {} as UploadDocumentModel,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      fileName: "",
      documentsName: [],
      uploadedFile: {},
      filesName: {},
      staffDocId: {},
      docStatus: {},
      uploadedDate: {},
      docNameObj: {},
      docPathObj: {},
      isTrainingOpen: false,
      issueDateObj: {},
      expiryDateObj: {},
      userInfo: {},
      isAddDoc: false,
      docname: "",
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");
      //console.log(match);
      if (match && userInfo) {
        var staffId = parseInt(match[1]);
        const documentName = await StaffServices.getStaffDocumentsName(
          JSON.parse(userInfo).provider_id
        );

        this.getAllDocuments(staffId, JSON.parse(userInfo).provider_id);

        this.setState({
          DocumentInfo: {
            ...this.state.DocumentInfo,
            staff_id: "",
            provider_id: 1,
            client_id: staffId,
            document_type: "",
          },
          documentsName: documentName.data,
          staffId,
          userInfo: JSON.parse(userInfo),
        });
      }
    }
  }
  getAllDocuments = async (staffId: number, provider_id: number) => {
    const allDocuments = await StaffServices.getAllStaffDocuments(
      staffId,
      provider_id
    );

    const docObj: any = {};
    const staffDocIdObj: any = {};
    const docStatusObj: any = {};
    const dateObj: any = {};
    const docNameObj: any = {};
    const docPathObj: any = {};
    const issueDateObj: any = {};
    const expiryDateObj: any = {};

    allDocuments.data.forEach((el: any) => {
      const document_id = el.document_id;
      docObj[document_id] = el.document_name;
      staffDocIdObj[document_id] = el.staff_document_id;
      docStatusObj[document_id] = el.status;
      dateObj[document_id] = el.created_on;
      docNameObj[document_id] = el.document_name;
      docPathObj[document_id] = el.document_path;
      issueDateObj[document_id] = el.document_issuedate;
      expiryDateObj[document_id] = el.document_expiredate;
    });

    this.setState({
      staffId,
      filesName: docObj,
      staffDocId: staffDocIdObj,
      docStatus: docStatusObj,
      uploadedDate: dateObj,
      docNameObj,
      docPathObj,
      expiryDateObj,
      issueDateObj,
    });
  };

  onDocumentupload = async (
    event: React.FormEvent,
    docId: number,
    ind?: number
  ) => {
    event.preventDefault();

    try {
      const {
        staffId,
        uploadedFile,
        issueDateObj,
        expiryDateObj,
        documentsName,
        userInfo,
      } = this.state;
      const response = await StaffServices.uploadDocuments(
        Number(staffId),
        userInfo.provider_id,
        docId,
        documentsName[ind!].document_name,
        issueDateObj[docId],
        expiryDateObj[docId],
        uploadedFile
      );
      if (response) {
        this.getAllDocuments(staffId, userInfo.provider_id);
        this.setState({
          openAlert: true,
          alertMsg: "Document uploaded successfully",
          alertType: "success",
        });
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };

  onDelete = async (docId: number) => {
    const { staffDocId, staffId, userInfo } = this.state;
    //console.log(docId, staffDocId);
    const response = await StaffServices.deleteDocuments(
      staffDocId[docId],
      staffId,
      userInfo.provider_id
    );
    if (response) {
      this.getAllDocuments(staffId, userInfo.provider_id);
      this.setState({
        openAlert: true,
        alertMsg: "Document deleted successfully",
        alertType: "success",
      });
    }
  };
  onDownload = async (docId: number) => {
    const { docNameObj, docPathObj } = this.state;
    window.open(
      `${Environment.Enviroment.dev.BASE_API_URL}/download_document?file_path=${docPathObj[docId]}&file_name=${docNameObj[docId]}`,
      "_blank"
    );
  };
  onIssueDateChange = (value: Date | null, id: number) => {
    //const { staffInfo } = this.state;
    //console.log(value, id);
    let docId1 = this.state.documentsName[id].document_id;
    if (value) {
      this.setState((prevState) => ({
        ...prevState,
        issueDateObj: {
          ...prevState.issueDateObj,
          [docId1]: value.toLocaleDateString("en-CA"),
        },
      }));
    }
  };
  onExpiryDateChange = (value: Date | null, id: number) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      let docId1 = this.state.documentsName[id].document_id;
      if (value) {
        this.setState((prevState) => ({
          ...prevState,
          expiryDateObj: {
            ...prevState.expiryDateObj,
            [docId1]: value.toLocaleDateString("en-CA"),
          },
        }));
      }
    }
  };
  onTrainingDocOpen = () => {
    this.setState({ isTrainingOpen: !this.state.isTrainingOpen });
  };
  handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    docName: string,
    docId: number,
    id: number
  ) => {
    const { target } = event;
    //console.log(docId, docName);
    let docId1 = this.state.documentsName[id].document_id;
    if (target.files && target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", target.files[0]);

      // this.setState({ uploadedFile: formData });
      const fileName = target.files[0].name;

      this.setState(
        (prevState) => ({
          ...prevState,
          filesName: {
            ...prevState.filesName,
            [docId1]: fileName,
          },
        }),
        () => {
          this.setState({ uploadedFile: formData });
        }
      );

      //console.log(response);
    }
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      docname: event.target.value,
    });
  };
  handleSaveDocName = async () => {
    const { docname, userInfo, staffId } = this.state;

    const response = await StaffServices.addDocumentsName(
      docname,
      userInfo.provider_id
    );
    const documentName = await StaffServices.getStaffDocumentsName(
      userInfo.provider_id
    );

    this.getAllDocuments(staffId, userInfo.provider_id);

    if (response) {
      this.setState({
        openAlert: true,
        alertMsg: "Document Name Added Successfully",
        alertType: "success",
        isAddDoc: false,
        documentsName: documentName.data,
      });
    }
  };
  render() {
    const {
      openAlert,
      alertMsg,
      alertType,

      documentsName,
      filesName,
      docStatus,
      uploadedDate,
      issueDateObj,
      expiryDateObj,
      isAddDoc,
      docname,
    } = this.state;
    //console.log("issueDateObj", issueDateObj, expiryDateObj, documentsName);
    let fileExplorerStyle = {
      // color: "#FFFFFF",
      cursor: "pointer",
      fontWeight: 400,
      fontSize: "1rem",
      height: "2rem",
    };
    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container justifyContent="space-between">
              <Typography
                marginBottom={1}
                style={{ fontSize: "1rem", fontWeight: 550 }}
              >
                Documents
              </Typography>
              <Button
                style={{
                  height: "2.5rem",
                  textTransform: "none",
                  marginLeft: 5,
                }}
                variant="contained"
                onClick={() => {
                  this.setState({
                    isAddDoc: true,
                  });
                }}
              >
                + Add Document Name
              </Button>
            </Grid>
            <hr />
            <ModalDialog
              isOpen={isAddDoc}
              dialogWidth="md"
              title="Add Document Name"
              onClose={() => {
                this.setState({
                  isAddDoc: false,
                });
              }}
            >
              <Grid item container>
                <Grid item xs={8} mr={3}>
                  <TextInputField
                    id="docname"
                    value={docname}
                    onChange={this.onInputChange}
                    placeholder="Enter Document Name"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    disabled={docname.length > 0 ? false : true}
                    style={{
                      height: "2.5rem",
                      textTransform: "none",
                      marginLeft: 5,
                    }}
                    variant="contained"
                    onClick={this.handleSaveDocName}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </ModalDialog>
            <form>
              <TableContainer component={Paper} style={{ overflow: "hidden" }}>
                <Grid
                  container
                  item
                  flexDirection={"column"}
                  marginTop={3}
                  paddingLeft={2}
                  paddingRight={2}
                >
                  <Grid container item columnSpacing={2}>
                    <Grid item xs={1.5}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        Document Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        File Upload
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        File Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        Issue Date
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        Expiry Date
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        Uploaded On
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        Action
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <hr />
                <Grid container>
                  {documentsName.map((document: any, index: any) => {
                    if (
                      !document.document_name.includes(
                        "Training Completion Certificates"
                      )
                    )
                      return (
                        <Grid
                          container
                          item
                          mt={5}
                          columnSpacing={2}
                          paddingLeft={2}
                          paddingRight={2}
                          paddingBottom={2}
                          key={index}
                        >
                          <Grid item xs={1.5}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {document.document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id={`my_file-${index}`}
                                onChange={(event) =>
                                  this.handleFileChange(
                                    event,
                                    document.document_name,
                                    document.document_id,
                                    index
                                  )
                                }
                              />
                              <label
                                htmlFor={`my_file-${index}`}
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[document.document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5}>
                            <DatePicker
                              value={
                                new Date(issueDateObj[document.document_id])
                              }
                              onChange={(value) =>
                                this.onIssueDateChange(value, index)
                              }
                            />
                          </Grid>
                          <Grid item xs={1.5}>
                            <DatePicker
                              value={
                                new Date(expiryDateObj[document.document_id])
                              }
                              onChange={(value) =>
                                this.onExpiryDateChange(value, index)
                              }
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[document.document_id]
                                ? new Date(
                                    uploadedDate[document.document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[document.document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[document.document_id] ? false : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  document.document_id,
                                  index
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[document.document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(document.document_id)
                                  }
                                />

                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(document.document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      );
                  })}
                </Grid>
                <Box mt={5} padding={2}>
                  <Grid container item>
                    <Grid item xs={2}>
                      <Typography
                        marginBottom={1}
                        style={{ fontSize: "1rem", fontWeight: 550 }}
                      >
                        Training Completion Certificates
                      </Typography>
                    </Grid>
                    <Grid item xs={0.5}>
                      {/* <Button
                                style={{
                                  height: "2.5rem",
                                  textTransform: "none",
                                  marginLeft: 5,
                                }}
                                variant="contained"
                                onClick={this.onTrainingDocOpen}
                              >
                                {this.state.isTrainingOpen ? "- Add" : "+ Add"}
                              </Button> */}
                      {this.state.isTrainingOpen ? (
                        <Tooltip
                          title={<Typography fontSize={16}>Remove</Typography>}
                        >
                          <RemoveCircleOutlineIcon
                            style={{
                              color: "#1976d2",
                              cursor: "pointer",
                            }}
                            onClick={this.onTrainingDocOpen}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={<Typography fontSize={16}>Add</Typography>}
                        >
                          <AddCircleOutlineIcon
                            style={{
                              color: "#1976d2",
                              cursor: "pointer",
                            }}
                            onClick={this.onTrainingDocOpen}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>

                  <hr />
                  {this.state.isTrainingOpen ? (
                    <>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[10].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file10"
                              onChange={(event, ind: number = 10) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[10].document_name,
                                  documentsName[10].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file10"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[10].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[10].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 10)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[10].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 10)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[10].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[10].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[10].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[10].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[10].document_id,
                                10
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[10].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[10].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[10].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[11].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file11"
                              onChange={(event, ind: number = 11) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[11].document_name,
                                  documentsName[11].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file11"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[11].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[11].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 11)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[11].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 11)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[11].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[11].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[11].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[11].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[11].document_id,
                                11
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[11].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[11].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[11].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[12].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file12"
                              onChange={(event, ind: number = 12) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[12].document_name,
                                  documentsName[12].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file12"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[12].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[12].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 12)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[12].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 12)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[12].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[12].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[12].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[12].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[12].document_id,
                                12
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[12].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[12].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[12].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[13].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file13"
                              onChange={(event, ind: number = 13) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[13].document_name,
                                  documentsName[13].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file13"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[13].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[13].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 13)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[13].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 13)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[13].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[13].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[13].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[13].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[13].document_id,
                                13
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[13].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[13].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[13].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[14].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file14"
                              onChange={(event, ind: number = 14) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[14].document_name,
                                  documentsName[14].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file14"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[14].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[14].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 14)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[14].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 14)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[14].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[14].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[14].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[14].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[14].document_id,
                                14
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[14].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[14].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[14].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[15].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file15"
                              onChange={(event, ind: number = 15) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[15].document_name,
                                  documentsName[15].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file15"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[15].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[15].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 15)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[15].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 15)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[15].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[15].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[15].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[15].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[15].document_id,
                                15
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[15].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[15].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[15].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[16].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file16"
                              onChange={(event, ind: number = 16) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[16].document_name,
                                  documentsName[16].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file16"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[16].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[16].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 16)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[16].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 16)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[16].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[16].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[16].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[16].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[16].document_id,
                                16
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[16].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[16].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[16].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[17].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file17"
                              onChange={(event, ind: number = 17) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[17].document_name,
                                  documentsName[17].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file17"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[17].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[17].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 17)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[17].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 17)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[17].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[17].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[17].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[17].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[17].document_id,
                                17
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[17].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[17].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[17].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[18].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file18"
                              onChange={(event, ind: number = 18) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[18].document_name,
                                  documentsName[18].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file18"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[18].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[18].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 18)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[18].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 18)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[18].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[18].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[18].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[18].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[18].document_id,
                                18
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[18].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[18].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[18].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container item mt={5} columnSpacing={2}>
                        <Grid item xs={1.5}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {documentsName[19].document_name.replace(
                              /Training Completion Certificates-/i,
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Button
                            variant="outlined"
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                            }}
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="my_file19"
                              onChange={(event, ind: number = 19) =>
                                this.handleFileChange(
                                  event,
                                  documentsName[19].document_name,
                                  documentsName[19].document_id,
                                  ind
                                )
                              }
                            />
                            <label
                              htmlFor="my_file19"
                              style={fileExplorerStyle}
                            >
                              &nbsp;Upload
                            </label>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {filesName[documentsName[19].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                issueDateObj[documentsName[19].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onIssueDateChange(value, 19)
                            }
                          />
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            value={
                              new Date(
                                expiryDateObj[documentsName[19].document_id]
                              )
                            }
                            onChange={(value) =>
                              this.onExpiryDateChange(value, 19)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                          >
                            {uploadedDate[documentsName[19].document_id]
                              ? new Date(
                                  uploadedDate[documentsName[19].document_id]
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            marginBottom={1}
                            style={{
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "green",
                            }}
                          >
                            {docStatus[documentsName[19].document_id]}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disabled={
                              filesName[documentsName[19].document_id]
                                ? false
                                : true
                            }
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) =>
                              this.onDocumentupload(
                                event,
                                documentsName[19].document_id,
                                19
                              )
                            }
                          >
                            Save
                          </Button>
                          {docStatus[documentsName[19].document_id] ? (
                            <>
                              <IconButton
                                IconType="Download"
                                onClick={() =>
                                  this.onDownload(documentsName[19].document_id)
                                }
                              />

                              <IconButton
                                IconType="Delete"
                                onClick={() =>
                                  this.onDelete(documentsName[19].document_id)
                                }
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Box>
              </TableContainer>
            </form>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(Document);
