import React, { Component } from "react";
import {
  AlertColor,
  Box,
  Button,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import SingleSelect from "../../Components/Select/SingleSelect";
import SelectModel from "../../Components/Select/SelectModel";
import NewAgreementService from "../../Services/NewAgreementService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DataLoader from "../../Components/Table/DataLoader";
import { NewServiceAgreementModel } from "../../Models/NewServiceAgreementModel";
import moment from "moment";
import Utils from "../../Common/Utils";

const AgreementService = new NewAgreementService();

interface Props extends RouteComponentProps<any, any, any> {}
interface State {
  page: number;
  rows: number;
  totalRecordsCount: number;
  isEdit: boolean;
  filterData: any;
  searchText: string;
  agreementDisplayData: NewServiceAgreementModel[];

  agreementData: [];
  userInfo: any;
}
class ServiceAgreement extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agreementDisplayData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      isEdit: false,
      filterData: [],
      searchText: "",
      agreementData: [],
      userInfo: {},
    };
  }
  async componentDidMount() {
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      this.setState({ userInfo: JSON.parse(userInfo) });

      const response = await AgreementService.getServiceAgreementsDropDownValue(
        JSON.parse(userInfo).provider_id
      );
      const agreementData = response.data.map((el: any) => {
        return {
          id: el.client_id,
          text: el.client_name,
          value: el.client_name,
        };
      });
      this.setState({
        agreementData: agreementData,
      });
    }
  }

  onDropDownChange = async (selected: SelectModel, targetId?: string) => {
    const isValidDate = Utils.isValidDate;
    const client_id = selected["id"] || 18;
    const response = await AgreementService.getServiceAgreements(
      client_id,
      this.state.userInfo.provider_id
    );
    const data = response.data.map((el: any, i: any) => {
      return {
        Id: i + 1,
        support_item_name: el.support_item_name,
        start_date: el.start_date,
        end_date: el.end_date,
        start_time: isValidDate(el.start_time)
          ? moment(el.start_time).format("h:mm:ss a")
          : el.start_time,
        end_time: isValidDate(el.end_time)
          ? moment(el.end_time).format("h:mm:ss a")
          : el.end_time,
        //   creation_date: new Date(el.created_on).toDateString(),
      };
    });
    console.log(data);

    this.setState({
      agreementDisplayData: data,
    });
  };
  onSearchChange = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const filterData = this.state.agreementDisplayData.filter(
      (el: NewServiceAgreementModel) =>
        el.support_item_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    this.setState({
      filterData,
    });
    this.setState({ searchText: event.target.value });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  render() {
    const {
      agreementDisplayData,
      page,
      rows,
      totalRecordsCount,
      isEdit,
      filterData,
      searchText,
      agreementData,
    } = this.state;
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ display: "flex" }}>
          <Typography
            marginBottom={5}
            style={{ fontSize: "1.7rem", fontWeight: 550 }}
          >
            Service Agreement
          </Typography>
        </Box>
        <Grid container marginBottom={4}>
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <SingleSelect
              value="Select Client"
              id="service_agreement"
              size="small"
              values={agreementData}
              onChange={this.onDropDownChange}
            />
          </Grid>
        </Grid>

        <Box>
          <DataLoader
            totalRecordsCount={
              searchText ? filterData.length : totalRecordsCount
            }
            onChangePage={this.onChangePage}
            onChangeRow={this.onChangeRow}
            //   onActionClick={this.onActionClick}
            tableData={
              searchText
                ? rows > 0
                  ? filterData.slice(page * rows, page * rows + rows)
                  : filterData
                : rows > 0
                ? agreementDisplayData.slice(page * rows, page * rows + rows)
                : agreementDisplayData
            }
            //   onClickLink={this.onClickName}
            // actionArray={["Edit", "Delete"]}
          />
        </Box>
      </Box>
    );
  }
}

export default withRouter(ServiceAgreement);
