import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import ServiceAgreement from "./ServiceAgreement";
import Roster from "./Roster";
import NDIS from "./NDIS";
import AssociatedContacts from "./AssociatedContacts";
import Referral from "./Referral";
import Assessment from "./Assessment";
import CaseNotes from "./CaseNotes";
import EmailPage from "./EmailPage";
import FundTracking from "./FundTracking";
import Document from "./Document";
import ShiftApproval from "./ShiftApproval";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ marginTop: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}
const tabStyle: React.CSSProperties = {
  textTransform: "none",
  fontSize: "1rem",
};
export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", height: 24 }}>
      <Tabs
        scrollButtons={true}
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="horizontal tabs example"
        sx={{ borderRight: 1, borderColor: "divider", overflow: "auto" }}
      >
        <Tab label="Referrals" style={tabStyle} {...a11yProps(0)} />
        <Tab label="NDIS Plan" style={tabStyle} {...a11yProps(1)} />
        <Tab label="Assessment" style={tabStyle} {...a11yProps(2)} />
        <Tab label="Associated Contacts" style={tabStyle} {...a11yProps(3)} />
        <Tab label="Service Agreement" style={tabStyle} {...a11yProps(4)} />
        <Tab label="Roster" style={tabStyle} {...a11yProps(5)} />
        <Tab label="Fund Tracking" style={tabStyle} {...a11yProps(6)} />
        <Tab label="Mailmerge" style={tabStyle} {...a11yProps(7)} />
        <Tab label="Documents" style={tabStyle} {...a11yProps(8)} />
        <Tab label="Email" style={tabStyle} {...a11yProps(9)} />
        <Tab label="Case Notes" style={tabStyle} {...a11yProps(10)} />
        <Tab label="Shift Approval" style={tabStyle} {...a11yProps(11)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Referral />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NDIS />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Assessment />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AssociatedContacts />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ServiceAgreement />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Roster />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <FundTracking />
      </TabPanel>
      <TabPanel value={value} index={7}>
        Item Seven
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Document />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <EmailPage />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <CaseNotes />
      </TabPanel>
      <TabPanel value={value} index={11}>
        <ShiftApproval />
      </TabPanel>
    </Box>
  );
}
