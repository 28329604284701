import {
  IconDefinition,
  faCaretDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { FIELDS_TO_LINK } from "../../Common/Constants";
import { IconType } from "../Button/IconButton";
import Table from "./Table";

interface Props {
  onClickCheckBox?: (selectedRows: any) => void;
  tableData: any;
  actionArray?: IconType[];
  IconType?: IconDefinition;
  totalRecordsCount?: number;
  onActionClick?: (action: IconType, recordId: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
}
interface State {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  totalCount: number;
  showstep: boolean;
  caretHover: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: "none",
    cursor: "pointer",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 20,
  },
}));

class DataLoader extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      totalCount: 0,
      showstep: false,
      caretHover: false,
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      this.props.tableData &&
      !isEqual(prevProps.tableData[0], this.props.tableData[0])
    ) {
      this.changeHeadCellData();
    }
    if (prevProps.IconType !== this.props.IconType) {
      this.changeHeadCellData();
    }
    if (prevProps.actionArray?.length !== this.props.actionArray?.length) {
      this.changeHeadCellData();
    }
  };

  componentDidMount = () => {
    this.changeHeadCellData();
  };

  onCaretHover = () => {
    this.setState({ caretHover: true });
  };

  changeHeadCellData = () => {
    //console.log("this.props.tableData - ", this.props.tableData)
    if (this.props.tableData.length) {
      const headerData: any = Object.keys(this.props.tableData[0]).map((k) => {
        //console.log("k", k);
        const shouldBeLinked = Object.keys(FIELDS_TO_LINK).includes(k);
        return {
          key: k,
          label: k,
          linkTo: shouldBeLinked
            ? FIELDS_TO_LINK[k as keyof typeof FIELDS_TO_LINK]
            : null,
          elementToLoad:
            k === "LvlAvl" ? (
              <>
                <LightTooltip placement="bottom" title={""} arrow>
                  <FontAwesomeIcon icon={faCaretDown} />
                </LightTooltip>
              </>
            ) : null,
        };
      });
      // console.log("this.props.actionArray?.length",this.props.actionArray?.length);
      if (this.props.actionArray?.length) {
        headerData.push({
          key: "Action",
          label: "Action",
          linkTo: null,
        });
      }
      this.setState({ headCellData: headerData });
    } else {
      this.setState({ headCellData: [] });
    }
  };

  onChangePage = (page: number) => {
    this.props.onChangePage(page);
  };

  handleCaretClick = () => {
    this.setState({ showstep: true });
  };

  onChangeRow = (row: number) => {
    this.props.onChangeRow(row);
  };
  handleCaretCloseClick = () => {
    this.setState({ showstep: false });
  };

  render() {
    const { headCellData, showstep } = this.state;
    // console.log("headCellData - ", headCellData);

    return (
      <>
        <Table
          onActionClick={this.props.onActionClick}
          actionArray={this.props.actionArray}
          data={this.props.tableData}
          onClickCheckBox={this.props.onClickCheckBox}
          totalRecordsCount={this.props.totalRecordsCount || 0}
          headCellData={headCellData}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          sortConfig={null}
          onClickLink={this.props.onClickLink}
          isLoading={this.props.isLoading}
          fieldsToColor={this.props.fieldsToColor}
        />
      </>
    );
  }
}

export default DataLoader;
