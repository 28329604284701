import TextField from "@mui/material/TextField";
//import { Box } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as DatePickerMUI } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Moment } from "moment";
import { Component } from "react";

interface Props {
  label?: string;
  value?: Date | null;
  placeHolder?: string;
  /**
   * @property pass Moment Object here
   */
  maxDate?: any;
  /**
   * @property pass Moment Object here
   */
  minDate?: any;
  disabled?: boolean;
  width?: string;
  isRequired?: boolean;
  name?: string;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined,
    name?: string
  ) => void;
}

interface State {}

class DatePicker extends Component<Props, State> {
  state = {};

  render() {
    const { value, label, maxDate, minDate, disabled, width, isRequired } =
      this.props;
    //console.log(value);
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePickerMUI
          InputProps={{ style: { height: "2.5em", width: width || "auto" } }}
          label={label}
          value={value}
          disabled={disabled}
          onChange={this.handleDateChange}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(params) => {
            //console.log(params);
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Select",
                  "aria-required": isRequired,
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    );
  }
  handleDateChange = (
    value: Moment | null,
    keyboardInputValue?: string | undefined
  ) => {
    //console.log("this.props.name", this.props.name);
    // const selectedDate: Date | null = value ? new Date(value) : null;
    this.props.onChange(
      value?.toDate() as unknown as Date,
      keyboardInputValue,
      this.props.name
    );
  };
}

export default DatePicker;
