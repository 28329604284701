import React from "react";
import { Box, TextField } from "@mui/material";

interface Props {
  id?: string;
  label?: string;
  isReadonly: boolean;
  isDisabled: boolean;
  endAdornment?: React.ReactNode;
  value?: string | number;
  width?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  isRequired?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  minValue?: number;
  maxValue?: number;
}
interface State {}
class TextInputField extends React.Component<Props, State> {
  public static defaultProps = {
    isReadonly: false,
    isDisabled: false,
    placeholder: "",
    isRequired: false,
  };

  render() {
    const {
      id,
      label,
      isReadonly,
      isDisabled,
      isRequired,
      endAdornment,
      value,
      onChange,
      placeholder,
      width,
      name,
      type,
      minValue,
      maxValue,
    } = this.props;

    let inputType = "text";
    let inputValue: string | number = "";

    if (typeof value === "number") {
      inputType = "number";
    }

    if (value) {
      inputValue = value;
    }

    return (
      <Box style={{ width: width }}>
        <TextField
          disabled={isDisabled}
          type={type || inputType}
          variant="outlined"
          required={isRequired}
          size="small"
          name={name}
          fullWidth
          InputProps={{
            required: isRequired,
          }}
          aria-required
          placeholder={placeholder}
          id={id}
          label={label}
          value={inputValue}
          onChange={onChange}
        />
      </Box>
    );
  }
}

export default TextInputField;
