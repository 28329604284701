import { URIs } from "../Common/Constants";

import { AssessmentModel } from "../Models/AssessmentModel";
import GenericAPIService from "./GenericAPIService";

const {
  GET_ASSESSMENT,
  UPDATE_ASSESSMENT,
  CREATE_ASSESSMENT,
  DELETE_ASSESSMENT,
} = URIs;
class ReferralService extends GenericAPIService {
  async getAssessmentById(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_ASSESSMENT}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async deleteAssessmentById(
    assessmentId: number,
    id: number,
    providerId: number
  ) {
    const data: any = await this.getAsync(
      `${DELETE_ASSESSMENT}?client_assessmentid=${assessmentId}&client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  // async getAssignedSources(formId?: number) {
  //     const { data } = await this.getAsync<{ data: AvailableFormValueModel }>(`${ASSIGNED_SOURCE}/${formId}`);
  //     return data.data;
  // }

  async createAssessment(body: AssessmentModel) {
    const { data } = await this.postAsync<{ data: any }>(
      CREATE_ASSESSMENT,
      body
    );
    //console.log("data", data);
    return data;
  }
  async UpdateAssessment(body: AssessmentModel) {
    const { data } = await this.postAsync<{ data: any }>(
      UPDATE_ASSESSMENT,
      body
    );
    //console.log("data", data);
    return data;
  }
}

export default ReferralService;
