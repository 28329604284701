import { URIs } from "../Common/Constants";
import GenericAPIService from "./GenericAPIService";

const { GET_SERVICE_DROPDOWN, GET_SERVICE_LINE } = URIs;

class NewAgreementService extends GenericAPIService {
  async getServiceAgreements(client_id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_SERVICE_LINE}?client_id=${client_id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  async getServiceAgreementsDropDownValue(providerId: number) {
    const data: any = await this.getAsync(
      `${GET_SERVICE_DROPDOWN}?provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
}

export default NewAgreementService;
