import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";

interface Props {
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  label?: "";
  value: any;
  placeholder?: string;
  id?: string;
}

interface State {}

class SearchFilter extends React.Component<Props, State> {
  render() {
    const { label, onChange, value, placeholder,id } = this.props;

    return (
      <Grid container>
        <Grid item>
          <TextField
            size="small"
            id={id}
            label={label}
            value={value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    color="#0086ff"
                    icon={faMagnifyingGlass}
                    cursor="pointer"
                  />
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    );
  }
}

export default SearchFilter;
