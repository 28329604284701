import {
  AlertColor,
  Box,
  Button,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IconType } from "../../Components/Button/IconButton";

import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import DataLoader from "../../Components/Table/DataLoader";

import SearchFilter from "../../Components/Search/SearchFilter";

import ModalDialog from "../../Components/Modal/ModelDialog";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { LeadModel } from "../../Models/LeadModel";
import { ClientModel } from "../../Models/ClientModel";
import ClientServices from "../../Services/ClientServices";
import DatePicker from "../../Components/DatePicker/DatePicker";
const ClientService = new ClientServices();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  showEdit: boolean;
  isAddClient: boolean;
  recordMarked: any;
  searchText: string;
  clientInfo: LeadModel;
  countryData: SelectModel[];
  stateData: SelectModel[];
  cityData: SelectModel[];
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isEdit: boolean;
  filterData: any;
  userInfo: any;
}

class ContactData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      showEdit: false,
      isAddClient: false,
      recordMarked: {},
      searchText: "",
      clientInfo: {} as LeadModel,
      countryData: [],
      stateData: [],
      cityData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isEdit: false,
      filterData: [],
      userInfo: {},
    };
  }

  async componentDidMount() {
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      const response = await ClientService.getAllLeads(
        JSON.parse(userInfo).provider_id
      );
      const countryDataResponse = await ClientService.getCountry();
      this.setState({
        clientInfo: {
          ...this.state.clientInfo,
          modified_by: "test123",
        },
        countryData: countryDataResponse.data.map((el: any) => {
          return {
            text: el,
            value: el,
          };
        }),
      });
      const data = response.data.map((el: LeadModel) => {
        return {
          Id: el.lead_id,
          name: el.lead_name,
          status: el.lead_status,
          phone: el.lead_phone,
          email: el.lead_email,
          creation_date: new Date(el.created_on).toDateString(),
        };
      });
      //console.log(data);
      this.setState({
        tableData: data,
        totalRecordsCount: data.length,
        userInfo: JSON.parse(userInfo),
      });
    }
  }

  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        const response = await ClientService.getSelectedLead(
          record.Id,
          this.state.userInfo.provider_id
        );
        this.setState({
          clientInfo: response.data,
          isAddClient: true,
          isEdit: true,
        });
        break;
      case "Delete":
        const deleteResponse = await ClientService.deleteLead(
          record.Id,
          this.state.userInfo.provider_id
        );
        if (deleteResponse) {
          const updatedTableData = await ClientService.getAllLeads(
            this.state.userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: LeadModel) => {
            return {
              Id: el.lead_id,
              name: el.lead_name,
              status: el.lead_status,
              phone: el.lead_phone,
              email: el.lead_email,
              creation_date: new Date(el.created_on).toDateString(),
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Lead Deleted Successfully",
            alertType: "success",
          });
        }
        break;
      case "Clone":
        break;
    }
  };

  onClickName = (_fieldClicked: string, record: any) => {
    if (record.Id) {
      this.props.history.push(`/viewlead/${record.Id}`);
    }
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      clientInfo: {
        ...this.state.clientInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onSearchChange = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const filterData = this.state.tableData.filter((el: any) =>
      el.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({
      filterData,
    });
    this.setState({ searchText: event.target.value });
    // if (event.target.value === "") {
    //   const response = await ClientService.getAllClients();

    //   const data = response.data.map((el: ClientDetailModel) => {
    //     return {
    //       Id: el.Id,
    //       name: el.Name,
    //       status: el.Status,
    //       phone: el.Phone,
    //       email: el.Email,
    //       creation_date: new Date(el.created_date).toDateString(),
    //     };
    //   });
    //   //console.log(data);
    //   this.setState({
    //     tableData: data,
    //     totalRecordsCount: data.length,
    //   });
    // }
  };
  currencyValues: SelectModel[] = [
    { text: "INR", value: "INR" },
    { text: "USD", value: "USD" },
    { text: "AUD", value: "AUD" },
  ];
  statusValues: SelectModel[] = [
    { text: "New", value: "New" },
    { text: "Prospect", value: "Prospect" },
    { text: "Move to Client", value: "Move to Client" },
  ];
  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    console.log(targetId);
    if (targetId) {
      this.setState({
        clientInfo: {
          ...this.state.clientInfo,
          [targetId]: selected.value,
        },
      });
    }
    if (targetId === "lead_country") {
      this.getState(selected.value);
    }
    if (targetId === "lead_state") {
      this.getCity(selected.value, this.state.clientInfo.lead_country);
    }
  };

  getState = async (country: string) => {
    const response = await ClientService.getState(country);
    this.setState({
      stateData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };
  getCity = async (state: string, country: string) => {
    const response = await ClientService.getCity(state, country);
    this.setState({
      cityData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };
  onClientSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { clientInfo, isEdit, userInfo } = this.state;

      if (isEdit) {
        const updatedResponse = await ClientService.UpdateLead({
          ...clientInfo,
          provider_id: userInfo.provider_id,
        });
        if (updatedResponse) {
          const updatedTableData = await ClientService.getAllLeads(
            userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: LeadModel) => {
            return {
              Id: el.lead_id,
              name: el.lead_name,
              status: el.lead_status,
              phone: el.lead_phone,
              email: el.lead_email,
              creation_date: new Date(el.created_on).toDateString(),
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Lead Updated Successfully",
            alertType: "success",
            isAddClient: false,
          });
        }
      } else {
        const response = await ClientService.createLead({
          ...clientInfo,
          provider_id: userInfo.provider_id,
        });
        if (clientInfo.lead_status === "Move to Client") {
          let clientData = {} as ClientModel;
          for (let o in clientInfo) {
            if (o.includes("lead")) {
              let key = o.replace(/lead/i, "client");
              let value = clientInfo[o as keyof LeadModel];
              clientData[key as keyof ClientModel] = value;
            }
            clientData["modified_by"] = clientInfo["modified_by"];
            clientData["client_status"] = "Active";
          }
          await ClientService.createClient(clientData);
        }
        if (response) {
          const response = await ClientService.getAllLeads(
            userInfo.provider_id
          );
          const data = response.data.map((el: LeadModel) => {
            return {
              Id: el.lead_id,
              name: el.lead_name,
              status: el.lead_status,
              phone: el.lead_phone,
              email: el.lead_email,
              creation_date: new Date(el.created_on).toDateString(),
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Lead Created Successfully",
            alertType: "success",
            isAddClient: false,
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onCancelClick = () => {
    this.setState({
      isAddClient: false,
      clientInfo: {} as LeadModel,
      isEdit: false,
    });
  };
  onDateChange = (value: Date | null) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        clientInfo: {
          ...this.state.clientInfo,
          lead_dob: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };
  genderValues: SelectModel[] = [
    { text: "Male", value: "Male" },
    { text: "Female", value: "Female" },
    { text: "Intersex", value: "Intersex" },
    { text: "Non-binary", value: "Non-binary" },
    { text: "Unspecified", value: "Unspecified" },
    { text: "Prefer not to say", value: "Prefer not to say" },
  ];
  interpreterValues: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];
  atsiValues: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
    { text: "Not stated", value: "Not Stated" },
  ];
  render() {
    const {
      tableData,
      totalRecordsCount,
      rows,
      page,
      searchText,
      isAddClient,
      clientInfo,
      countryData,
      stateData,
      cityData,
      openAlert,
      alertMsg,
      isEdit,
      alertType,
      filterData,
    } = this.state;

    // Check if the status is "Move to Client"
    const isStatusMoveToClient = clientInfo.lead_status === "Move to Client";
    // const isUpdateButtonDisabled = isStatusMoveToClient && isEdit;
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <ModalDialog
          isOpen={isAddClient}
          title={isEdit ? "Edit Lead" : "Add Lead"}
          onClose={() => {
            this.setState({
              isAddClient: false,
              isEdit: false,
              clientInfo: {} as LeadModel,
            });
          }}
        >
          <form onSubmit={this.onClientSave}>
            <Grid container item flexDirection={"column"}>
              <Grid container item rowSpacing={3}>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Name</FormLabel>
                    <TextInputField
                      id="lead_name"
                      value={clientInfo.lead_name}
                      onChange={this.onInputChange}
                      placeholder="Name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Phone</FormLabel>
                    <TextInputField
                      id="lead_phone"
                      value={clientInfo.lead_phone}
                      onChange={this.onInputChange}
                      placeholder="Phone"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Email</FormLabel>
                    <TextInputField
                      id="lead_email"
                      value={clientInfo.lead_email}
                      onChange={this.onInputChange}
                      placeholder="Email"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Gender</FormLabel>
                    <SingleSelect
                      value={clientInfo.lead_gender}
                      id="lead_gender"
                      size="small"
                      values={this.genderValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Status</FormLabel>
                    <SingleSelect
                      value={clientInfo.lead_status}
                      id="lead_status"
                      size="small"
                      values={this.statusValues}
                      onChange={this.onDropDownChange}
                      isDisabled={isStatusMoveToClient} // Set disabled property based on status
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Address</FormLabel>
                    <TextInputField
                      id="lead_address"
                      value={clientInfo.lead_address}
                      onChange={this.onInputChange}
                      placeholder="Address"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Country</FormLabel>
                    <SingleSelect
                      value={clientInfo.lead_country}
                      id="lead_country"
                      size="small"
                      values={countryData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>State</FormLabel>
                    <SingleSelect
                      customOptions={
                        clientInfo.lead_country ? "" : "Select Country First"
                      }
                      value={clientInfo.lead_state}
                      id="lead_state"
                      size="small"
                      values={stateData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>City</FormLabel>
                    <SingleSelect
                      customOptions={
                        clientInfo.lead_state ? "" : "Select State First"
                      }
                      value={clientInfo.lead_city}
                      id="lead_city"
                      size="small"
                      values={cityData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Lead Source
                    </FormLabel>
                    <TextInputField
                      id="lead_source"
                      value={clientInfo.lead_source}
                      onChange={this.onInputChange}
                      placeholder="Lead Source"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Choose Industry
                    </FormLabel>
                    <TextInputField
                      id="lead_industry"
                      value={clientInfo.lead_industry}
                      onChange={this.onInputChange}
                      placeholder="Choose Industry"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Enquiry Type
                    </FormLabel>
                    <TextInputField
                      id="lead_enquirytype"
                      value={clientInfo.lead_enquirytype}
                      onChange={this.onInputChange}
                      placeholder="Enquiry Type"
                    />
                  </Grid>
                </Grid>

                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Currency</FormLabel>
                    <SingleSelect
                      value={clientInfo.lead_curency}
                      id="lead_curency"
                      size="small"
                      values={this.currencyValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Annual Revenue
                    </FormLabel>
                    <TextInputField
                      id="lead_annualrevenue"
                      value={clientInfo.lead_annualrevenue}
                      onChange={this.onInputChange}
                      placeholder="Revenue in Numbers"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Company</FormLabel>
                    <TextInputField
                      id="lead_company"
                      value={clientInfo.lead_company}
                      onChange={this.onInputChange}
                      placeholder="Company"
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end">
                  {isEdit ? (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    onClick={this.onCancelClick}
                    style={{ textTransform: "capitalize", marginLeft: 7 }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>

        <Box style={{ display: "flex" }}>
          <Typography
            marginBottom={5}
            style={{ fontSize: "1.7rem", fontWeight: 550 }}
          >
            Leads
          </Typography>
        </Box>
        <Grid container marginBottom={4}>
          <Grid item xs={6}>
            <SearchFilter
              id="searchText"
              placeholder="Search"
              value={searchText}
              onChange={this.onSearchChange}
            />
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              onClick={() => {
                this.setState({ isAddClient: true });
              }}
            >
              + Add Lead
            </Button>
          </Grid>
        </Grid>
        <Box>
          <DataLoader
            totalRecordsCount={
              searchText ? filterData.length : totalRecordsCount
            }
            onChangePage={this.onChangePage}
            onChangeRow={this.onChangeRow}
            onActionClick={this.onActionClick}
            tableData={
              searchText
                ? rows > 0
                  ? filterData.slice(page * rows, page * rows + rows)
                  : filterData
                : rows > 0
                ? tableData.slice(page * rows, page * rows + rows)
                : tableData
            }
            onClickLink={this.onClickName}
            actionArray={["Edit", "Delete"]}
          />
        </Box>
      </Box>
    );
  }
}

export default withRouter(ContactData);
