export const HEADER_MAPPER = {};
export const FIELDS_TO_LINK = {
  name: "",
};

export const STATUS_END_POINTS = {};

export const DATA_TYPES = [
  {
    text: "varchar(255)",
    value: "varchar(255)",
  },
  {
    text: "int",
    value: "int",
  },
];

export const URIs = {
  CREATE_CLIENT: "/client_create",
  GETALL_CLIENT: "/getallclients",
  GET_COUNTRY: "/country_select",
  GET_STATE: "/state_select",
  GET_CITY: "/city_select",
  GET_CLIENT: "/client_select",
  UPDATE_CLIENT: "/client_update",
  DELETE_CLIENT: "/client_delete",
  GET_REGISTRATION_GROUP: "/registrationgroup",
  GET_SUPPORT_ITEM: "/supportitem",
  GET_PRICE_DETAILS: "/pricedetails",
  CREATE_SERVICEAGREEMENT: "/serviceagreement_create",
  GET_SERVICEAGREEMENT: "/servicelines_select",
  GETALL_STAFF: "/getallstaffs",
  CREATE_STAFF: "/staff_create",
  UPDATE_STAFF: "/staff_update",
  GET_STAFF: "/staff_select",
  DELETE_STAFF: "/staff_delete",
  GET_STAFFROLE: "/getstaffroles",
  GETALL_ROSTER: "/getall_roster",
  GET_ROSTER_BY_CLIENT: "/roster_byclient",
  CREATE_ROSTER: "/roster_create",
  UPDATE_ROSTER: "/roster_update",
  DELETE_ROSTER: "/roster_delete",
  GET_ROSTER_BY_ID: "/roster_byrosterid",
  CREATE_NDIS: "/clientndisplan_create",
  UPDATE_NDIS: "/clientndisplan_update",
  DELETE_NDIS: "/clientndisplan_delete",
  GET_NDIS: "/clientndisplan_select",
  CREATE_ASSOCIATE: "/clientassodetails_create",
  UPDATE_ASSOCIATE: "/clientassodetails_update",
  GET_ASSOCIATE_BY_ID: "/clientassodetails_selectbyid",
  GET_ALL_ASSOCIATE: "/clientassodetails_select",
  DELETE_SELECTED_ASSOCIATE: "/clientassodetails_singledelete",
  CREATE_CLIENT_REF: "/clientref_create",
  UPDATE_CLIENT_REF: "/clientref_update",
  GET_ALL_REF: "/clientref_select",
  GET_CLIENT_REF_BY_ID: "/clientref_selectbyid",
  DELETE_CLIENT_REF: "/clientref_singledelete",
  CREATE_ASSESSMENT: "/client_createassessment",
  UPDATE_ASSESSMENT: "/client_updateassessment",
  GET_ASSESSMENT: "/client_selectassessment",
  DELETE_ASSESSMENT: "/client_deleteassessment",
  CREATE_CASENOTES: "/clientnotes_create",
  GET_CASENOTES: "/clientnotes_select",
  SEND_EMAIL: "/sendemail",
  GET_FUND_BY_CLIENT: "/fund_details",
  GETALL_LEADS: "/getallleads",
  UPDATE_LEAD: "/lead_update",
  DELETE_LEAD: "/lead_delete",
  SELECT_LEAD: "/lead_select",
  CREATE_LEAD: "/lead_create",
  GET_ALL_EMAIL: "/getemail_details",
  UPLOAD_DOCUMENT: "/uploader",
  GET_SERVICE_LINE: "/servicelines_select",
  GET_SERVICE_DROPDOWN: "/getallclientid",
  GET_STAFF_ROSTER: "/getallstaffid",
  ROSTER_CLIENT: "/roster_byclient",
  ROSTER_STAFF: "/roster_forstaff",
  CALENDAR_CLIENT: "/calender_forclient",
  CALENDAR_STAFF: "/calender_forstaff",
  GET_DOCUMENTS_NAME: "/getdocumentnames",
  GETALL_DOCUMENTS: "/getclientdocs",
  DELETE_DOCUMENTS: "/clientdocs_delete",
  DOWNLOAD_DOCUMENTS: "/download_document",
  GETALL_STAFF_ROSTER: "/roster_forstaff",
  STAFF_ROSTER_STATUS: "/roster_status",
  GETALL_CLIENT_ROSTER: "/rosterlines_byclient",
  GET_STAFF_DOCUMENTS_NAME: "/getstaffdocumentnames",
  GET_STAFF_DOCUMENTS: "/getstaffdocs",
  DELETE_STAFF_DOCUMENTS: "/staffdocs_delete",
  GET_TRAINING_DOCUMENTS: "/getstafftraininglist",
  CREATE_TRAINING: "/stafftraining_create",
  GET_TRAINING: "/stafftraining_select",
  GET_CLIENTS_FOR_STAFF: "/getclientforstaff",
  GET_WEEK: "/getweekdates",
  GETALL_TIMESHEET: "/timesheet_allselect",
  CREATE_TIMESHEET: "/timesheet_create",
  UPDATE_TIMESHEET: "/timesheet_update",
  ADD_CLIENT_DOCUMENT_NAME: "/addnewclientdocument",
  ADD_STAFF_DOCUMENT_NAME: "/addnewstaffdocument",
  ADD_STAFF_TRAINING_NAME: "/addnewstafftraining",
  CREATE_FEEDBACK: "/feedback_create",
};

export const KEY = `g]kaaGVPLg>f/Q:Cvjs_Q>9BR7Q6/t`;
