import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import Shift from "./shift";
import StaffDocuments from "./StaffDocuments";
import StaffTraining from "./StaffTraining";
import CaseNotes from "./CaseNotes";
import TimeSheet from "./TimeSheet";
// import ServiceAgreement from "./ServiceAgreement";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ marginTop: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
}
const tabStyle: React.CSSProperties = {
  textTransform: "none",
  fontSize: "1rem",
};
export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", height: 24 }}>
      <Tabs
        scrollButtons={true}
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="horizontal tabs example"
        sx={{ borderRight: 1, borderColor: "divider", overflow: "auto" }}
      >
        <Tab label="Profile" style={tabStyle} {...a11yProps(0)} />
        <Tab label="Shift" style={tabStyle} {...a11yProps(1)} />
        <Tab label="Time Sheet" style={tabStyle} {...a11yProps(2)} />
        <Tab label="Documents" style={tabStyle} {...a11yProps(3)} />
        <Tab label="Training" style={tabStyle} {...a11yProps(4)} />
        <Tab label="Case Notes" style={tabStyle} {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        Profile
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Shift />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TimeSheet />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StaffDocuments />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StaffTraining />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <CaseNotes />
      </TabPanel>
    </Box>
  );
}
