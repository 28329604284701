import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { FC } from "react";

// import GenericAPIService from "./GenericAPIService";
// import GenericAPIService from "./../../Services/GenericAPIService";
// import { KEY } from './../../Common/Constants';
import axios from "axios";
import { useHistory } from "react-router-dom";
import Enviroment from "./../../config.json";
const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";
const key: any = process.env;
const { REACT_APP_KEY } = process.env;
// console.log(key1);
console.log(REACT_APP_KEY);

type T = {
  status: boolean;
  user_name: string;
  session_token: string;
  provider_email: string;
  provider_name: string;
  staff_email: string;
  staff_name: string;
  provider_id: number;
  provider_phone: number;
  staff_phone: number;
};
// const InValidPassCSS = {
//     textAlign:'center',
//     color:'red'
// };
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        target="_blank"
        href="https://www.connektivity.com.au/"
      >
        conneckivity
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const Login: FC = () => {
  // const [inValidPass,setInValidPass] = useState("")
  let history = useHistory();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const url: string = "/user_validate";
    const password: any = data.get("password");
    const response = await axios.post<T>(baseURL + url, null, {
      params: {
        username: data.get("email"),
        userpassword: data.get("password"),
        // userpassword:Utils.encrypt(REACT_APP_KEY,password)
      },
    });
    if (response.data.status == true) {
      let userInfo: {
        email: string;
        provider_email: string;
        provider_name: string;
        staff_email: string;
        staff_name: string;
        provider_id: number;
        provider_phone: number;
        staff_phone: number;
      } = {
        email: response.data.user_name,
        provider_email: response.data.provider_email,
        provider_name: response.data.provider_name,
        staff_email: response.data.staff_email,
        staff_name: response.data.staff_name,
        provider_id: response.data.provider_id,
        provider_phone: response.data.provider_phone,
        staff_phone: response.data.staff_phone,
      };
      window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      window.sessionStorage.setItem("token", response.data.session_token);
      history.push("/contacts");
    } else {
      window.alert("Please re-enter the password..!!!");
      // setInValidPass("Please re-enter the password..!!!")
      // console.log("unauthorized user message..!!!!!");
      // setTimeout(()=>{
      //     setInValidPass("");
      // },1500)
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t: any) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              {/* <Typography sx = {InValidPassCSS}>{inValidPass}</Typography> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgetpassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/resetpassword" variant="body2">
                    Reset Password
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
