import React from "react";
import { Typography } from "@mui/material";

interface Props {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
}
interface State {}

class LinkButton extends React.Component<Props, State> {
  render() {
    const { label, onClick, style } = this.props;

    const defaultStyle: any = {
      color: "#551A8B",
      textTransform: "none",
      textDecoration: "underline",
      cursor: "pointer",
    };

    let mergeStyle = { ...defaultStyle, ...style };

    return (
      <Typography style={mergeStyle} onClick={onClick}>
        {label}
      </Typography>
    );
  }
}

export default LinkButton;
