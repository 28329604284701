import axios from "axios";
import { URIs } from "../Common/Constants";

import { StaffModel } from "../Models/StaffModel";
import GenericAPIService from "./GenericAPIService";
import { AddStaffTrainingNameModel, StaffTrainingModel } from "../Models/StaffTrainingModel";
import { TimeSheetModel } from "../Models/StaffTimesheetModel";

const {
  CREATE_STAFF,
  GETALL_STAFF,

  GET_STAFF,
  UPDATE_STAFF,
  DELETE_STAFF,
  GET_STAFFROLE,
  GETALL_STAFF_ROSTER,
  STAFF_ROSTER_STATUS,
  GET_STAFF_DOCUMENTS_NAME,
  GET_STAFF_DOCUMENTS,
  DELETE_STAFF_DOCUMENTS,
  DOWNLOAD_DOCUMENTS,
  GET_TRAINING,
  GET_TRAINING_DOCUMENTS,
  CREATE_TRAINING,
  GET_WEEK,
  GET_CLIENTS_FOR_STAFF,
  GETALL_TIMESHEET,
  CREATE_TIMESHEET,
  UPDATE_TIMESHEET,
  ADD_STAFF_DOCUMENT_NAME,
  ADD_STAFF_TRAINING_NAME
} = URIs;
class StaffService extends GenericAPIService {
  async addDocumentsName(docName: string, providerId: number) {
    const data: any = await this.getAsync(
      `${ADD_STAFF_DOCUMENT_NAME}?document_name=${docName}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getAllStaffs(id: number) {
    const data: any = await this.getAsync(`${GETALL_STAFF}?provider_id=${id}`);
    //console.log("data",data)
    return data;
  }
  async getAllStaffsRole() {
    const data: any = await this.getAsync(GET_STAFFROLE);
    //console.log("data",data)
    return data;
  }

  async getSelectedStaff(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_STAFF}?staff_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async deleteStaff(id: number, providerId: number) {
    const data: any = await this.getAsync(
      `${DELETE_STAFF}?staff_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  async createStaff(body: StaffModel) {
    const { data } = await this.postAsync<{ data: any }>(CREATE_STAFF, body);
    //console.log("data", data);
    return data;
  }
  async UpdateStaff(body: StaffModel) {
    const { data } = await this.postAsync<{ data: any }>(UPDATE_STAFF, body);
    //console.log("data", data);
    return data;
  }

  async getAllStaffRoster(id: number | null, provider_id: number) {
    const data: any = await this.getAsync(
      `${GETALL_STAFF_ROSTER}?staff_id=${id}&provider_id=${provider_id}`
    );
    //console.log("data",data)
    return data;
  }
  async updateStaffRosterStatus(
    rosterId: number | null,
    rosterLineId: number | null,
    status: string,
    lastDay: number | null
  ) {
    const data: any = await this.getAsync(
      `${STAFF_ROSTER_STATUS}?roster_id=${rosterId}&roster_line_id=${rosterLineId}&status=${status}&last_day=${lastDay}`
    );
    //console.log("data",data)
    return data;
  }
  async getStaffDocumentsName(provider_id: number) {
    const data: any = await this.getAsync(
      `${GET_STAFF_DOCUMENTS_NAME}?provider_id=${provider_id}`
    );
    //console.log("data",data)
    return data;
  }
  async getAllStaffDocuments(staffId: number,provider_id: number) {
    const data: any = await this.getAsync(
      `${GET_STAFF_DOCUMENTS}?staff_id=${staffId}&provider_id=${provider_id}`
    );
    //console.log("data",data)
    return data;
  }
  async uploadDocuments(
    staffId: number,
    providerId: number,
    documentId: number,
    documentType: string,
    issueDate: string,
    expiryDate: string,
    formData: any
  ) {
    const data = await axios.post(
      `https://connektivitycrm.genxalign.com/api/uploader_staffdocs?staff_id=${staffId}&provider_id=${providerId}&document_id=${documentId}&document_type=${documentType}&document_issuedate=${issueDate}&document_expiredate=${expiryDate}`,
      formData
    );
    return data;
  }

  async deleteDocuments(documentId: number, staffId: any,provider_id: number) {
    const data: any = await this.getAsync(
      `${DELETE_STAFF_DOCUMENTS}?staff_document_id=${documentId}&staff_id=${staffId}&provider_id=${provider_id}`
    );
    //console.log("data",data)
    return data;
  }
  async downloadDocuments(filePath: string, fileName: string) {
    const data: any = await this.getAsync(
      `${DOWNLOAD_DOCUMENTS}?file_path=${filePath}&file_name=${fileName}`
    );
    //console.log("data",data)
    return data;
  }
  async addTrainingName(body:AddStaffTrainingNameModel) {
    const { data } = await this.postAsync<{ data: any }>(ADD_STAFF_TRAINING_NAME, body);
    //console.log("data", data);
    return data;
  }
  async getTrainingDocumentsName(staffId: number,provider_id: number) {
    const data: any = await this.getAsync(`${GET_TRAINING_DOCUMENTS}?staff_id=${staffId}&provider_id=${provider_id}`);
    //console.log("data",data)
    return data;
  }
  async createTraining(body: StaffTrainingModel[]) {
    const { data } = await this.postAsync<{ data: any }>(CREATE_TRAINING, body);
    //console.log("data", data);
    return data;
  }
  async getTraining(staffId: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_TRAINING}?staff_id=${staffId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getWeek(week: string) {
    const data: any = await this.getAsync(`${GET_WEEK}?option=${week}`);
    //console.log("data",data)
    return data;
  }
  async getClientsForStaff(staffId: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_CLIENTS_FOR_STAFF}?staff_id=${staffId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getAllTimesheet(staffId: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GETALL_TIMESHEET}?staff_id=${staffId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async createTimesheet(body: TimeSheetModel[]) {
    const { data } = await this.postAsync<{ data: any }>(
      CREATE_TIMESHEET,
      body
    );
    //console.log("data", data);
    return data;
  }
  async updateTimesheet(body: TimeSheetModel[]) {
    const { data } = await this.postAsync<{ data: any }>(
      UPDATE_TIMESHEET,
      body
    );
    //console.log("data", data);
    return data;
  }
}

export default StaffService;
