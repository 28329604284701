import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { IconType } from "../../Components/Button/IconButton";
import DatePicker from "../../Components/DatePicker/DatePicker";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import DataLoader from "../../Components/Table/DataLoader";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { ReferralModel } from "../../Models/ReferralModel";
import ClientService from "../../Services/ClientServices";
import ReferralService from "../../Services/ReferralService";

const ReferralServices = new ReferralService();
const ClientServices = new ClientService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  clientId: number;
  referralInfo: ReferralModel;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isAdd: boolean;
  userInfo: any;
}

class Referrals extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      clientId: 0,
      referralInfo: {} as ReferralModel,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isAdd: false,
      userInfo: {},
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");
      // console.log(match);
      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        const clientResponse = await ClientServices.getSelectedClient(
          clientId,
          JSON.parse(userInfo).provider_id
        );

        this.setState({
          referralInfo: {
            ...this.state.referralInfo,
            client_id: clientId,
            client_name: clientResponse.data.client_name,
            client_email: clientResponse.data.client_email,
            client_dob: clientResponse.data.client_dob,
            client_Phone: clientResponse.data.client_phone,
            provider_id: JSON.parse(userInfo).provider_id,
          },
          clientId: clientId,
          userInfo: JSON.parse(userInfo),
        });

        const response = await ReferralServices.getReferralByClientId(
          clientId,
          JSON.parse(userInfo).provider_id
        );

        const data = response.data.map((el: ReferralModel) => {
          return {
            Id: el.client_ref_id,
            referralName: el.ref_name,
            date: new Date(el.ref_date).toDateString(),
            phone: el.ref_phone,
            email: el.ref_email,
            referredby: el.client_referred_by,
            expectedStartDate: new Date(el.expected_start_date).toDateString(),
          };
        });
        this.setState({
          tableData: data,
          totalRecordsCount: data.length,
        });
      }
    }
  }
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      referralInfo: {
        ...this.state.referralInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        referralInfo: {
          ...this.state.referralInfo,
          [targetId]: selected.value,
          client_id: this.state.clientId,
        },
      });
    }
  };
  onClientDateChange = (value: Date | null) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        referralInfo: {
          ...this.state.referralInfo,
          client_dob: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };
  onRefDateChange = (value: Date | null) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        referralInfo: {
          ...this.state.referralInfo,
          ref_date: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };
  onstartDateChange = (value: Date | null) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        referralInfo: {
          ...this.state.referralInfo,
          expected_start_date: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };
  onReferralSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { referralInfo, isEdit, userInfo } = this.state;

      if (isEdit) {
        const updatedResponse = await ReferralServices.UpdateReferral({
          ...referralInfo,
          provider_id: userInfo.provider_id,
        });
        if (updatedResponse) {
          const response = await ReferralServices.getReferralByClientId(
            this.state.clientId,
            userInfo.provider_id
          );

          const data = response.data.map((el: ReferralModel) => {
            return {
              Id: el.client_ref_id,
              referralName: el.ref_name,
              date: new Date(el.ref_date).toDateString(),
              phone: el.ref_phone,
              email: el.ref_email,
              referredby: el.client_referred_by,
              expectedStartDate: new Date(
                el.expected_start_date
              ).toDateString(),
            };
          });

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Referral Updated Successfully",
            alertType: "success",
            isAdd: false,
            referralInfo: {
              ...this.state.referralInfo,

              client_referred_by: "",
              ref_date: "",
              ref_name: "",
              ref_phone: 0,
              ref_email: "",
              client: "",
              crisis: "",
              crisis_details: "",
              servic_requested: "",
              expected_start_date: "",
              ndis_plan: "",
              funding_type: "",
              behaviour_support_practitioner: "",
              behaviour_support_practitioner_details: "",
              plan_manager: "",
              plan_manager_details: "",
              lac: "",
              lac_details: "",
              support_coordinator: "",
              support_coordinator_details: "",
              rp: "",
              clinical_support_type: "",
              clinical_support_requested: "",
              medication_support_requested: "",
              ndis_planner: "",
              ndis_planner_details: "",
              is_ndis_funding_available: "",
              ndis_funding_details: "",
            },
          });
        }
      } else {
        const response = await ReferralServices.createReferral({
          ...referralInfo,
          provider_id: userInfo.provider_id,
        });
        if (response) {
          const response = await ReferralServices.getReferralByClientId(
            this.state.clientId,
            userInfo.provider_id
          );

          const data = response.data.map((el: ReferralModel) => {
            return {
              Id: el.client_ref_id,
              referralName: el.ref_name,
              date: new Date(el.ref_date).toDateString(),
              phone: el.ref_phone,
              email: el.ref_email,
              referredby: el.client_referred_by,
              expectedStartDate: new Date(
                el.expected_start_date
              ).toDateString(),
            };
          });

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Referral Created Successfully",
            alertType: "success",
            isAdd: false,
            referralInfo: {
              ...this.state.referralInfo,

              client_referred_by: "",
              ref_date: "",
              ref_name: "",
              ref_phone: 0,
              ref_email: "",
              client: "",
              crisis: "",
              crisis_details: "",
              servic_requested: "",
              expected_start_date: "",
              ndis_plan: "",
              funding_type: "",
              behaviour_support_practitioner: "",
              behaviour_support_practitioner_details: "",
              plan_manager: "",
              plan_manager_details: "",
              lac: "",
              lac_details: "",
              support_coordinator: "",
              support_coordinator_details: "",
              rp: "",
              clinical_support_type: "",
              clinical_support_requested: "",
              medication_support_requested: "",
              ndis_planner: "",
              ndis_planner_details: "",
              is_ndis_funding_available: "",
              ndis_funding_details: "",
            },
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onCancelClick = () => {
    this.setState({
      isAdd: false,
      referralInfo: {
        ...this.state.referralInfo,

        client_referred_by: "",
        ref_date: "",
        ref_name: "",
        ref_phone: 0,
        ref_email: "",
        client: "",
        crisis: "",
        crisis_details: "",
        servic_requested: "",
        expected_start_date: "",
        ndis_plan: "",
        funding_type: "",
        behaviour_support_practitioner: "",
        behaviour_support_practitioner_details: "",
        plan_manager: "",
        plan_manager_details: "",
        lac: "",
        lac_details: "",
        support_coordinator: "",
        support_coordinator_details: "",
        rp: "",
        clinical_support_type: "",
        clinical_support_requested: "",
        medication_support_requested: "",
        ndis_planner: "",
        ndis_planner_details: "",
        is_ndis_funding_available: "",
        ndis_funding_details: "",
      },
    });
  };

  onTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      referralInfo: {
        ...this.state.referralInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        const response = await ReferralServices.getReferralById(
          record.Id,
          this.state.userInfo.provider_id
        );
        this.setState({
          referralInfo: response.data,
          isAdd: true,
          isEdit: true,
        });
        break;
      case "Delete":
        const deleteResponse = await ReferralServices.deleteReferralById(
          this.state.clientId,
          record.Id,
          this.state.userInfo.provider_id
        );
        if (deleteResponse) {
          const response = await ReferralServices.getReferralByClientId(
            this.state.clientId,
            this.state.userInfo.provider_id
          );

          const data = response.data.map((el: ReferralModel) => {
            return {
              Id: el.client_ref_id,
              referralName: el.ref_name,
              date: new Date(el.ref_date).toDateString(),
              phone: el.ref_phone,
              email: el.ref_email,
              referredby: el.client_referred_by,
              expectedStartDate: new Date(
                el.expected_start_date
              ).toDateString(),
            };
          });
          //console.log(data);
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Referral Deleted Successfully",
            alertType: "success",
          });
        }
        break;
      case "Clone":
        break;
    }
  };

  referredValue: SelectModel[] = [
    { text: "Support Coordinator", value: "Support Coordinator" },
    { text: "Plan Manager", value: "Plan Manager" },
    { text: "Family Member", value: "Family Member" },
    { text: "Organisation", value: "Organisation" },
    { text: "Self Refferal", value: "Self Refferal" },
    { text: "LAC", value: "LAC" },
    { text: "NDIA Planner", value: "NDIA Planner" },
    { text: "Other (Specify)", value: "Other (Specify)" },
  ];
  crisisValue: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];
  clientTypeValue: SelectModel[] = [
    { text: "New", value: "New" },
    { text: "Previous Referral", value: "Previous Referral" },
    { text: "Exited Client", value: "Exited Client" },
  ];
  referredByValue: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
    { text: "Unsure", value: "Unsure" },
  ];
  ndisPlanValue: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
    { text: "Unsure", value: "Unsure" },
  ];
  fundingTypeValue: SelectModel[] = [
    { text: "NDIA", value: "NDIA" },
    { text: "Plan", value: "No" },
    { text: "Self", value: "Self" },
    { text: "Other", value: "Other" },
  ];
  clinicalSupportValue = [
    { text: "Bowel Care", value: "Bowel Care" },
    { text: "Wound", value: "Wound" },
    { text: "Enteral Peg Feed", value: "Enteral Peg Feed" },
    { text: "Subcutaneous Injection", value: "Subcutaneous Injection" },
    { text: "Urinary catheter", value: "Urinary catheter" },
    { text: "Tracheostomy", value: "Tracheostomy" },
    { text: "Ventilation", value: "Ventilation" },
    { text: "Severe Dysphagia", value: "Severe Dysphagia" },
    { text: "Stoma Care", value: "Stoma Care" },
    { text: "Diabetes", value: "Diabetes" },
    { text: "Epilepsy Stoma care", value: "Epilepsy Stoma care" },
  ];

  render() {
    const {
      referralInfo,
      isEdit,
      openAlert,
      alertMsg,
      alertType,
      isAdd,
      totalRecordsCount,
      page,
      rows,
      tableData,
    } = this.state;
    // console.log(this.state.referralInfo);
    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <ModalDialog
          isOpen={isAdd}
          dialogWidth="lg"
          title={isEdit ? "Edit Referral" : "Add Referral"}
          onClose={() => {
            this.setState({
              isAdd: false,
              isEdit: false,
              referralInfo: {} as ReferralModel,
            });
          }}
        >
          <form onSubmit={this.onReferralSave}>
            <Grid container item flexDirection={"column"}>
              <Grid container item rowSpacing={3}>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Referred By
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.client_referred_by}
                      id="client_referred_by"
                      size="small"
                      values={this.referredValue.sort((a, b) =>
                        a.text > b.text ? 1 : b.text > a.text ? -1 : 0
                      )}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Grid>
                      <FormLabel sx={{ fontSize: "0.9em" }}>Date</FormLabel>
                    </Grid>
                    <Grid>
                      <DatePicker
                        width="22rem"
                        value={new Date(referralInfo.ref_date)}
                        onChange={(value) => this.onRefDateChange(value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Referral Name
                    </FormLabel>
                    <TextInputField
                      id="ref_name"
                      value={referralInfo.ref_name}
                      onChange={this.onInputChange}
                      placeholder="Enter Name"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Phone</FormLabel>
                    <TextInputField
                      id="ref_phone"
                      value={referralInfo.ref_phone}
                      onChange={this.onInputChange}
                      placeholder="Enter Phone"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Email</FormLabel>
                    <TextInputField
                      id="ref_email"
                      value={referralInfo.ref_email}
                      onChange={this.onInputChange}
                      placeholder="Enter Email"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Client Type
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.client}
                      id="client"
                      size="small"
                      values={this.clientTypeValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Crisis</FormLabel>
                    <SingleSelect
                      value={referralInfo.crisis}
                      id="crisis"
                      size="small"
                      values={this.crisisValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Details</FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="crisis_details"
                      value={referralInfo.crisis_details}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter details"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Client Name
                    </FormLabel>
                    <TextInputField
                      id="client_name"
                      value={referralInfo.client_name}
                      onChange={this.onInputChange}
                      placeholder="Enter Client Name"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Date Of Birth
                    </FormLabel>
                    <DatePicker
                      width="22rem"
                      value={new Date(referralInfo.client_dob)}
                      onChange={(value) => this.onClientDateChange(value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Services Requested
                    </FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="servic_requested"
                      value={referralInfo.servic_requested}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Services Requested"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Client Email
                    </FormLabel>
                    <TextInputField
                      id="client_email"
                      value={referralInfo.client_email}
                      onChange={this.onInputChange}
                      placeholder="Enter Client Email"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Client Phone
                    </FormLabel>
                    <TextInputField
                      id="client_Phone"
                      value={referralInfo.client_Phone}
                      onChange={this.onInputChange}
                      placeholder="Enter Client Phone"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Expected Start Date
                    </FormLabel>
                    <DatePicker
                      width="22rem"
                      value={new Date(referralInfo.expected_start_date)}
                      onChange={(value) => this.onstartDateChange(value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>NDIS Plan</FormLabel>
                    <SingleSelect
                      value={referralInfo.ndis_plan}
                      id="ndis_plan"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Funding Type
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.funding_type}
                      id="funding_type"
                      size="small"
                      values={this.fundingTypeValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Is NDIS Funding Available ?
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.is_ndis_funding_available}
                      id="is_ndis_funding_available"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      NDIS Funding Details
                    </FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="ndis_funding_details"
                      value={referralInfo.ndis_funding_details}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter NDIS Funding Details"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Support Coordinator
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.support_coordinator}
                      id="support_coordinator"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Support Coordinator Details
                    </FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="support_coordinator_details"
                      value={referralInfo.support_coordinator_details}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter Support Coordinator Details"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Plan Manager
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.plan_manager}
                      id="plan_manager"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Plan Manager Details
                    </FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="plan_manager_details"
                      value={referralInfo.plan_manager_details}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter Details"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>LAC</FormLabel>
                    <SingleSelect
                      value={referralInfo.lac}
                      id="lac"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      LAC Details
                    </FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="lac_details"
                      value={referralInfo.lac_details}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter LAC Details"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Behaviour Support Practitioner
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.behaviour_support_practitioner}
                      id="behaviour_support_practitioner"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Behaviour Support Practitioner Details
                    </FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="behaviour_support_practitioner_details"
                      value={
                        referralInfo.behaviour_support_practitioner_details
                      }
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter Details"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      NDIS Planner
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.ndis_planner}
                      id="ndis_planner"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      NDIS Planner Details
                    </FormLabel>
                    <TextAreaInputField
                      minRows={1}
                      isRequired={false}
                      id="ndis_planner_details"
                      value={referralInfo.ndis_planner_details}
                      onChange={this.onTextAreaInputChange}
                      placeholder="Enter Details"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>RP</FormLabel>
                    <SingleSelect
                      value={referralInfo.rp}
                      id="rp"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Clinical Support Type
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.clinical_support_type}
                      id="clinical_support_type"
                      size="small"
                      values={this.clinicalSupportValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Clinical Support Requested
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.clinical_support_requested}
                      id="clinical_support_requested"
                      size="small"
                      values={this.crisisValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Medication Support Requested
                    </FormLabel>
                    <SingleSelect
                      value={referralInfo.medication_support_requested}
                      id="medication_support_requested"
                      size="small"
                      values={this.crisisValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item marginTop={5} justifyContent="flex-start">
                {isEdit ? (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                )}
                <Button
                  onClick={this.onCancelClick}
                  style={{ textTransform: "capitalize", marginLeft: 7 }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container>
              <Grid item xs={9}>
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  Referrals
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                {isAdd ? (
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={() => {
                      this.setState({ isAdd: true });
                    }}
                  >
                    - Add Referrals
                  </Button>
                ) : (
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={() => {
                      this.setState({ isAdd: true });
                    }}
                  >
                    + Add Referrals
                  </Button>
                )}
              </Grid>
            </Grid>
            <hr />

            <Box>
              <DataLoader
                totalRecordsCount={totalRecordsCount}
                onChangePage={this.onChangePage}
                onChangeRow={this.onChangeRow}
                onActionClick={this.onActionClick}
                tableData={
                  rows > 0
                    ? tableData.slice(page * rows, page * rows + rows)
                    : tableData
                }
                actionArray={["Edit", "Delete"]}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(Referrals);
