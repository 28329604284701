import React, { useState, useEffect } from 'react';
import './inbox.css';
import { useParams } from 'react-router-dom';
import DangerousHTML from 'dangerously-set-html-content';
import { Letter } from 'react-letter';
import DOMPurify from 'dompurify';
import Enviroment from "./../../config.json";
const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const InboxAzure = ({props}) => {
  const [code, setCode] = useState(sessionStorage.getItem('code'));
 // const emailsWithIds = props.map((email, index) => ({ ...email, indexId: (index + 1).toString() }));
  const [selectedEmailId, setSelectedEmailId] = useState("");
  const [currentSection, setCurrentSection] = useState('inbox');
  const [emails, setEmails] = useState([]);
 
  useEffect(() => {
    if (code) {
      fetch(baseURL + `/fetch-emails?code=${code}`)
        .then(response => response.json())
        .then(emailData => {
          console.log("Fetched emails:", emailData);
          const emailsWithIds = emailData.value.map((email, index) => ({ ...email, indexId: (index + 1).toString() }));
          setEmails(emailsWithIds)
          setSelectedEmailId(emailData.id);
          //setLoading(false);

        })
        .catch(error => {
          console.error("Error fetching emails:", error);
          // Handle the error
          //setLoading(false); 
        });
    }
  }, [code]);
   /*
  const handleDelete = (emailId) => {
    // Send a DELETE request to the backend to delete the email
    fetch(`https://localhost:5000/delete_email/${emailId}?access_code=${code}`, {
      method: 'DELETE'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.message);
        // Refresh the email list after successful deletion
        // You can implement this part based on your app's structure
      })
      .catch(error => {
        console.error('Error deleting email:', error);
      });
  };
 
  const handleMoveToTrash = (emailId) => {
    // Send a DELETE request to the backend to delete the email
    fetch(`https://localhost:5000/move_to_trash/${emailId}?access_code=${code}`, {
      method: 'PUT'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.message);
        // Refresh the email list after successful deletion
        // You can implement this part based on your app's structure
      })
      .catch(error => {
        console.error('Error moving email to trash:', error);
      });
  };
  */
  const handleMarkAsRead = (emailId) => {
    fetch(baseURL + `/mark_as_read/${emailId}?code=${code}`, {
      method: 'PUT'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.message);
      })
      .catch(error => {
        console.error('Error marking email as read:', error);
      });
  };
 

  const onBack = () => {
    setSelectedEmailId("");
  };
  const openEmail = (id) => {
  const updatedEmails = [...emails];
  const index = updatedEmails.findIndex((x) => x.id === id);

  if (index !== -1) {
    const emailToUpdate = updatedEmails[index];

    // Check if the email is marked as unread (based on the isRead property)
    if (!emailToUpdate.isRead) {
      emailToUpdate.isRead = true;
      setEmails(updatedEmails);
    }
  }
  setSelectedEmailId(id);
};




 const deleteMessage = (id) => {
  const updatedEmails = [...emails];
  const index = updatedEmails.findIndex((x) => x.id === id);

  if (index !== -1) {
    const emailToUpdate = updatedEmails[index];
    const category = emailToUpdate.folderData?.displayName;

    if (category !== 'deleted items') {
      emailToUpdate.folderData.displayName = 'deleted items';
      updatedEmails.forEach((email) => {
        if (email.id !== id) {
          const emailCategory = email.folderData?.displayName;
          if (emailCategory === category) {
            email.folderData.displayName = '';
          }
        }
      });
      //handleMoveToTrash(id);
    } else {
      //handleDelete(id);
      updatedEmails.splice(index, 1);
    }

    setEmails(updatedEmails);
  }
};






  const setSidebarSection = (section) => {
    if (section !== currentSection) {
      setSelectedEmailId(null);
    }
    setCurrentSection(section);
  };

  

  const currentEmailId = selectedEmailId;
  const currentEmail = emails.find(email => email.id === currentEmailId);

  const filteredEmails = emails.filter((email) => {
    const category = email.folderData?.displayName;
    const selectedCategory = currentSection.toLowerCase();
    return category && category.toLowerCase() === selectedCategory;
  });

  const toggleView = selectedEmailId !== null
    ? emails.find(email => email.id === selectedEmailId)
    : null;
  return (
    <div>
      <Sidebar emails={emails} setSidebarSection={setSidebarSection} setSelectedEmailId = {setSelectedEmailId} sideBarSection = {currentSection} />
      <div className="inbox-container">
      {toggleView ? (
          <EmailDetails email={emails.find(email => email.id === selectedEmailId)} onDelete={deleteMessage} onBack={onBack}/>
        ) : (
          <EmailList
          emails={filteredEmails}
          onEmailSelected={openEmail}
          selectedEmailId={selectedEmailId}
        />
        )}        
      </div>
    </div>
  );
};

const Sidebar = ({ emails, setSidebarSection, setSelectedEmailId, sideBarSection}) => {
 const inboxCount = emails.filter((msg) => !msg.isRead && msg.folderData?.displayName.toLowerCase() === 'inbox').length;
 const importantCount = emails.filter(msg => msg.folderData?.displayName.toLowerCase() === 'important' && !msg.isRead).length;
 const deletedCount = emails.filter(msg => msg.folderData?.displayName.toLowerCase() === 'deleted items' && !msg.isRead).length;
 const sentCount = emails.filter(msg => msg.folderData?.displayName.toLowerCase() === 'sent items' && !msg.isRead).length;

  return (
    <div id="sidebar">
      <ul className="sidebar__inboxes">
        <li
          className={`list-item ${sideBarSection === 'inbox' ? 'selected' : ''}`}
          onClick={() => {setSidebarSection('inbox');setSelectedEmailId(null);}}
        >
          <a>
            <span className="fa fa-inbox"></span> Inbox
            {inboxCount > 0 &&<span className="item-count">{inboxCount}</span>}
          </a>
        </li>
        <li
          className={`list-item ${sideBarSection === 'sent items' ? 'selected' : ''}`}
          onClick={() => {setSidebarSection('sent items');setSelectedEmailId(null);}}
        >
          <a>
            <span className="fa fa-paper-plane"></span> Sent
            {sentCount > 0 && <span className="item-count">{sentCount}</span>}
          </a>
        </li>
        <li
          className={`list-item ${sideBarSection === 'important' ? 'selected' : ''}`}
          onClick={() => {setSidebarSection('important');setSelectedEmailId(null);}}
        >
          <a>
            <span className="fa fa-pencil-square-o"></span> Important
            {importantCount > 0 && <span className="item-count">{importantCount}</span>}
          </a>
        </li>
        <li
          className={`list-item ${sideBarSection === 'deleted items' ? 'selected' : ''}`}
          onClick={() => {setSidebarSection('deleted items');setSelectedEmailId(null);}}
        >
          <a>
            <span className="fa fa-trash-o"></span> Trash
            {deletedCount > 0 && <span className="item-count">{deletedCount}</span>}
          </a>
        </li>
      </ul>
    </div>
  );
};

const EmailListItem = ({ email, onEmailClicked, selected }) => {
  const classes = "email-item" + (selected ? " selected" : "");
  /*const getHeaderValue = (name) => {
    const header = email.payload.headers.find(header => header.name === name);
    return header ? header.value : 'N/A';
  };
  const parts = getHeaderValue('From').split('<');
  const from = parts[0].trim();
  */
  const isUnread = !email.isRead
  //const isUnread = email.labelIds.includes('UNREAD');

  return (
    <div onClick={() => onEmailClicked(email.id)} className={classes}>
       <div className="email-item__unread-dot" data-read={!isUnread}></div>
      <div className="email-item__subject truncate">{email.subject}</div>
      <div className="email-item__details">
        <span className="email-item__from truncate">{email.from.emailAddress.name}</span>
        <span className="email-item__time truncate">{getPrettyDate(email.createdDateTime)}</span>
      </div>
    </div>
  );
};

const EmailDetails = ({ email, onDelete,onBack }) => {
  let bodyPartsHtml = ""
  let payloadBody = ""
  if(email.payload && email.payload.parts)
    bodyPartsHtml = email.payload.parts[1];
  if(email.payload && email.payload.body)
    payloadBody = email.payload.body;

  let bodyText = "";

  // Extract the body of the email
  if (bodyPartsHtml !== "") {
    if (bodyPartsHtml.body.data) {
      const decodedBody = atob(bodyPartsHtml.body.data.replace(/-/g, '+').replace(/_/g, '/'));
      bodyText = decodeURIComponent(escape(decodedBody));
    } else {
      bodyText = email.snippet;
    }
  } else if (payloadBody !== "") {
    if (payloadBody.data) {
      const decodedData = atob(payloadBody.data.replace(/-/g, '+').replace(/_/g, '/'));
      bodyText = decodeURIComponent(escape(decodedData));
    } else {
      bodyText = email.snippet;
    }
  } else {
    bodyText = email.snippet;
  }

  const output= DOMPurify.sanitize(bodyText);
    if (!email) {
      return <div className="email-content empty"></div>;
    }

  /*const getHeaderValue = (name) => {
    const header = email.payload.headers.find(header => header.name === name);
    return header ? header.value : 'N/A';
  };*/
  const date = `${getPrettyDate(email.createdDateTime)} · ${getPrettyTime(email.createdDateTime)}`;

  const getDeleteButton = () => {
    if (email.tag !== 'deleted') {
      return <span onClick={() => onDelete(email.id)} className="delete-btn fa fa-trash-o">Delete</span>;
    }
    return undefined;
  };

  return (
    <div className="email-content">
      <div className="email-content__header">
        <h3 className="email-content__subject">{email.subject}</h3>
        {getDeleteButton()}
        <button onClick={onBack} className="back-button">
          &lt; Back to List
        </button>
        <div className="email-content__time">{date}</div>
        <div className="email-content__from">{email.from.emailAddress.name}</div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: output }} />
     
    </div>
  );
};

const EmailList = ({ emails, onEmailSelected, selectedEmailId }) => {
  if (emails.length === 0) {
    return <div className="email-list empty">Nothing to see here, great job!</div>;
  }

  return (
    <div className="email-list">
      {emails.map(email => (
        <EmailListItem
          key={email.indexId}
          onEmailClicked={onEmailSelected}
          email={email}
          selected={selectedEmailId === email.id}
        />
      ))}
      
    </div>
  );
};

const getPrettyDate = (dateString) => {
  const currentDate = new Date();
  const inputDate = new Date(dateString);

  if (
    currentDate.getDate() === inputDate.getDate() &&
    currentDate.getMonth() === inputDate.getMonth() &&
    currentDate.getFullYear() === inputDate.getFullYear()
  ) {
    const elapsedMilliseconds = currentDate - inputDate;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

    if (elapsedSeconds < 60) {
      return `${elapsedSeconds} seconds ago`;
    } else if (elapsedSeconds < 3600) {
      const elapsedMinutes = Math.floor(elapsedSeconds / 60);
      return `${elapsedMinutes} ${elapsedMinutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      const elapsedHours = Math.floor(elapsedSeconds / 3600);
      return `${elapsedHours} ${elapsedHours === 1 ? 'hour' : 'hours'} ago`;
    }
  } else {
    const month = months[inputDate.getMonth()];
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();
    return `${month} ${day < 10 ? '0' : ''}${day}, ${year}`;
  }
};

const getPrettyTime = (dateString) => {
  const inputDate = new Date(dateString);
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};



export default InboxAzure;
