import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import IconButton from "../../Components/Button/IconButton";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import { UploadDocumentModel } from "../../Models/DocumentUploadModel";
import DocumentUploadService from "../../Services/DocumentUploadService";
import Environment from "../../config.json";
import ModalDialog from "../../Components/Modal/ModelDialog";
import TextInputField from "../../Components/TextInputField/TextInputField";
import React from "react";
const DocumentUploadServices = new DocumentUploadService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  clientId: number;
  DocumentInfo: UploadDocumentModel;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  fileName: string;
  documentsName: any;
  uploadedFile: any;
  filesName: any;
  clientDocId: any;
  docStatus: any;
  uploadedDate: any;
  docNameObj: any;
  docPathObj: any;
  userInfo: any;
  isAddDoc: boolean;
  docname: string;
}

class Document extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      clientId: 0,
      DocumentInfo: {} as UploadDocumentModel,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      fileName: "",
      documentsName: [],
      uploadedFile: {},
      filesName: {},
      clientDocId: {},
      docStatus: {},
      uploadedDate: {},
      docNameObj: {},
      docPathObj: {},
      userInfo: {},
      isAddDoc: false,
      docname: "",
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");
      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        const documentName = await DocumentUploadServices.getDocumentsName(
          JSON.parse(userInfo).provider_id
        );

        this.getAllDocuments(clientId, JSON.parse(userInfo).provider_id);

        this.setState({
          DocumentInfo: {
            ...this.state.DocumentInfo,
            staff_id: "",
            provider_id: 1,
            client_id: clientId,
            document_type: "",
          },
          documentsName: documentName.data,
          clientId,
          userInfo: JSON.parse(userInfo),
        });
      }
    }
  }
  getAllDocuments = async (clientId: number, providerId: number) => {
    const allDocuments = await DocumentUploadServices.getAllDocuments(
      clientId,
      providerId
    );

    const docObj: any = {};
    const clientDocIdObj: any = {};
    const docStatusObj: any = {};
    const dateObj: any = {};
    const docNameObj: any = {};
    const docPathObj: any = {};

    allDocuments.data.forEach((el: any) => {
      const document_id = el.document_id;
      docObj[document_id] = el.document_name;
      clientDocIdObj[document_id] = el.client_document_id;
      docStatusObj[document_id] = el.status;
      dateObj[document_id] = el.created_on;
      docNameObj[document_id] = el.document_name;
      docPathObj[document_id] = el.document_path;
    });

    this.setState({
      clientId,
      filesName: docObj,
      clientDocId: clientDocIdObj,
      docStatus: docStatusObj,
      uploadedDate: dateObj,
      docNameObj,
      docPathObj,
    });
  };

  // getAllDocuments = async (clientId: number, providerId: number) => {
  //   const allDocuments = await DocumentUploadServices.getAllDocuments(
  //     clientId,
  //     providerId
  //   );
  //   const docObj: any = {};
  //   allDocuments.data.forEach((el: any) => {
  //     docObj[el.document_id] = el.document_name;
  //   });
  //   const clientDocIdObj: any = {};
  //   allDocuments.data.forEach((el: any) => {
  //     clientDocIdObj[el.document_id] = el.client_document_id;
  //   });
  //   const docStatusObj: any = {};
  //   allDocuments.data.forEach((el: any) => {
  //     docStatusObj[el.document_id] = el.status;
  //   });
  //   const dateObj: any = {};
  //   allDocuments.data.forEach((el: any) => {
  //     dateObj[el.document_id] = el.created_on;
  //   });
  //   const docNameObj: any = {};
  //   allDocuments.data.forEach((el: any) => {
  //     docNameObj[el.document_id] = el.document_name;
  //   });
  //   const docPathObj: any = {};
  //   allDocuments.data.forEach((el: any) => {
  //     docPathObj[el.document_id] = el.document_path;
  //   });
  //   this.setState({
  //     clientId,
  //     filesName: docObj,
  //     clientDocId: clientDocIdObj,
  //     docStatus: docStatusObj,
  //     uploadedDate: dateObj,
  //     docNameObj,
  //     docPathObj,
  //   });
  // };
  onDocumentupload = async (event: React.FormEvent, docId: number) => {
    event.preventDefault();

    try {
      const { clientId, uploadedFile, userInfo } = this.state;
      const response = await DocumentUploadServices.uploadDocuments(
        Number(clientId),
        userInfo.provider_id,
        docId,
        uploadedFile
      );
      if (response) {
        this.getAllDocuments(clientId, userInfo.provider_id);
        this.setState({
          openAlert: true,
          alertMsg: "Document uploaded successfully",
          alertType: "success",
        });
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };

  onDelete = async (docId: number) => {
    const { clientDocId, clientId, userInfo } = this.state;

    const response = await DocumentUploadServices.deleteDocuments(
      clientDocId[docId],
      clientId,
      userInfo.provider_id
    );
    if (response) {
      this.getAllDocuments(clientId, userInfo.provider_id);
      this.setState({
        openAlert: true,
        alertMsg: "Document deleted successfully",
        alertType: "success",
      });
    }
  };
  onDownload = async (docId: number) => {
    const { docNameObj, docPathObj } = this.state;
    window.open(
      `${Environment.Enviroment.dev.BASE_API_URL}/download_document?file_path=${docPathObj[docId]}&file_name=${docNameObj[docId]}`,
      "_blank"
    );
  };
  handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    docName: string,
    docId: number,
    id: number
  ) => {
    const { target } = event;
    //console.log(docId, docName);
    let docId1 = this.state.documentsName[id].document_id;
    if (target.files && target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", target.files[0]);

      // this.setState({ uploadedFile: formData });
      const fileName = target.files[0].name;

      this.setState(
        (prevState) => ({
          ...prevState,
          filesName: {
            ...prevState.filesName,
            [docId1]: fileName,
          },
        }),
        () => {
          this.setState({ uploadedFile: formData });
        }
      );

      //console.log(response);
    }
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      docname: event.target.value,
    });
  };
  handleSaveDocName = async () => {
    const { clientId, docname, userInfo } = this.state;

    const response = await DocumentUploadServices.addDocumentsName(
      docname,
      userInfo.provider_id
    );
    if (response) {
      const documentName = await DocumentUploadServices.getDocumentsName(
        userInfo.provider_id
      );

      this.getAllDocuments(clientId, userInfo.provider_id);

      this.setState({
        documentsName: documentName.data,
      });
      this.setState({
        openAlert: true,
        alertMsg: "Document Name Added Successfully",
        alertType: "success",
        isAddDoc: false,
      });
    }
  };
  render() {
    const {
      openAlert,
      alertMsg,
      alertType,
      isAddDoc,
      documentsName,
      filesName,
      docStatus,
      uploadedDate,
      docname,
    } = this.state;
    //console.log(filesName);
    let fileExplorerStyle = {
      // color: "#FFFFFF",
      cursor: "pointer",
      fontWeight: 400,
      fontSize: "1rem",
      height: "2rem",
    };
    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container justifyContent="space-between">
              <Typography
                marginBottom={1}
                style={{ fontSize: "1rem", fontWeight: 550 }}
              >
                Documents
              </Typography>
              <Button
                style={{
                  height: "2.5rem",
                  textTransform: "none",
                  marginLeft: 5,
                }}
                variant="contained"
                onClick={() => {
                  this.setState({
                    isAddDoc: true,
                  });
                }}
              >
                + Add Document Name
              </Button>
            </Grid>

            <hr />
            <ModalDialog
              isOpen={isAddDoc}
              dialogWidth="md"
              title="Add Document Name"
              onClose={() => {
                this.setState({
                  isAddDoc: false,
                });
              }}
            >
              <Grid item container>
                <Grid item xs={8} mr={3}>
                  <TextInputField
                    id="docname"
                    value={docname}
                    onChange={this.onInputChange}
                    placeholder="Enter Document Name"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    style={{
                      height: "2.5rem",
                      textTransform: "none",
                      marginLeft: 5,
                    }}
                    variant="contained"
                    onClick={this.handleSaveDocName}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </ModalDialog>
            <form>
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
                }}
              >
                <CardContent>
                  <Grid container item flexDirection={"column"} marginTop={3}>
                    <Grid container item columnSpacing={2}>
                      <Grid item xs={3}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 550 }}
                        >
                          Document Name
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 550 }}
                        >
                          File Upload
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 550 }}
                        >
                          File Name
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 550 }}
                        >
                          Uploaded On
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 550 }}
                        >
                          Upload Status
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 550 }}
                        >
                          Action
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container>
                    {/* {documentsName.length > 0 ? (
                      <Grid container item flexDirection={"column"}>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[0].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file"
                                onChange={(event, ind: number = 0) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[0].document_name,
                                    documentsName[0].document_id,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[0].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[0].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[0].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[0].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[0].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[0].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[0].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[0].document_id
                                    )
                                  }
                                />

                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[0].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[1].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file1"
                                onChange={(event, ind: number = 1) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[1].document_id,
                                    documentsName[1].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file1"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[1].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[1].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[1].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[1].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[1].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[1].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[1].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[1].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[1].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[2].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file2"
                                onChange={(event, ind: number = 2) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[2].document_id,
                                    documentsName[2].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file2"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[2].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[2].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[2].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[2].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[2].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[2].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[2].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[2].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[2].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[3].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file3"
                                onChange={(event, ind: number = 3) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[3].document_id,
                                    documentsName[3].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file3"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[3].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[3].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[3].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[3].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[3].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[3].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[3].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[3].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[3].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[4].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file4"
                                onChange={(event, ind: number = 4) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[4].document_id,
                                    documentsName[4].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file4"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[4].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[4].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[4].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[4].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[4].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[4].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[4].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[4].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[4].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[5].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file5"
                                onChange={(event, ind: number = 5) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[5].document_id,
                                    documentsName[5].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file5"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[5].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[5].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[5].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[5].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[5].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[5].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[5].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[5].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[5].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[6].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file6"
                                onChange={(event, ind: number = 6) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[6].document_id,
                                    documentsName[6].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file6"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[6].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[6].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[6].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[6].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[6].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[6].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[6].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[6].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[6].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[7].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file7"
                                onChange={(event, ind: number = 7) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[7].document_id,
                                    documentsName[7].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file7"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[7].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[7].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[7].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[7].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[7].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[7].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[7].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[7].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[7].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[8].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file8"
                                onChange={(event, ind: number = 8) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[8].document_id,
                                    documentsName[8].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file8"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[8].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[8].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[8].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[8].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[8].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[8].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[8].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[8].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[8].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[9].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file9"
                                onChange={(event, ind: number = 9) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[9].document_id,
                                    documentsName[9].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file9"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[9].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[9].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[9].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[9].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[9].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[9].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[9].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[9].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[9].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[10].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file10"
                                onChange={(event, ind: number = 10) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[10].document_id,
                                    documentsName[10].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file10"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[10].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[10].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[10].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[10].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[10].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[10].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[10].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[10].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[10].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[11].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file11"
                                onChange={(event, ind: number = 11) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[11].document_id,
                                    documentsName[11].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file11"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[11].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[11].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[11].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[11].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[11].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[11].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[11].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[11].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[11].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item mt={5} columnSpacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {documentsName[12].document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="my_file12"
                                onChange={(event, ind: number = 12) =>
                                  this.handleFileChange(
                                    event,
                                    documentsName[12].document_id,
                                    documentsName[12].document_name,
                                    ind
                                  )
                                }
                              />
                              <label
                                htmlFor="my_file12"
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[documentsName[12].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[documentsName[12].document_id]
                                ? new Date(
                                    uploadedDate[documentsName[12].document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[documentsName[12].document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={
                                filesName[documentsName[12].document_id]
                                  ? false
                                  : true
                              }
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(
                                  event,
                                  documentsName[12].document_id
                                )
                              }
                            >
                              Save
                            </Button>
                            {docStatus[documentsName[12].document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(
                                      documentsName[12].document_id
                                    )
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() =>
                                    this.onDelete(documentsName[12].document_id)
                                  }
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null} */}

                    {documentsName.map((doc: any, index: any) => (
                      <Grid
                        container
                        item
                        flexDirection={"column"}
                        key={doc.document_id}
                      >
                        <Grid
                          container
                          item
                          mt={5}
                          columnSpacing={2}
                          marginTop={2}
                        >
                          <Grid item xs={3}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {doc.document_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="outlined"
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                              }}
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id={`my_file_${index}`}
                                onChange={(event) =>
                                  this.handleFileChange(
                                    event,
                                    doc.document_name,
                                    doc.document_id,
                                    index
                                  )
                                }
                              />
                              <label
                                htmlFor={`my_file_${index}`}
                                style={fileExplorerStyle}
                              >
                                &nbsp;Upload
                              </label>
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {filesName[doc.document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              marginBottom={1}
                              style={{ fontSize: "1rem", fontWeight: 400 }}
                            >
                              {uploadedDate[doc.document_id]
                                ? new Date(
                                    uploadedDate[doc.document_id]
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              marginBottom={1}
                              style={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "green",
                              }}
                            >
                              {docStatus[doc.document_id]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={!filesName[doc.document_id]}
                              style={{
                                height: "2.5rem",
                                textTransform: "none",
                                marginLeft: 5,
                              }}
                              variant="contained"
                              onClick={(event) =>
                                this.onDocumentupload(event, doc.document_id)
                              }
                            >
                              Save
                            </Button>
                            {docStatus[doc.document_id] ? (
                              <>
                                <IconButton
                                  IconType="Download"
                                  onClick={() =>
                                    this.onDownload(doc.document_id)
                                  }
                                />
                                <IconButton
                                  IconType="Delete"
                                  onClick={() => this.onDelete(doc.document_id)}
                                />
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(Document);
