import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import {
  SentimentSatisfied,
  SentimentDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";

// Define TypeScript interfaces for props and state
interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
  onSubmitFeedback: (feedback: string) => void;
  comments: string;
  onTextAreaInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
}

enum FeedbackEmoji {
  None = "none",
  Happy = "happy",
  Sad = "sad",
}

interface FeedbackModalState {
  selectedFeedback: FeedbackEmoji;
}

class FeedbackModal extends Component<FeedbackModalProps, FeedbackModalState> {
  constructor(props: FeedbackModalProps) {
    super(props);
    this.state = {
      selectedFeedback: FeedbackEmoji.None,
    };
  }

  handleFeedbackSelection = (feedback: FeedbackEmoji) => {
    this.setState({ selectedFeedback: feedback });
  };

  handleSubmitFeedback = () => {
    const { selectedFeedback } = this.state;
    this.props.onSubmitFeedback(selectedFeedback);
    this.setState({ selectedFeedback: FeedbackEmoji.None });
    this.props.onClose();
  };

  render() {
    const { open, onClose, comments, onTextAreaInputChange } = this.props;
    const { selectedFeedback } = this.state;

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle sx={{ fontSize: "1.8rem" }}>
          How was your experience?
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item container xs={3} justifyContent="center">
              <Grid>
                <IconButton
                  color={
                    selectedFeedback === FeedbackEmoji.Happy
                      ? "primary"
                      : "default"
                  }
                  onClick={() =>
                    this.handleFeedbackSelection(FeedbackEmoji.Happy)
                  }
                >
                  <SentimentVerySatisfied
                    style={{ fontSize: "4rem", fontWeight: 550 }}
                  />
                </IconButton>
              </Grid>
              <Grid>
                <Typography style={{ fontSize: "1.2rem", fontWeight: 550 }}>
                  Happy
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={2.5} justifyContent="center">
              <Grid>
                <IconButton
                  color={
                    selectedFeedback === FeedbackEmoji.None
                      ? "primary"
                      : "default"
                  }
                  onClick={() =>
                    this.handleFeedbackSelection(FeedbackEmoji.None)
                  }
                >
                  <SentimentSatisfied
                    style={{ fontSize: "4rem", fontWeight: 550 }}
                  />
                </IconButton>
              </Grid>
              <Grid>
                <Typography style={{ fontSize: "1.2rem", fontWeight: 550 }}>
                  None
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={2.5} justifyContent="center">
              <Grid>
                <IconButton
                  color={
                    selectedFeedback === FeedbackEmoji.Sad
                      ? "primary"
                      : "default"
                  }
                  onClick={() =>
                    this.handleFeedbackSelection(FeedbackEmoji.Sad)
                  }
                >
                  <SentimentDissatisfied
                    style={{ fontSize: "4rem", fontWeight: 550 }}
                  />
                </IconButton>
              </Grid>
              <Grid>
                <Typography style={{ fontSize: "1.2rem", fontWeight: 550 }}>
                  Sad
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <TextAreaInputField
              minRows={4}
              isRequired={false}
              id="comments"
              value={comments}
              onChange={onTextAreaInputChange}
              placeholder="Enter your comment here..."
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{ textTransform: "none", fontSize: "16px" }}
            onClick={onClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmitFeedback}
            color="primary"
            variant="contained"
            style={{ textTransform: "none", fontSize: "16px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FeedbackModal;
