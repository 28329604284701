import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import { Component } from "react";

import { Box, Grid, Typography } from "@mui/material";

import { RouteComponentProps } from "react-router-dom";
import NewRosterService from "./../../Services/NewRosterService";

import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import NewAgreementService from "../../Services/NewAgreementService";
import CalendarService from "./../../Services/CalendarService";
const AgreementService = new NewAgreementService();
const RosterService = new NewRosterService();
const CalService = new CalendarService();
type CalendarArray = {
  id?: number;
  start: string;
  end: string;
};

interface Props extends RouteComponentProps<any, any, any> {}
// interface Props{
//     data:any
// };
interface State {
  label: string;
  dropDownData: SelectModel[];
  isDropDown: boolean;
  calendarData: any;
  userInfo: any;
}
class CalenderView extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      label: "",
      dropDownData: [],
      isDropDown: false,
      calendarData: [],
      userInfo: {},
    };
  }

  selectDataDropDown1: SelectModel[] = [
    {
      value: "Staff",
      text: "Staff",
    },
    {
      value: "Client",
      text: "Client",
    },
  ];

  componentDidMount() {
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      this.setState({ userInfo: JSON.parse(userInfo) });
    }
  }
  onDropDownChange = async (selected: SelectModel) => {
    if (selected.value === "Staff") {
      const response = await RosterService.getStaffDropDownValue(
        this.state.userInfo.provider_id
      );
      let d = response.data.map((el: any, i: any) => {
        return {
          text: el.staff_name + "_" + i,
          value: el.staff_name,
          id: el.staff_id,
        };
      });
      this.setState({
        isDropDown: true,
        dropDownData: d,
        label: "staff",
      });
    } else if (selected.value === "Client") {
      const response =
        await AgreementService.getServiceAgreementsDropDownValue(this.state.userInfo.provider_id);
      let d = response.data.map((el: any, i: any) => {
        return {
          text: el.client_name + "_" + i,
          value: el.client_name,
          id: el.client_id,
        };
      });
      this.setState({
        isDropDown: true,
        dropDownData: d,
        label: "client",
      });
    }
  };
  onDropDown2Change = async (selected: SelectModel, targetId: any) => {
    if (targetId === "staff_id") {
      const response = await CalService.getStaffCalendar(
        Number(selected.id),
        this.state.userInfo.provider_id
      );
      let d = response.data.map((el: any, i: any) => {
        return {
          start: moment(el.start_datetime).format(),
          end: moment(el.end_datetime).format(),
          title: el.client_name,
          description: el.shift_item_name,
          id: i,
        };
      });
      this.setState({
        calendarData: d,
      });
    } else if (targetId === "client_id") {
      const response = await CalService.getClientCalendar(
        42,
        this.state.userInfo.provider_id
      );
      let d = response.data.map((el: any, i: any) => {
        return {
          start: moment(el.start_datetime).format(),
          end: moment(el.end_datetime).format(),
          title: el.staff_name,
          description: el.shift_item_name,
          id: i,
        };
      });
      this.setState({
        calendarData: d,
      });
    }
    // CalService
  };
  render() {
    const { label, isDropDown, dropDownData, calendarData } = this.state;
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ display: "flex" }}>
          <Typography
            marginBottom={5}
            style={{ fontSize: "1.7rem", fontWeight: 550 }}
          >
            Calendar
          </Typography>
        </Box>
        <Grid container marginBottom={4}>
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <SingleSelect
              // value={clientInfo.lead_status}
              id={label}
              size="small"
              values={this.selectDataDropDown1}
              onChange={this.onDropDownChange}
            />
          </Grid>
          {isDropDown && (
            <Grid
              item
              xs={3}
              display="flex"
              justifyContent="flex-end"
              sx={{ marginLeft: "20px" }}
            >
              <SingleSelect
                // value={clientInfo.lead_status}
                id={`${label}_id`}
                size="small"
                values={dropDownData}
                onChange={this.onDropDown2Change}
              />
            </Grid>
          )}
        </Grid>

        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          eventClick={function (arg) {
            alert(`Shift Item Name: ${arg.event.extendedProps.description}`);
          }}
          events={[...calendarData]}
        />
      </Box>
    );
  }
}

export default CalenderView;
