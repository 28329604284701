import React, { Component } from 'react'
import Snackbar from "@mui/material/Snackbar";
import { Alert, Button, AlertColor } from "@mui/material";
import IconButton from '../Button/IconButton';

type Props = {
  open: boolean;
  onClose: (() => void);
  message: string;
  onClickView?: (() => void);
  alertType: AlertColor;
}

type State = {}

class SnackbarAlert extends Component<Props, State> {
  state = {}

  render() {
    const { open, onClose, message, onClickView, alertType } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <Alert
          onClose={onClose}
          severity={alertType}
          action={(
            <>
            {onClickView && alertType === 'success'? <Button
                color="error"
                size="small"
                onClick={onClickView}
              >
                View
              </Button>: null}
              <IconButton
                IconType="Cancel"
                onClick={onClose}
              />
            </>
          )}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    )
  }
}

export default SnackbarAlert;