import React, { useState, useEffect,useRef } from 'react';
import './new-profile.css';
import { useHistory } from 'react-router-dom';
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert.tsx";
import Enviroment from "./../../config.json";
const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";
const NewProfile = () => {
  const history = useHistory();
  const [roleName, setRoleName] = useState("");
  const [selectedCloneProfile, setSelectedCloneProfile] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [roleDescription, setRoleDescription] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
   const urlGetAllProfiles = baseURL + "/get_all_roles";
    const headers: any = {
    'Content-Type': 'application/json'
  }
  const method: string = 'GET';
  useEffect(() => {
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('userInfo')) {
      const userInfoFromSession = sessionStorage.getItem('userInfo');
      setUserInfo(userInfoFromSession);
    }
  }, []);
  useEffect(() => {
    const fetchRoles = async () => {
      if(userInfo)
      {try {
        const { provider_id } = JSON.parse(userInfo);
        const queryParams = new URLSearchParams({ provider_id });
        const urlWithParams = `${urlGetAllProfiles}?${queryParams}`;
        const response = await fetch(urlWithParams, { method, headers});
        const data = await response.json();
        console.log(data);
        setProfiles(data);
        setSelectedCloneProfile(data.length > 0 ? data[0].role_name : '');
      } catch (error) {
        console.error(error);
      }}
    };
    fetchRoles();
  }, [userInfo]);


  const saveProfile= async()=> {
    if(userInfo)
    {
    try {
      const cloneProfileObj = profiles.find((profile) => profile.role_name === selectedCloneProfile);
      const postProfile = {
        profile_name: roleName,
        profile_description: roleDescription,
        clone_profile: selectedCloneProfile,
        provider_id: JSON.parse(userInfo).provider_id
      }
      console.log(postProfile)
      const response = await fetch(baseURL + "/create_custom_profile", {
        method: "POST",
        body: JSON.stringify(postProfile),
        headers
      });

      if (response.ok) {
        const processedProfiles = await response.json();

        console.log("Profile added successfully:", processedProfiles);
        setOpenAlert(true);
        setAlertMsg("Profile Added");
        setAlertType("success");
        setTimeout(() => {
          history.push('/settings/profiles');
        }, 2000);

      } else {
        console.error("Error Adding Profile:", response.status);
        setOpenAlert(true);
        setAlertMsg("Error Adding Profile");
        setAlertType("error");
      }

    } catch (error) {
      console.error("Error Adding Profile:", error);
      setOpenAlert(true);
        setAlertMsg("Error Adding Profile");
        setAlertType("error");
    }
  }
  }
  return (
    <>
    <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            setOpenAlert(false);
          }}
        />
    <div className="new-profile-main-container">
      <span className="new-profile-title">Profiles</span>
      <span className="new-profile-subtitle">This page will allow you to add new profiles and provide custom permissions to staff</span>
      <div className = "new-profile-input-containers">
        <div className = "new-profile-element-div">
          <span className ="new-profile-element-span">Profile Name</span>
          <input onChange = {(e)=>(setRoleName(e.target.value))} className = "new-profile-element-input"/>
        </div>
        <div className = "new-profile-element-div">
          <span className ="new-profile-element-span">Clone From</span>
          <select
            value={selectedCloneProfile}
            onChange={(e) => setSelectedCloneProfile(e.target.value)}
            className="new-profile-element-input"
          >
            {profiles.length > 0 && profiles.map((profile) => (
              <option key={profile.role_id} value={profile.role_name}>
                {profile.role_name}
              </option>
            ))}
          </select>
        </div>
        <div className = "new-profile-element-div">
          <span className ="new-profile-element-span">Description</span>
          <textArea onChange = {(e)=>(setRoleDescription(e.target.value))} className = "new-profile-desc-input"/>
        </div>
      </div>
      <div className = "new-profile-btn-div">
        <button className = "new-profile-cancel-btn" onClick = {()=>{history.push('/settings/profiles')}}>Cancel</button>
        <button className = "new-profile-save-btn" onClick = {saveProfile}>Save</button>
      </div>
    </div>
    </>
  );
};

export default NewProfile;