import * as React from "react";

import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

interface Props {
  label?: string;
  value?: any;
  placeHolder?: string;
  disabled?: boolean;
  width?: string;
  name?: string;
  onChange: (
    value: any | null,
    keyboardInputValue?: string | undefined,
    name?: string
  ) => void;
}
class TimePickerComponent extends React.Component<Props> {
  render() {
    const { value, onChange, label, width, disabled } = this.props;
    //console.log(value)
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          disabled={disabled}
          label={label}
          value={value}
          InputProps={{ style: { height: "2.5em", width: width || "auto" } }}
          onChange={this.handleTimeChange}
          renderInput={(params) => {
            //console.log(params);
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Select",
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    );
  }
  handleTimeChange = (
    value:any,
    keyboardInputValue?: string | undefined
  ) => {
    this.props.onChange(value, keyboardInputValue, this.props.name);
  };
}
export default TimePickerComponent;
