import { URIs } from "../Common/Constants";
import { AssociatedContactModel } from "../Models/AssociatedContact";

import GenericAPIService from "./GenericAPIService";

const {
  GET_ALL_ASSOCIATE,
  GET_ASSOCIATE_BY_ID,
  CREATE_ASSOCIATE,
  UPDATE_ASSOCIATE,
  DELETE_SELECTED_ASSOCIATE,
} = URIs;
class AssociateContactService extends GenericAPIService {
  async getAllAssociateContact(clientId: any, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_ALL_ASSOCIATE}?client_id=${clientId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  async getAssociateById(clientId: any, providerId: number) {
    const data: any = await this.getAsync(
      `${GET_ASSOCIATE_BY_ID}?client_assocontact_id=${clientId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async createAssociatedContact(body: AssociatedContactModel) {
    const data = await this.postAsync(`${CREATE_ASSOCIATE}`, body);
    //console.log("data", data);
    return data;
  }
  async UpdateAssociatedContact(body: AssociatedContactModel) {
    const { data } = await this.postAsync<{ data: any }>(
      UPDATE_ASSOCIATE,
      body
    );
    //console.log("data", data);
    return data;
  }
  async deleteAssociatedContact(
    clientId: any,
    associateId: number,
    providerId: number
  ) {
    const data: any = await this.getAsync(
      `${DELETE_SELECTED_ASSOCIATE}?client_id=${clientId}& client_assocontact_id=${associateId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
}

export default AssociateContactService;
