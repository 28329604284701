import React, { useState, useEffect } from 'react';
import './roles.css';
import CustomizedTreeView from './tree-view.tsx';

import { useHistory } from 'react-router-dom';
import Enviroment from './../../config.json';


const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";


const Roles = ({ onAddNewRoleClick }) => {
  const [reportsTo, setReportsTo] = useState([]);
  const [hierarchicalData, setHierarchicalData] = useState([]);
  const [userInfo, setUserInfo] = useState();
  

  const history = useHistory();
  const urlGetAllCompanyRoles = baseURL + '/get_company_roles';
  const headers = {
    'Content-Type': 'application/json',
  };
  const method = 'GET';
  useEffect(() => {
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('userInfo')) {
      const userInfoFromSession = sessionStorage.getItem('userInfo');
      setUserInfo(userInfoFromSession);
    }
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
  if (userInfo) {
    try {
      const { provider_id } = JSON.parse(userInfo);
      const queryParams = new URLSearchParams({ provider_id });
      const urlWithParams = `${urlGetAllCompanyRoles}?${queryParams}`;
      const response = await fetch(urlWithParams, { method, headers });
      const data = await response.json();
      const hierarchicalData = convertToHierarchical(data);
      setHierarchicalData(hierarchicalData);
      setReportsTo(data);
    } catch (error) {
      console.error(error);
    }
  }
};

fetchRoles();

  }, [userInfo]);
  const convertToHierarchical = (data) => {
  const map = new Map();
  const result = [];

  // First pass: create a map of items using their company_role_id as keys
  data.forEach((item) => {
    map.set(item.company_role_id, { ...item, children: [] });
  });

  // Second pass: connect children to their parent nodes
  data.forEach((item) => {
    if (item.reports_to !== null) {
      const parent = map.get(item.reports_to);
      if (parent) {
        parent.children.push(map.get(item.company_role_id));
      }
    } else {
      // If there is no reports_to, it's a top-level node
      result.push(map.get(item.company_role_id));
    }
  });

  return result;
};

  

  return (
    
    <div className="roles-main-container">
      <span className="roles-title">Roles</span>
      <span className="roles-subtitle">
        This page will allow you to define how you share the data among users based on your organization's role hierarchy.
      </span>
      <button className="roles-add-role-btn" onClick={onAddNewRoleClick}>
        New Role
      </button>
      <div className = "roles-tree-holder">
        {hierarchicalData.length > 0 && <CustomizedTreeView data={hierarchicalData} />}
      </div>
    </div>
    
  );
};

export default Roles;
