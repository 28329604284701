import {
  AlertColor,
  Box,
  Button,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IconType } from "../../Components/Button/IconButton";

import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import DataLoader from "../../Components/Table/DataLoader";

import SearchFilter from "../../Components/Search/SearchFilter";

import ModalDialog from "../../Components/Modal/ModelDialog";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { ClientModel } from "../../Models/ClientModel";
import ClientServices from "../../Services/ClientServices";
import DatePicker from "../../Components/DatePicker/DatePicker";
const ClientService = new ClientServices();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  showEdit: boolean;
  isAddClient: boolean;
  recordMarked: any;
  searchText: string;
  clientInfo: ClientModel;
  countryData: SelectModel[];
  stateData: SelectModel[];
  cityData: SelectModel[];
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isEdit: boolean;
  filterData: any;
  userInfo: any;
}

class ContactData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      showEdit: false,
      isAddClient: false,
      recordMarked: {},
      searchText: "",
      clientInfo: {} as ClientModel,
      countryData: [],
      stateData: [],
      cityData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isEdit: false,
      filterData: [],
      userInfo: {},
    };
  }

  async componentDidMount() {
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      const response = await ClientService.getAllClients(
        JSON.parse(userInfo).provider_id
      );
      const countryDataResponse = await ClientService.getCountry();
      this.setState({
        clientInfo: {
          ...this.state.clientInfo,
          modified_by: JSON.parse(userInfo).staff_name
            ? JSON.parse(userInfo).staff_name
            : JSON.parse(userInfo).provider_name,
          provider_id: JSON.parse(userInfo).provider_id,
        },
        countryData: countryDataResponse.data.map((el: any) => {
          return {
            text: el,
            value: el,
          };
        }),
        userInfo: JSON.parse(userInfo),
      });
      const data = response.data.map((el: ClientModel) => {
        return {
          Id: el.client_id,
          name: el.client_name,
          status: el.client_status,
          phone: el.client_phone,
          email: el.client_email,
          creation_date: new Date(el.created_on).toDateString(),
        };
      });
      //console.log(data);
      this.setState({
        tableData: data,
        totalRecordsCount: data.length,
      });
    }
  }

  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        const response = await ClientService.getSelectedClient(
          record.Id,
          this.state.userInfo.provider_id
        );
        this.setState({
          clientInfo: response.data,
          isAddClient: true,
          isEdit: true,
        });
        break;
      case "Delete":
        const deleteResponse = await ClientService.deleteClient(
          record.Id,
          this.state.userInfo.provider_id
        );
        if (deleteResponse) {
          const updatedTableData = await ClientService.getAllClients(
            this.state.userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: ClientModel) => {
            return {
              Id: el.client_id,
              name: el.client_name,
              status: el.client_status,
              phone: el.client_phone,
              email: el.client_email,
              creation_date: new Date(el.created_on).toDateString(),
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Client Deleted Successfully",
            alertType: "success",
          });
        }
        break;
      case "Clone":
        break;
    }
  };

  onClickName = (_fieldClicked: string, record: any) => {
    if (record.Id) {
      this.props.history.push(`/viewclient/${record.Id}`);
    }
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      clientInfo: {
        ...this.state.clientInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onSearchChange = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const filterData = this.state.tableData.filter((el: any) =>
      el.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({
      filterData,
    });
    this.setState({ searchText: event.target.value });
    // if (event.target.value === "") {
    //   const response = await ClientService.getAllClients();

    //   const data = response.data.map((el: ClientDetailModel) => {
    //     return {
    //       Id: el.Id,
    //       name: el.Name,
    //       status: el.Status,
    //       phone: el.Phone,
    //       email: el.Email,
    //       creation_date: new Date(el.created_date).toDateString(),
    //     };
    //   });
    //   //console.log(data);
    //   this.setState({
    //     tableData: data,
    //     totalRecordsCount: data.length,
    //   });
    // }
  };
  currencyValues: SelectModel[] = [
    { text: "INR", value: "INR" },
    { text: "USD", value: "USD" },
    { text: "AUD", value: "AUD" },
  ];
  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    console.log(targetId);
    if (targetId) {
      this.setState({
        clientInfo: {
          ...this.state.clientInfo,
          [targetId]: selected.value,
        },
      });
    }
    if (targetId === "client_country") {
      this.getState(selected.value);
    }
    if (targetId === "client_state") {
      this.getCity(selected.value, this.state.clientInfo.client_country);
    }
  };

  getState = async (country: string) => {
    const response = await ClientService.getState(country);
    this.setState({
      stateData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };
  getCity = async (state: string, country: string) => {
    const response = await ClientService.getCity(state, country);
    this.setState({
      cityData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };
  onClientSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { clientInfo, isEdit } = this.state;

      if (isEdit) {
        const updatedResponse = await ClientService.UpdateClient(clientInfo);
        if (updatedResponse) {
          const updatedTableData = await ClientService.getAllClients(
            this.state.userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: ClientModel) => {
            return {
              Id: el.client_id,
              name: el.client_name,
              status: el.client_status,
              phone: el.client_phone,
              email: el.client_email,
              creation_date: new Date(el.created_on).toDateString(),
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Client Updated Successfully",
            alertType: "success",
            isAddClient: false,
          });
          this.onCancelClick();
        }
      } else {
        const response = await ClientService.createClient(clientInfo);
        if (response) {
          const response = await ClientService.getAllClients(
            this.state.userInfo.provider_id
          );
          const data = response.data.map((el: ClientModel) => {
            return {
              Id: el.client_id,
              name: el.client_name,
              status: el.client_status,
              phone: el.client_phone,
              email: el.client_email,
              creation_date: new Date(el.created_on).toDateString(),
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Client Created Successfully",
            alertType: "success",
            isAddClient: false,
          });
          this.onCancelClick();
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onCancelClick = () => {
    this.setState({
      isAddClient: false,
      clientInfo: {} as ClientModel,
      isEdit: false,
    });
  };
  onDateChange = (value: Date | null) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        clientInfo: {
          ...this.state.clientInfo,
          client_dob: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };
  genderValues: SelectModel[] = [
    { text: "Male", value: "Male" },
    { text: "Female", value: "Female" },
    { text: "Intersex", value: "Intersex" },
    { text: "Non-binary", value: "Non-binary" },
    { text: "Unspecified", value: "Unspecified" },
    { text: "Prefer not to say", value: "Prefer not to say" },
  ];
  interpreterValues: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];
  atsiValues: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
    { text: "Not stated", value: "Not Stated" },
  ];
  render() {
    const {
      tableData,
      totalRecordsCount,
      rows,
      page,
      searchText,
      isAddClient,
      clientInfo,
      countryData,
      stateData,
      cityData,
      openAlert,
      alertMsg,
      isEdit,
      alertType,
      filterData,
    } = this.state;
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <ModalDialog
          isOpen={isAddClient}
          title={isEdit ? "Edit Contact" : "Add New Contact"}
          onClose={() => {
            this.setState({
              isAddClient: false,
              isEdit: false,
              clientInfo: {} as ClientModel,
            });
          }}
        >
          <form onSubmit={this.onClientSave}>
            <Grid container item flexDirection={"column"}>
              <Grid container item rowSpacing={3}>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Name</FormLabel>
                    <TextInputField
                      id="client_name"
                      value={clientInfo.client_name}
                      onChange={this.onInputChange}
                      placeholder="Name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Phone</FormLabel>
                    <TextInputField
                      id="client_phone"
                      value={clientInfo.client_phone}
                      onChange={this.onInputChange}
                      placeholder="Phone"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Email</FormLabel>
                    <TextInputField
                      id="client_email"
                      value={clientInfo.client_email}
                      onChange={this.onInputChange}
                      placeholder="Email"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Gender</FormLabel>
                    <SingleSelect
                      value={clientInfo.client_gender}
                      id="client_gender"
                      size="small"
                      values={this.genderValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Date Of Birth
                    </FormLabel>
                    <DatePicker
                      width="21.5rem"
                      value={new Date(clientInfo.client_dob)}
                      onChange={(value) => this.onDateChange(value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Address</FormLabel>
                    <TextInputField
                      id="client_address"
                      value={clientInfo.client_address}
                      onChange={this.onInputChange}
                      placeholder="Address"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Country</FormLabel>
                    <SingleSelect
                      value={clientInfo.client_country}
                      id="client_country"
                      size="small"
                      values={countryData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>State</FormLabel>
                    <SingleSelect
                      customOptions={
                        clientInfo.client_country ? "" : "Select Country First"
                      }
                      value={clientInfo.client_state}
                      id="client_state"
                      size="small"
                      values={stateData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>City</FormLabel>
                    <SingleSelect
                      customOptions={
                        clientInfo.client_state ? "" : "Select State First"
                      }
                      value={clientInfo.client_city}
                      id="client_city"
                      size="small"
                      values={cityData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Preferred Language
                    </FormLabel>
                    <TextInputField
                      id="client_preferred_language"
                      value={clientInfo.client_preferred_language}
                      onChange={this.onInputChange}
                      placeholder="Enter Preferred Language"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Interpreter Required
                    </FormLabel>
                    <SingleSelect
                      value={clientInfo.client_interpreter_required}
                      id="client_interpreter_required"
                      size="small"
                      values={this.interpreterValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      ATSI Background
                    </FormLabel>
                    <SingleSelect
                      value={clientInfo.client_atsi_background}
                      id="client_atsi_background"
                      size="small"
                      values={this.atsiValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      CALD Background
                    </FormLabel>
                    <SingleSelect
                      value={clientInfo.client_cald_background}
                      id="client_cald_background"
                      size="small"
                      values={this.atsiValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Lead Source
                    </FormLabel>
                    <TextInputField
                      id="client_source"
                      value={clientInfo.client_source}
                      onChange={this.onInputChange}
                      placeholder="Lead Source"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Choose Industry
                    </FormLabel>
                    <TextInputField
                      id="client_industry"
                      value={clientInfo.client_industry}
                      onChange={this.onInputChange}
                      placeholder="Choose Industry"
                    />
                  </Grid>
                </Grid>

                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Enquiry Type
                    </FormLabel>
                    <TextInputField
                      id="client_enquirytype"
                      value={clientInfo.client_enquirytype}
                      onChange={this.onInputChange}
                      placeholder="Enquiry Type"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Currency</FormLabel>
                    <SingleSelect
                      value={clientInfo.client_curency}
                      id="client_curency"
                      size="small"
                      values={this.currencyValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Annual Revenue
                    </FormLabel>
                    <TextInputField
                      id="client_annualrevenue"
                      value={clientInfo.client_annualrevenue}
                      onChange={this.onInputChange}
                      placeholder="Revenue in Numbers"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Status</FormLabel>
                    <TextInputField
                      id="client_status"
                      value={clientInfo.client_status}
                      onChange={this.onInputChange}
                      placeholder="Status"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Company</FormLabel>
                    <TextInputField
                      id="client_company"
                      value={clientInfo.client_company}
                      onChange={this.onInputChange}
                      placeholder="Company"
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end">
                  {isEdit ? (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    onClick={this.onCancelClick}
                    style={{ textTransform: "capitalize", marginLeft: 7 }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>

        <Box style={{ display: "flex" }}>
          <Typography
            marginBottom={5}
            style={{ fontSize: "1.7rem", fontWeight: 550 }}
          >
            Contacts
          </Typography>
        </Box>
        <Grid container marginBottom={4}>
          <Grid item xs={6}>
            <SearchFilter
              id="searchText"
              placeholder="Search"
              value={searchText}
              onChange={this.onSearchChange}
            />
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              onClick={() => {
                this.setState({ isAddClient: true });
              }}
            >
              + Add New Contact
            </Button>
          </Grid>
        </Grid>
        <Box>
          <DataLoader
            totalRecordsCount={
              searchText ? filterData.length : totalRecordsCount
            }
            onChangePage={this.onChangePage}
            onChangeRow={this.onChangeRow}
            onActionClick={this.onActionClick}
            tableData={
              searchText
                ? rows > 0
                  ? filterData.slice(page * rows, page * rows + rows)
                  : filterData
                : rows > 0
                ? tableData.slice(page * rows, page * rows + rows)
                : tableData
            }
            onClickLink={this.onClickName}
            actionArray={["Edit", "Delete"]}
          />
        </Box>
      </Box>
    );
  }
}

export default withRouter(ContactData);
