import {
  Box,
  Table as MaterialTable,
  styled,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Theme,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faUpDown,
} from "@fortawesome/free-solid-svg-icons";
import _, { isEqual, isNull } from "lodash";
import { v4 as uuid } from "uuid";
import IconButton, { IconType } from "../Button/IconButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NoRecords from "../NoRecords/NoRecords";
import Utils from "../../Common/Utils";

type SortType = {
  key: string;
  direction: string;
};

interface Props extends RouteComponentProps<any, any, any> {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  sortConfig: SortType | null;
  data: any;
  totalRecordsCount: number;
  onClickCheckBox?: (selectedRows: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  actionArray?: IconType[];
  onActionClick?: (action: IconType, record: any) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
}

interface State {
  sortConfig: SortType | null;
  data: any;
  selectedData: any;
  selectAll: boolean;
  rowsPerPage: number;
  page: number;
  caretHover: boolean;
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class Table extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sortConfig: null,
      data: [],
      selectAll: false,
      selectedData: [],
      rowsPerPage: 5,
      page: 0,
      caretHover: false,
    };
  }
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
    if (this.props.onClickCheckBox) {
      if (!isEqual(prevState.data, this.state.data)) {
        //console.log("dadadta", this.state?.data);
        this.setState({
          selectedData: this.state?.data?.filter(
            (el: any) => el.checked === true
          ),
        });
      }
      if (!isEqual(prevState.selectedData, this.state.selectedData)) {
        this.props.onClickCheckBox(this.state.selectedData);
      }
    }
  };

  componentDidMount = () => {
    this.setState({ data: this.props.data });
  };

  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = "ascending";
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
    }
    this.setState({ sortConfig: { key, direction } });
    this.sorter();
  };

  sorter = () => {
    const { sortConfig, data } = this.state;
    let sortedData = data;
    if (sortConfig !== null) {
      sortedData = sortedData.sort(
        (a: { [x: string]: number }, b: { [x: string]: number }) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }
      );
    }
    this.setState({ data: sortedData });
    return sortedData;
  };

  handleChangePage = (_event: any, page: any) => {
    this.setState({ page: page });
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.onChangeRow(event.target.value);
  };

  defaultSortIconColor = "#959595";
  activeSortIconColor = "#000000";

  faCaretUpColor = this.activeSortIconColor;
  faCaretDownColor = this.activeSortIconColor;
  faUpDownColor = this.defaultSortIconColor;

  positionArrow = (key: string) => {
    const { sortConfig } = this.state;
    if (sortConfig) {
      if (sortConfig.direction === "ascending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretUp}
            color={this.faCaretUpColor}
            size="xs"
          />
        );
      if (sortConfig.direction === "descending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretDown}
            color={this.faCaretDownColor}
            size="xs"
          />
        );
    }
    return (
      <FontAwesomeIcon
        icon={faUpDown}
        color={this.defaultSortIconColor}
        size="sm"
      />
    );
  };

  onClickCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    action: "single" | "all",
    singleRow?: any
  ) => {
    if (this.props.onClickCheckBox) {
      switch (action) {
        case "single":
          const dataMapped = this.state.data.map((el: any) => {
            if (el.customerId === singleRow?.customerId) {
              return { ...el, checked: event.target.checked };
            }
            return el;
          });
          this.setState({ data: dataMapped });
          if (
            dataMapped.some((d: any) => d.checked === false) &&
            this.state.selectAll
          ) {
            this.setState({ selectAll: false });
          } else if (
            dataMapped.every((d: any) => d.checked === true) &&
            !this.state.selectAll
          ) {
            this.setState({ selectAll: true });
          }
          break;
        case "all":
          this.setState({
            data: this.state.data.map((d: any) => ({
              ...d,
              checked: event.target.checked,
            })),
          });
          this.setState({ selectAll: event.target.checked });
          break;
        default:
          break;
      }
    }
  };

  onClickButton = (action: "view" | "cancel" | "delete" | "back") => {
    switch (action) {
      case "view":
        this.props.history.push("");
        this.props.history.go(0);
        break;
      default:
        break;
    }
  };
  onClickLink = (fieldClicked: string, record: any) => {
    if (this.props.onClickLink) {
      this.props.onClickLink(fieldClicked, record);
    }
  };
  handleDrag = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(this.state.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    this.setState({ data: items });
  };
  render() {
    const { headCellData, totalRecordsCount, onClickCheckBox, fieldsToColor } =
      this.props;
    //console.log("headCellData - from table.tsx", headCellData,"isLoading",this.props.isLoading);
    const { data, rowsPerPage, page, selectAll } = this.state;

    return (
      <Box>
        {headCellData.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              {this.props.isLoading ? (
                <Box
                  style={{
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    margin: "20vh",
                  }}
                >
                  <CircularProgress sx={{ color: "#d52b1e" }} />
                </Box>
              ) : (
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                >
                  <TableHead>
                    <DragDropContext onDragEnd={() => {}}>
                      <Droppable droppableId="Table">
                        {(provided) => (
                          <TableRow
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {onClickCheckBox ? (
                              <TableCell
                                style={{
                                  fontSize: "16px",
                                  borderBottom: "2px solid #0086ff",
                                }}
                              >
                                <Checkbox
                                  inputProps={{ "aria-label": "controlled" }}
                                  size="small"
                                  checked={selectAll}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => this.onClickCheckBox(event, "all")}
                                />
                              </TableCell>
                            ) : null}
                            {headCellData.map((el, index) => {
                              if (el && el.key !== "id") {
                                return (
                                  <Draggable
                                    key={`${index}-head`}
                                    draggableId={el.label}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <TableCell
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        key={`${index}-head`}
                                        style={
                                          el?.key !== "Action"
                                            ? {
                                                fontSize: "16px",
                                                borderBottom:
                                                  "2px solid #0086ff",
                                                whiteSpace: "nowrap",
                                                minWidth: "10em",
                                              }
                                            : {
                                                fontSize: "16px",
                                                borderBottom:
                                                  "2px solid #0086ff",
                                                whiteSpace: "nowrap",
                                              }
                                        }
                                        onClick={() => this.requestSort(el.key)}
                                      >
                                        {_.startCase(el.label)}
                                        {this.positionArrow(el.key)}
                                      </TableCell>
                                    )}
                                  </Draggable>
                                );
                              }
                              return null;
                            })}
                            {provided.placeholder}
                          </TableRow>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </TableHead>
                  <TableBody>
                    {data.length > 0
                      ? data.map((record: any, id: number) => {
                          return (
                            <StyledTableRow key={id}>
                              {onClickCheckBox ? (
                                <TableCell style={{ fontSize: "16px" }}>
                                  <Checkbox
                                    size="small"
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={record.checked || false}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      this.onClickCheckBox(
                                        event,
                                        "single",
                                        record
                                      )
                                    }
                                  />
                                </TableCell>
                              ) : null}
                              {headCellData.map((el) => {
                                if (el?.key !== "id") {
                                  const clrObj = fieldsToColor?.find(
                                    (clr) => clr.key === el?.key
                                  );
                                  return (
                                    <TableCell
                                      key={uuid()}
                                      style={{
                                        fontSize: "16px",
                                        wordWrap: "break-word",
                                        hyphens: "auto",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      {!isNull(el?.linkTo) ? (
                                        <Link
                                          onClick={() => {
                                            this.onClickLink(el.key, record);
                                          }}
                                          to={{ pathname: el.linkTo }}
                                        >
                                          {record[el?.key]}
                                        </Link>
                                      ) : (
                                        <>
                                          <Box
                                            style={{
                                              color: clrObj
                                                ? clrObj.values[record[el?.key]]
                                                : "black",
                                            }}
                                          >
                                            {record[el?.key]}
                                          </Box>
                                          {el.elementToLoad}
                                          {el?.key === "Action" ? (
                                            <Box
                                              style={{
                                                display: "flex",
                                                gap: 2,
                                              }}
                                            >
                                              {this.props.actionArray?.map(
                                                (action, ind) => (
                                                  <IconButton
                                                    key={ind}
                                                    IconType={action}
                                                    onClick={() => {
                                                      if (
                                                        this.props.onActionClick
                                                      ) {
                                                        this.props.onActionClick(
                                                          action,
                                                          record
                                                        );
                                                      }
                                                    }}
                                                  />
                                                )
                                              )}
                                            </Box>
                                          ) : null}
                                        </>
                                      )}
                                    </TableCell>
                                  );
                                }
                                return null;
                              })}
                            </StyledTableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </MaterialTable>
              )}
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRecordsCount}
              page={page}
              showFirstButton
              showLastButton
              labelRowsPerPage={`${
                totalRecordsCount > rowsPerPage
                  ? rowsPerPage
                  : totalRecordsCount
              } of ${totalRecordsCount} records per page`}
              onPageChange={this.handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Box style={{ marginTop: "20vh" }}>
            <NoRecords />
          </Box>
        )}
      </Box>
    );
  }
}

export default withRouter(Table);
