import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { FC } from 'react';
// import GenericAPIService from "./GenericAPIService";
// import GenericAPIService from "./../../Services/GenericAPIService";
import axios from 'axios';
import Enviroment from "./../../config.json";
const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? '';

type T = {
    status: boolean,
    user_name: string
};
// const InValidPassCSS = {
//     textAlign:'center',
//     color:'red',
 
// };
// const fadeOut = {
//     transition: "opacity 1s ease",
//     opacity: 0
// }
function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
// @keyframes fadeIn {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }
// .fade-in-image { animation: fadeIn 5s; }
const Login: FC = () => {
    // const [inValidPass,setInValidPass] = useState("");
    // const [fadeOut,setFadeOut] = useState(false)
    // let history = useHistory();
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const url:string = '/user_forgotpassword';
        const response = await axios.post<T>(baseURL + url, null, {
            params: {
                user_name: data.get('email')
            }
        });
        if (response.data.status === true) {
            window.alert("Please check your mail id for reset password link..!!!")
            // setInValidPass("Please check your mail id for reset password link..!!!");
            // setTimeout(()=>{
            //     setInValidPass("");
            // },1500);

            // window.sessionStorage.setItem('token',response.data.session_token)
            // history.push('/contacts');

        }else{
            // setInValidPass("Incorrect email Id..!!!");
            console.log("Incorrect email Id..!!!!!");
            window.alert("Incorrect email Id..!!!!!")
            // setTimeout(()=>{
            //     setFadeOut(true);
            //     setInValidPass("");
            // },1500);
            // setFadeOut(false);
        }
    };

        return (
            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t: any) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1,width:"100%" }}>

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    // autoFocus
                                />
                                {/* <Fade in = {fadeOut}>
                                    <Typography sx = {{...InValidPassCSS}}>
                                    {inValidPass}
                                    </Typography>
                                    </Fade> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Get Password
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/login" variant="body2">
                                           Back to login
                                        </Link>
                                    </Grid>
                                    {/* <Grid item>
                                        <Link href="#" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid> */}
                                </Grid>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
}

export default Login;