import React, { useState, useEffect,useRef } from 'react';
import './add-new-role.css';
import { useHistory } from 'react-router-dom';
import Enviroment from "./../../config.json";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert.tsx";
const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";
const AddNewRole = () => {
  const history = useHistory();
  const [roleName, setRoleName] = useState("");
  const [selectedReportsTo, setSelectedReportsTo] = useState("");
  const [reportsTo, setReportsTo] = useState([]);
  const [roleDescription, setRoleDescription] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
   const urlGetAllCompanyRoles = baseURL + "/get_company_roles";
    const headers: any = {
    'Content-Type': 'application/json'
  }
  const method: string = 'GET';
  useEffect(() => {
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('userInfo')) {
      const userInfoFromSession = sessionStorage.getItem('userInfo');
      setUserInfo(userInfoFromSession);
    }
  }, []);
  useEffect(() => {
  const fetchRoles = async () => {
    if (userInfo) {
      try {
        const { provider_id } = JSON.parse(userInfo);
        const queryParams = new URLSearchParams({ provider_id });
        const urlWithParams = `${urlGetAllCompanyRoles}?${queryParams}`;
        const response = await fetch(urlWithParams, { method, headers });
        const data = await response.json();
        console.log(data);
        setReportsTo(data);
        setSelectedReportsTo(data.length > 0 ? data[0].role_name : '');
      } catch (error) {
        console.error(error);
      }
  }
  };
  fetchRoles();
}, [userInfo]);


  const saveCompanyRole = async()=> {

    try {
      const reportsToObj = reportsTo.find((role) => role.role_name === selectedReportsTo);
      const postCompanyRole = {
        role_name: roleName,
        role_description: roleDescription,
        reports_to: reportsToObj.company_role_id,
        provider_id: JSON.parse(userInfo).provider_id
      }
      console.log(postCompanyRole)
      const response = await fetch(baseURL + "/create_company_role", {
        method: "POST",
        body: JSON.stringify(postCompanyRole), // Convert to JSON string
        headers
      });

      if (response.ok) {
        const processedEntities = await response.json();

        console.log("Role added successfully:", processedEntities);
        setOpenAlert(true);
        setAlertMsg("Role Added");
        setAlertType("success");
        setTimeout(() => {
          history.push('/settings/roles');
        }, 2000);

      } else {
        console.error("Error Adding Role:", response.status);
        setOpenAlert(true);
        setAlertMsg("Error Adding Role");
        setAlertType("error");
      }

    } catch (error) {
      console.error("Error Adding Role:", error);
      setOpenAlert(true);
        setAlertMsg("Error Adding Role");
        setAlertType("error");
    }
  }
  return (
    <>
    <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            setOpenAlert(false);
          }}
        />
    <div className="add-new-role-main-container">
      <span className="add-new-role-title">Roles</span>
      <span className="add-new-role-subtitle">This page will allow you to define how you share the data among users based on your organization's role hierarchy.
      </span>
      <div className = "add-new-role-input-containers">
        <div className = "add-new-role-element-div">
          <span className ="add-new-role-element-span">Role Name</span>
          <input onChange = {(e)=>(setRoleName(e.target.value))} className = "add-new-role-element-input"/>
        </div>
        <div className = "add-new-role-element-div">
          <span className ="add-new-role-element-span">Reports to</span>
          <select
            value={selectedReportsTo}
            onChange={(e) => setSelectedReportsTo(e.target.value)}
            className="add-new-role-element-input"
          >
            {reportsTo.length > 0 && reportsTo.map((companyRole) => (
              <option key={companyRole.company_role_id} value={companyRole.role_name}>
                {companyRole.role_name}
              </option>
            ))}
          </select>
        </div>
        <div className = "add-new-role-element-div">
          <span className ="add-new-role-element-span">Description</span>
          <textArea onChange = {(e)=>(setRoleDescription(e.target.value))} className = "add-new-role-desc-input"/>
        </div>
      </div>
      <div className = "add-new-role-btn-div">
        <button className = "add-new-role-cancel-btn" onClick = {()=>{history.push('/settings/roles')}}>Cancel</button>
        <button className = "add-new-role-save-btn" onClick = {saveCompanyRole}>Save</button>
      </div>
    </div>
    </>
  );
};

export default AddNewRole;