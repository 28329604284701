import React, { Component } from "react";
import { AlertColor, DialogTitle, FormLabel, Grid } from "@mui/material";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { EmailModel } from "../../Models/EmailModel";
import EmailService from "../../Services/EmailService";
import { ClientModel } from "../../Models/ClientModel";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import RichTextEditor from "../../Components/RichTextEditor/RichTextEditor";
import Button from "@mui/material/Button";
import Enviroment from "./../../config.json";
const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";
const EmailServices = new EmailService();

interface Props {
  clientInfo: ClientModel;
  getAllEmail: () => void;
}

interface State {
  emailInfo: EmailModel;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
}

class FullPageEmailForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emailInfo: {} as EmailModel,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
    };
  }

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      emailInfo: {
        ...this.state.emailInfo,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleChange = (content: string) => {
    this.setState({
      emailInfo: {
        ...this.state.emailInfo,
        message_body: content,
      },
    });
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    if (target.files && target.files.length > 0) {
      this.setState({
        emailInfo: { ...this.state.emailInfo, file: target.files[0] },
      });
    }
  };
  sendEmail = (event: React.FormEvent) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append("file", this.state.emailInfo.file || "");
    const emailData = {
      access_code: sessionStorage.getItem('access_code'), // Assuming this is your access code
      to: this.state.emailInfo.recipient_email, // Replace with the recipient's email address
      subject: this.state.emailInfo.subject,
      body: this.state.emailInfo.message_body
    };
    const accessCode = sessionStorage.getItem('access_code');

    if (accessCode !== null) {
      console.log(accessCode)
      formData.append("access_code", accessCode);
      formData.append("to", this.state.emailInfo.recipient_email );
      formData.append("subject", this.state.emailInfo.subject);
      formData.append("body", this.state.emailInfo.message_body);
      fetch(baseURL + "/send_email", {
      method: 'POST',
      body: formData // Convert the object to JSON
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.message);
        if (data) {
          this.setState({
            openAlert: true,
            alertMsg: "Email Sent Successfully",
            alertType: "success",
          });
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
        this.setState({
        openAlert: true,
        alertMsg: "Email Could not be sent",
        alertType: "error",
      });
      });
    } 
    else
    {
      this.setState({
        openAlert: true,
        alertMsg: "Email Could not be sent",
        alertType: "error",
      });
    }
    

    
  };
  /*onSendEmail = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.emailInfo.file || "");

    const response = await EmailServices.sendEmail(
      Number(this.props.clientInfo.client_id),
      1,
      this.state.emailInfo,
      formData
    );

    if (response) {
      this.setState({
        openAlert: true,
        alertMsg: "Email Sent Successfully",
        alertType: "success",
      });
    }
  };*/

  render() {
    const { alertMsg, alertType, openAlert } = this.state;
    return (
      <>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <div>
          <Grid container sx={{ marginTop: 5 }}>
            <Grid item xs={12}>
              <DialogTitle>Compose Email</DialogTitle>
            </Grid>
          </Grid>
          <form onSubmit={this.sendEmail}>
            <Grid container paddingLeft={5} marginTop={5}>
              <Grid container item>
                <Grid item xs={12}>
                  <FormLabel sx={{ fontSize: "0.9em", color: "black" }}>
                    To:
                  </FormLabel>
                </Grid>
                <Grid item xs={11}>
                  <TextInputField
                    id="recipient_email"
                    value={this.state.emailInfo.recipient_email}
                    onChange={this.onInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container item marginTop={4}>
            <Grid item xs={12}>
              <FormLabel sx={{ fontSize: "0.9em", color: "black" }}>
                Subject :
              </FormLabel>
            </Grid>
            <Grid item xs={11}>
              <TextInputField
                id="subject"
                placeholder="Subject"
                value={this.state.emailInfo.subject}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>
          <Grid container item marginTop={4}>
            <Grid item xs={12}>
              <FormLabel sx={{ fontSize: "0.9em", color: "black" }}>
                Message :
              </FormLabel>
            </Grid>
            <Grid item xs={11}>
              {/* <TextAreaInputField
                minRows={12}
                isRequired={false}
                id="message_body"
                value={this.state.emailInfo.message_body}
                onChange={this.onTextAreaInputChange}
                placeholder="Type Here..."
              /> */}
              <RichTextEditor
                value={this.state.emailInfo.message_body}
                onChange={this.handleChange}
              />
            </Grid>
            </Grid>
              <Grid container item marginTop={8}>
                <input type="file" id="my_file" onChange={this.handleFileChange} />
              </Grid>
              <Grid container item marginTop={4} mb={2}>
                <Button
                  type="submit"
                  style={{ textTransform: "capitalize" }}
                  variant="contained"
                >
                  Send Email
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    );
  }
}

export default FullPageEmailForm;
