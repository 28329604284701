import { URIs } from "../Common/Constants";

import { ReferralModel } from "../Models/ReferralModel";
import GenericAPIService from "./GenericAPIService";

const {
  CREATE_CLIENT_REF,
  UPDATE_CLIENT_REF,
  GET_ALL_REF,
  DELETE_CLIENT_REF,
  GET_CLIENT_REF_BY_ID,
} = URIs;
class ReferralService extends GenericAPIService {
  async getReferralByClientId(id: number,providerId:number) {
    const data: any = await this.getAsync(`${GET_ALL_REF}?client_id=${id}&provider_id=${providerId}`);
    //console.log("data",data)
    return data;
  }
  async getReferralById(id: number,providerId:number) {
    const data: any = await this.getAsync(
      `${GET_CLIENT_REF_BY_ID}?client_ref_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async deleteReferralById(id: number, refId: number,providerId:number) {
    const data: any = await this.getAsync(
      `${DELETE_CLIENT_REF}?client_id=${id}&client_ref_id=${refId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  // async getAssignedSources(formId?: number) {
  //     const { data } = await this.getAsync<{ data: AvailableFormValueModel }>(`${ASSIGNED_SOURCE}/${formId}`);
  //     return data.data;
  // }

  async createReferral(body: ReferralModel) {
    const { data } = await this.postAsync<{ data: any }>(
      CREATE_CLIENT_REF,
      body
    );
    //console.log("data", data);
    return data;
  }
  async UpdateReferral(body: ReferralModel) {
    const { data } = await this.postAsync<{ data: any }>(
      UPDATE_CLIENT_REF,
      body
    );
    //console.log("data", data);
    return data;
  }
}

export default ReferralService;
