import React, { useState, useEffect, useRef } from 'react';
import './administrator-roles.css';
import Switch from 'react-ios-switch';
import Enviroment from "./../../config.json";
import { useParams } from 'react-router-dom';

interface Entity {
  entity_name: string;
  action?: string[];
  isChecked: boolean;
  isListOpen: boolean;
  actionStates: boolean[];
  permitted_role: string;
}
interface RouteParams {
  profileId: string; // Assuming profileName is a string parameter
}

const baseURL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";

const AdministratorRoles: React.FC = () => {
  const { profileId } = useParams<RouteParams>();
  const [switchChecked, setSwitchChecked] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [roleId,setRoleId] = useState(profileId);
  const checkboxTexts = ["View", "Create", "Edit", "Delete"];
  const actionTexts = ["view", "create", "edit", "delete"];
  const [saveChangesBool,setSaveChangesBool] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();
  const listContainerRef = useRef(null);

  const [entities, setEntities] = useState<Entity[]>([]);
  const urlGetAllEntites = baseURL + "/get-entities";
  const headers: HeadersInit = {
    'Content-Type': 'application/json'
  };
  const method = 'GET';
useEffect(() => {
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('userInfo')) {
      const userInfoFromSession = sessionStorage.getItem('userInfo');
      setUserInfo(userInfoFromSession);
    }
  }, []);
useEffect(() => {
   
  const fetchEntities = async () => {
    if(userInfo)
    {
      try {
      const params = new URLSearchParams();
      const { provider_id } = JSON.parse(userInfo);
      params.append('role_id', profileId);
      params.append('provider_id', provider_id);
      const urlWithParams = `${urlGetAllEntites}?${params.toString()}`;
      const response = await fetch(urlWithParams, { method, headers });
      const data: Entity[] = await response.json();
      console.log(data);
      const updatedEntities = data.map(entity => {
        const actionStates = actionTexts.map(actionText =>
          entity.action?.includes(actionText) ?? false // Use optional chaining and nullish coalescing
        );
        console.log(actionStates);
        return {
          entity_name: entity.entity_name,
          isChecked: actionStates.some(state => state),
          permitted_role: entity.permitted_role,
          isListOpen: false,
          actionStates
        };
      });

      setEntities(updatedEntities);
    } catch (error) {
      console.error(error);
    }
  }
  };
  
    fetchEntities();
}, [userInfo]);

// ...


  const toggleEntity = (index: number) => {
    const updatedEntities = entities.map((entity, i) => ({
      ...entity,
      isListOpen: i === index ? !entity.isListOpen : false,
    }));
    setEntities(updatedEntities);
  };

  const toggleEntityPermission = (entityIndex: number, checkboxIndex: number) => {
    const updatedEntities = [...entities];
    updatedEntities[entityIndex].actionStates[checkboxIndex] = !updatedEntities[entityIndex].actionStates[checkboxIndex];

    const allPermissionsChecked = updatedEntities[entityIndex].actionStates.some(state => state);
    updatedEntities[entityIndex].isChecked = allPermissionsChecked;

    setEntities(updatedEntities);
  };

  const handleEntitySwitchChange = (entityIndex: number) => {
    const updatedEntities = [...entities];
    updatedEntities[entityIndex].isChecked = !updatedEntities[entityIndex].isChecked;
    if (updatedEntities[entityIndex].isChecked) {
      updatedEntities[entityIndex].actionStates = [true, true, true, true];
    } else {
      updatedEntities[entityIndex].actionStates = [false, false, false, false];
    }

    setEntities(updatedEntities);
  };
  useEffect(() =>{
    setSaveChangesBool(true);
  },[entities])

  const saveChanges = async () => {
    if(saveChangesBool && userInfo)
    {
      const { provider_id } = JSON.parse(userInfo);
      const postEntities = entities.map(entity => ({
      entity_name: entity.entity_name,
      permitted_role: entity.permitted_role,
      permitted_profile_id: roleId,
      action: entity.actionStates
        .map((actionState, index) => (actionState ? actionTexts[index] : null))
        .filter(action => action !== null),
        provider_id: provider_id
    }));
    console.log(postEntities)
    try {

      const response = await fetch(baseURL + "/update-entities", {
        method: "POST",
        body: JSON.stringify(postEntities), // Convert to JSON string
        headers
      });

      if (response.ok) {
        const processedEntities = await response.json();

        console.log("Entities updated successfully:", processedEntities);

      } else {
        console.error("Error updating entities:", response.status);
      }

    } catch (error) {
      console.error("Error updating entities:", error);
    }
  }
  };

  return (
    <div className="administrator-roles-main-container">
      <div className="administrator-roles-header">
        <div className="administrator-roles-back-title-container">
          <span className="administrator-roles-grey-text">Profile:</span>
          <span className="administrator-roles-black-text">{entities.length>0 && entities[0].permitted_role}</span> 
        </div>
        <button className="administrator-roles-save-changes-btn" onClick ={saveChanges}>Save Changes</button>
      </div>
      <div className="administrator-roles-permissions-container">
        <span className="administrator-roles-title-text">Basic Permissions</span>
        {entities.map((entity, index) => (
          <div key={index} className="administrator-roles-permissions-element">
              <span className="administrator-permissions-roles-element-text">{entity.entity_name
                .split('_')[1] // Get the part after the underscore
                .charAt(0)    // Get the first character
                .toUpperCase() // Convert it to uppercase
                + entity.entity_name.split('_')[1].slice(1) // Get the rest of the string
              }</span>
            <div className="administrator-roles-permissions-switch-list-container">
              <div className="administrator-roles-permissions-element-switch-div">
                <Switch checked={entity.isChecked} onChange={() => handleEntitySwitchChange(index)} />
                <div style={{ cursor: 'pointer' }}>
                  <span className="administrator-roles-permissions-element-text" onClick={() => toggleEntity(index)}>
                    View, Create, Edit, Delete
                  </span>
                </div>
              </div>
              {entity.isListOpen && (
                <div className="administrator-roles-checklist-container">
                  {entity.actionStates.map((isChecked, checkboxIndex) => (
                    <div className="administrator-roles-checklist-element" key={checkboxIndex}>
                      <input
                        type="checkbox"
                        className="administrator-roles-checklist-checkbox"
                        checked={isChecked}
                        onChange={() => toggleEntityPermission(index, checkboxIndex)}
                      />
                      <span className="administrator-roles-checklist-text">
                        {checkboxTexts[checkboxIndex]}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdministratorRoles;
