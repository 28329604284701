import axios from "axios";
import { URIs } from "../Common/Constants";

import GenericAPIService from "./GenericAPIService";

const {
  GETALL_DOCUMENTS,
  GET_DOCUMENTS_NAME,
  DELETE_DOCUMENTS,
  DOWNLOAD_DOCUMENTS,
  ADD_CLIENT_DOCUMENT_NAME,
} = URIs;
class DocumentUploadService extends GenericAPIService {
  async addDocumentsName(docName: string, providerId: number) {
    const data: any = await this.getAsync(
      `${ADD_CLIENT_DOCUMENT_NAME}?document_name=${docName}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getDocumentsName(providerId: number) {
    const data: any = await this.getAsync(
      `${GET_DOCUMENTS_NAME}?provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getAllDocuments(clientId: number, providerId: number) {
    const data: any = await this.getAsync(
      `${GETALL_DOCUMENTS}?client_id=${clientId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async uploadDocuments(
    clientId: number,
    providerId: number,
    documentId: number,
    formData: any
  ) {
    const data = await axios.post(
      `https://connektivitycrm.genxalign.com/api/uploader_clientdocs?client_id=${clientId}&provider_id=${providerId}&document_id=${documentId}`,
      formData
    );
    return data;
  }

  async deleteDocuments(documentId: number, clientId: any, providerId: number) {
    const data: any = await this.getAsync(
      `${DELETE_DOCUMENTS}?client_document_id=${documentId}&client_id=${clientId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async downloadDocuments(filePath: string, fileName: string) {
    const data: any = await this.getAsync(
      `${DOWNLOAD_DOCUMENTS}?file_path=${filePath}&file_name=${fileName}`
    );
    //console.log("data",data)
    return data;
  }
}

export default DocumentUploadService;
