import { URIs } from "../Common/Constants";

import { RosterModel } from "../Models/RosterModel";
import GenericAPIService from "./GenericAPIService";

const {
  CREATE_ROSTER,
  GET_ROSTER_BY_CLIENT,
  GETALL_ROSTER,
  UPDATE_ROSTER,
  DELETE_ROSTER,
  GET_STAFFROLE,
  GET_ROSTER_BY_ID,
} = URIs;
class StaffService extends GenericAPIService {
  async getAllRoster() {
    const data: any = await this.getAsync(GETALL_ROSTER);
    //console.log("data",data)
    return data;
  }
  async getAllStaffsRole() {
    const data: any = await this.getAsync(GET_STAFFROLE);
    //console.log("data",data)
    return data;
  }

  async getRosterByClient(id: number,providerId: number) {
    const data: any = await this.getAsync(
      `${GET_ROSTER_BY_CLIENT}?client_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async deleteRoster(id: number,providerId: number) {
    const data: any = await this.getAsync(`${DELETE_ROSTER}?roster_id=${id}&provider_id=${providerId}`);
    //console.log("data",data)
    return data;
  }
  async getRosterById(id: number,providerId: number) {
    const data: any = await this.getAsync(
      `${GET_ROSTER_BY_ID}?roster_id=${id}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  async createRoster(body: RosterModel) {
    const { data } = await this.postAsync<{ data: any }>(CREATE_ROSTER, body);
    //console.log("data", data);
    return data;
  }
  async updateRoster(body: RosterModel) {
    const { data } = await this.postAsync<{ data: any }>(UPDATE_ROSTER, body);
    //console.log("data", data);
    return data;
  }
}

export default StaffService;
