import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import SelectModel from "./SelectModel";

interface Props {
  id?: string;
  label?: string;
  value?: string | number;
  style?: React.CSSProperties;
  size?: "small" | "medium";
  values?: SelectModel[];
  defaultValue?: string;
  placeholder?: string;
  AllValue?: boolean;
  name?: string;
  noneValue?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange: (selected: SelectModel, targetId?: string) => void;
  customOptions?: string;
  displayEmpty?: boolean;
}
interface State {}
class SingleSelect extends React.Component<Props, State> {
  render() {
    const {
      id,
      AllValue,
      label,
      noneValue,
      value,
      defaultValue,
      values,
      size,
      placeholder,
      isDisabled,
      isRequired,
      customOptions,
      displayEmpty,
    } = this.props;
    // console.log(value);
    const labelId = id ? id : label;
    return (
      <FormControl
        size={size ?? "small"}
        style={{ alignContent: "center", justifyContent: "center" }}
        variant="outlined"
        fullWidth
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          required={isRequired}
          disabled={isDisabled}
          labelId={labelId + "label"}
          id={labelId}
          value={value}
          onChange={this.handleChange}
          label={label}
          defaultValue={defaultValue}
          MenuProps={{
            PaperProps: {
              style: { overflowY: "scroll", maxHeight: "20em" },
            },
          }}
          placeholder={placeholder}
          displayEmpty={true}
          renderValue={(value) => {
            if (!value || value === "None") {
              return <>{customOptions || "Select Value"}</>;
            }
            return value;
          }}
        >
          <MenuItem disabled value={""}>
            <>{customOptions || "Select Value"}</>
          </MenuItem>
          {AllValue ? (
            <MenuItem key={"ALL"} value={"ALL"}>
              ALL
            </MenuItem>
          ) : null}

          {values?.map((v) => {
            return (
              <MenuItem key={v.text} value={v.value}>
                {v.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  handleChange = (
    event: SelectChangeEvent<string | number>,
    child: ReactNode
  ) => {
    const { id, values, noneValue, AllValue } = this.props;
    const value = event.target.value as string;

    const selectedValue = values?.find((p) => p.value === value);

    if (selectedValue) {
      this.props.onChange(selectedValue, id);
    } else if (noneValue) {
      this.props.onChange({ text: "None", value: "None" }, id);
    } else if (AllValue) {
      this.props.onChange({ text: "ALL", value: "ALL" }, id);
    }
  };
}

export default SingleSelect;
