import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";

import { EventType } from "../../Common/Enums";
import BlueButton from "../../Components/Button/BlueButton";
import SingleCheckBox from "../../Components/CheckBox/CheckBox";
import DatePicker from "../../Components/DatePicker/DatePicker";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextInputField from "../../Components/TextInputField/TextInputField";
import TimePickerComponent from "../../Components/TimePicker/TimePicker";
import { ServiceAgreementModel } from "../../Models/ServiceAgreementModel";
import AgreementService from "../../Services/AgreementService";

const agreement = new AgreementService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  isAdd: boolean;
  registrationGroup: SelectModel[];
  supportItemData: SelectModel[];
  clientAgreement: ServiceAgreementModel[];
  clientId: number;
  weekdayFlag: boolean;
  totalHours: number;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isData: boolean;
  userInfo: any;
}

class ServiceAgreement extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAdd: false,
      registrationGroup: [],
      clientId: 0,
      supportItemData: [],
      totalHours: 0,
      clientAgreement: [
        {
          client_id: 0,
          support_item_name: "",
          support_item_no: 0,
          registration_group_no: 0,
          registration_group_name: "",
          support_category_name: "",
          support_category_no: 0,
          rate_per_hour: 0,
          status: "",
          start_date: "",
          start_time: "",
          end_date: "",
          end_time: "",
          no_of_days_per_week: 0,
          no_of_days_per_year: 0,
          mon_flag: 0,
          Tue_flag: 0,
          wed_flag: 0,
          thrus_flag: 0,
          fri_flag: 0,
          sat_flag: 0,
          sun_flag: 0,
          holiday_flag: 0,
          weekday_flg: 0,
          total_price_yearly: 0,
          no_of_hrs_per_day: 0,
          mon_hrs: 0,
          Tue_hrs: 0,
          wed_hrs: 0,
          thrus_hrs: 0,
          fri_hrs: 0,
          sat_hrs: 0,
          sun_hrs: 0,
          ph_hrs: 0,
          provider_id: 0,
        },
      ],
      weekdayFlag: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isData: false,
      userInfo: {},
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var number = parseInt(match[1]);
        this.setState({
          clientId: number,
        });
        const agreementData = await agreement.getServiceAgreement(
          number,
          JSON.parse(userInfo).provider_id
        );

        if (agreementData.data.length > 0) {
          this.setState({
            clientAgreement: agreementData.data,
            isAdd: true,
            isData: true,
            userInfo: JSON.parse(userInfo),
          });
        }

        // const searchParams = new URLSearchParams(this.props.location.search);
        // if (searchParams.get("client_id")) {
        //   this.setState({
        //     clientId: Number(searchParams.get("client_id")),
        //   });
        //   const agreementData = await agreement.getServiceAgreement(
        //     Number(searchParams.get("client_id"))
        //   );
        //   if (agreementData.data.length > 0) {
        //     this.setState({
        //       clientAgreement: agreementData.data,
        //       isAdd: true,
        //       isData: true,
        //     });
        //   }
        //   console.log(agreementData);
        // }

        const response = await agreement.getAllRegistrationGroup();
        this.setState({
          registrationGroup: response.data.map((el: any) => {
            return {
              id: el.registration_group_no,
              text: el.registration_group_name,
              value: el.registration_group_name,
            };
          }),
          userInfo: JSON.parse(userInfo),
        });
      }
    }
  }
  onDropDownChange = async (
    selected: SelectModel,
    targetId?: string,
    index?: number
  ) => {
    const { clientAgreement: agreementField, userInfo } = this.state;

    if (targetId === "registration_group_name") {
      this.setState({
        clientAgreement: agreementField.map((el, ind) => {
          if (ind === index) {
            return {
              ...el,
              client_id: this.state.clientId,
              registration_group_name: selected.value,
              registration_group_no: selected.id,
              provider_id: userInfo.provider_id,
            };
          }
          return el;
        }),
      });
      const response = await agreement.getSupportItem(
        selected.value,
        userInfo.provider_id
      );
      this.setState({
        supportItemData: response.data.map((el: any) => {
          return {
            id: el.support_item_no,
            text: el.support_item_name,
            value: el.support_item_name,
          };
        }),
      });
    }
    if (targetId === "support_item_name") {
      //console.log(selected);
      this.setState({
        clientAgreement: agreementField.map((el, ind) => {
          if (ind === index) {
            return {
              ...el,
              support_item_name: selected.value,
              support_item_no: selected.id,
            };
          }
          return el;
        }),
      });
      if (index?.toString().length) {
        const response = await agreement.getPriceDetail(
          this.state.clientAgreement[index].registration_group_name,
          selected.value
        );
        this.setState({
          clientAgreement: agreementField.map((el, ind) => {
            if (ind === index) {
              return {
                ...el,
                support_item_name: response.data.support_item_name,
                support_item_no: response.data.support_item_no,
                rate_per_hour: response.data.rate_per_hour,
                support_category_name: response.data.support_category_name,
                support_category_no: response.data.support_category_no,
                holiday_flag: response.data.holiday_flg ? 1 : 0,
                sat_flag: response.data.saturday_flg ? 1 : 0,
                sun_flag: response.data.sunday_flg ? 1 : 0,
                weekday_flg: response.data.weekday_flg ? 1 : 0,
                mon_hrs: 0,
                Tue_hrs: 0,
                wed_hrs: 0,
                thrus_hrs: 0,
                fri_hrs: 0,
                sat_hrs: 0,
                sun_hrs: 0,
                no_of_days_per_week: 0,
                no_of_days_per_year: 0,
                total_price_yearly: 0,
                mon_flag: 0,
                Tue_flag: 0,
                wed_flag: 0,
                thrus_flag: 0,
                fri_flag: 0,
              };
            }
            return el;
          }),
        });
      }
      // console.log("response", response.data.support_category_name);
    }
  };
  onCheckSelect = (
    selected: any,
    eventType: EventType,
    targetId?: string,
    index?: number
  ) => {
    const { clientAgreement: agreementField } = this.state;
    let flag = eventType;

    //console.log(selected, targetId);
    if (targetId) {
      this.setState({
        clientAgreement: agreementField.map((el, ind) => {
          if (ind === index) {
            return {
              ...el,
              [targetId]: flag,
            };
          }
          return el;
        }),
      });
    }
  };

  onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { clientAgreement: agreementField } = this.state;
    this.setState({
      clientAgreement: agreementField.map((el, ind) => {
        if (ind === index) {
          if (
            Number(event.target.value) >= 0 &&
            Number(event.target.value) <= 24
          ) {
            const daysOfWeek = [
              "mon_hrs",
              "Tue_hrs",
              "wed_hrs",
              "thrus_hrs",
              "fri_hrs",
              "sat_hrs",
              "sun_hrs",
            ];
            let totalHoursData = 0;
            daysOfWeek.forEach((day) => {
              if (event.target.id.includes(day)) {
                totalHoursData += Number(event.target.value);
              }
            });
            let hoursData =
              Number(el.mon_hrs) +
              Number(el.Tue_hrs) +
              Number(el.wed_hrs) +
              Number(el.thrus_hrs) +
              Number(el.fri_hrs) +
              Number(el.sat_hrs) +
              Number(el.sun_hrs) +
              totalHoursData;
            this.setState({
              totalHours: hoursData,
            });

            const newObj = {
              mon: el.mon_flag,
              tue: el.Tue_flag,
              wed: el.wed_flag,
              thurs: el.thrus_flag,
              fri: el.fri_flag,
              sat: el.sat_flag,
              sun: el.sun_flag,
            };
            const dayCount = Object.values(newObj).filter((el) => el === 1);

            return {
              ...el,
              [event.target.id]: Number(event.target.value),
              no_of_days_per_week: dayCount.length,
              no_of_days_per_year: Number(dayCount.length * 52),
              total_price_yearly:
                dayCount.length * 52 * hoursData * el.rate_per_hour,
            };
          }
        }
        return el;
      }),
    });
  };
  handleAddMoreClick = () => {
    const { clientAgreement: agreementField, userInfo } = this.state;
    this.setState({
      clientAgreement: agreementField.concat({
        client_id: 0,
        support_item_name: "",
        support_item_no: 0,
        registration_group_no: 0,
        registration_group_name: "",
        support_category_name: "",
        support_category_no: 0,
        rate_per_hour: 0,
        status: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        no_of_days_per_week: 0,
        no_of_days_per_year: 0,
        mon_flag: 0,
        Tue_flag: 0,
        wed_flag: 0,
        thrus_flag: 0,
        fri_flag: 0,
        sat_flag: 0,
        sun_flag: 0,
        holiday_flag: 0,
        weekday_flg: 0,
        total_price_yearly: 0,
        no_of_hrs_per_day: 0,
        mon_hrs: 0,
        Tue_hrs: 0,
        wed_hrs: 0,
        thrus_hrs: 0,
        fri_hrs: 0,
        sat_hrs: 0,
        sun_hrs: 0,
        ph_hrs: 0,
        provider_id: userInfo.provider_id,
      }),
    });
  };
  handleRemoveClick = (index: number) => {
    const { clientAgreement: agreementField } = this.state;
    const removefilteredFields = agreementField.filter(
      (el, indx) => indx !== index
    );
    this.setState({
      clientAgreement: removefilteredFields,
    });
  };

  onStartDateChange = (value: Date | null, index: number) => {
    const { clientAgreement: agreementField } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        clientAgreement: agreementField.map((el, ind) => {
          if (ind === index) {
            return {
              ...el,
              start_date: value.toLocaleDateString("en-CA"),
            };
          }
          return el;
        }),
      });
    }
  };

  onStartTimeChange = (value: any | null, index: number) => {
    const { clientAgreement: agreementField } = this.state;
    //console.log(value?.toLocaleTimeString());
    if (value) {
      this.setState({
        clientAgreement: agreementField.map((el, ind) => {
          if (ind === index) {
            return {
              ...el,
              start_time: new Date(value).toLocaleString(),
            };
          }
          return el;
        }),
      });
    }
  };

  onEndDateChange = (value: Date | null, index: number) => {
    const { clientAgreement: agreementField } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        clientAgreement: agreementField.map((el, ind) => {
          if (ind === index) {
            return {
              ...el,
              end_date: value.toLocaleDateString("en-CA"),
              status: "created",
            };
          }
          return el;
        }),
      });
    }
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
  };

  onEndTimeChange = (value: any | null, index: number) => {
    const { clientAgreement: agreementField } = this.state;
    //console.log(value?.toLocaleTimeString());
    if (value) {
      this.setState({
        clientAgreement: agreementField.map((el, ind) => {
          if (ind === index) {
            return {
              ...el,
              end_time: new Date(value).toLocaleString(),
            };
          }
          return el;
        }),
      });
    }
  };

  onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await agreement.createAgreement(
        this.state.clientAgreement,
        this.state.clientId,
        this.state.userInfo.provider_id
      );
      if (response) {
        this.setState({
          openAlert: true,
          alertMsg: "Agreement Created Successfully",
          alertType: "success",
        });
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  handleCancelClick = (event: React.FormEvent) => {
    event.preventDefault();
    this.setState({
      clientAgreement: [
        {
          client_id: 0,
          support_item_name: "",
          support_item_no: 0,
          registration_group_no: 0,
          registration_group_name: "",
          support_category_name: "",
          support_category_no: 0,
          rate_per_hour: 0,
          status: "",
          start_date: "",
          start_time: "",
          end_date: "",
          end_time: "",
          no_of_days_per_week: 0,
          no_of_days_per_year: 0,
          mon_flag: 0,
          Tue_flag: 0,
          wed_flag: 0,
          thrus_flag: 0,
          fri_flag: 0,
          sat_flag: 0,
          sun_flag: 0,
          holiday_flag: 0,
          weekday_flg: 0,
          total_price_yearly: 0,
          no_of_hrs_per_day: 0,
          mon_hrs: 0,
          Tue_hrs: 0,
          wed_hrs: 0,
          thrus_hrs: 0,
          fri_hrs: 0,
          sat_hrs: 0,
          sun_hrs: 0,
          ph_hrs: 0,
          provider_id: this.state.userInfo.provider_id,
        },
      ],
    });
  };
  render() {
    const {
      isAdd,
      registrationGroup,
      clientAgreement: agreementFields,
      supportItemData,
      openAlert,
      alertMsg,
      alertType,
      isData,
    } = this.state;
    //console.log(agreementFields);
    return (
      <Grid container>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container>
              <Grid item xs={9}>
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  Service Agreement
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                {isAdd ? (
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={() => {
                      this.setState({ isAdd: true });
                    }}
                  >
                    - Schedule of supports
                  </Button>
                ) : (
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={() => {
                      this.setState({ isAdd: true });
                    }}
                  >
                    + Schedule of supports
                  </Button>
                )}
              </Grid>
            </Grid>
            <hr />

            {isAdd ? (
              <form onSubmit={this.onSubmit}>
                {this.state.clientAgreement.map((el, index) => {
                  return (
                    <Box key={index}>
                      <Grid
                        container
                        item
                        flexDirection={"column"}
                        marginTop={3}
                        key={index}
                      >
                        <Grid container item rowSpacing={3}>
                          <Grid container item columnSpacing={7}>
                            <Grid item xs={4}>
                              <FormLabel sx={{ fontSize: "0.9em" }}>
                                Registration Group Name
                              </FormLabel>
                              <SingleSelect
                                isDisabled={isData}
                                value={el.registration_group_name}
                                id="registration_group_name"
                                size="small"
                                values={registrationGroup}
                                onChange={(
                                  selected: SelectModel,
                                  targetId?: string | undefined
                                ) =>
                                  this.onDropDownChange(
                                    selected,
                                    targetId,
                                    index
                                  )
                                }
                              />
                            </Grid>
                            {el.registration_group_name ? (
                              <Grid item xs={4}>
                                <FormLabel sx={{ fontSize: "0.9em" }}>
                                  Support Item Name
                                </FormLabel>
                                <SingleSelect
                                  isDisabled={isData}
                                  value={el.support_item_name}
                                  id="support_item_name"
                                  size="small"
                                  values={supportItemData}
                                  onChange={(
                                    selected: SelectModel,
                                    targetId?: string | undefined
                                  ) =>
                                    this.onDropDownChange(
                                      selected,
                                      targetId,
                                      index
                                    )
                                  }
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {el.support_category_name ? (
                            <>
                              <Grid container item columnSpacing={7}>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    Type of Support
                                  </FormLabel>
                                  <Typography>
                                    {el.support_item_name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    Registration Group
                                  </FormLabel>
                                  <Typography>
                                    {el.registration_group_no}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    Support Item No.
                                  </FormLabel>
                                  <Typography>{el.support_item_no}</Typography>
                                </Grid>
                              </Grid>
                              <Grid container item columnSpacing={7}>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    Support Category Name
                                  </FormLabel>
                                  <Typography>
                                    {el.support_category_name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    Price Rate/hr
                                  </FormLabel>
                                  <Typography>{el.rate_per_hour}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    Support Category No.
                                  </FormLabel>
                                  <Typography>
                                    {el.support_category_no}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container item columnSpacing={7}>
                                <Grid item xs={2}>
                                  <Grid>
                                    <FormLabel sx={{ fontSize: "0.9em" }}>
                                      Service Start Date
                                    </FormLabel>
                                  </Grid>
                                  <Grid>
                                    <DatePicker
                                      disabled={isData}
                                      value={new Date(el.start_date)}
                                      onChange={(value) =>
                                        this.onStartDateChange(value, index)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid>
                                    <FormLabel sx={{ fontSize: "0.9em" }}>
                                      Service Start Time
                                    </FormLabel>
                                  </Grid>
                                  <Grid>
                                    <TimePickerComponent
                                      disabled={isData}
                                      value={new Date(el.start_time)}
                                      onChange={(value) =>
                                        this.onStartTimeChange(value, index)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid>
                                    <FormLabel sx={{ fontSize: "0.9em" }}>
                                      Service End Date
                                    </FormLabel>
                                  </Grid>
                                  <Grid>
                                    <DatePicker
                                      disabled={isData}
                                      value={new Date(el.end_date)}
                                      onChange={(value) =>
                                        this.onEndDateChange(value, index)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid>
                                    <FormLabel sx={{ fontSize: "0.9em" }}>
                                      Service End Time
                                    </FormLabel>
                                  </Grid>
                                  <Grid>
                                    <TimePickerComponent
                                      disabled={isData}
                                      value={new Date(el.end_time)}
                                      onChange={(value) =>
                                        this.onEndTimeChange(value, index)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          {el.weekday_flg ? (
                            <>
                              <Grid container item columnSpacing={7}>
                                <Grid item container xs={4}>
                                  <Grid item xs={4}>
                                    <SingleCheckBox
                                      disabled={isData}
                                      id="mon_flag"
                                      label="Monday"
                                      isChecked={
                                        el.mon_flag === 1 ? true : false
                                      }
                                      value={el.mon_flag}
                                      onChange={(
                                        selected: any,
                                        eventType: EventType,
                                        targetId?: string
                                      ) =>
                                        this.onCheckSelect(
                                          selected,
                                          eventType,
                                          targetId,
                                          index
                                        )
                                      }
                                    />
                                  </Grid>

                                  {el.mon_flag ? (
                                    <Grid item xs={4}>
                                      <TextInputField
                                        isDisabled={isData}
                                        type="number"
                                        id="mon_hrs"
                                        value={el.mon_hrs}
                                        onChange={(event: any) =>
                                          this.onInputChange(event, index)
                                        }
                                        placeholder="Hours"
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                                <Grid item container xs={4}>
                                  <Grid item xs={4}>
                                    <SingleCheckBox
                                      disabled={isData}
                                      id="Tue_flag"
                                      label="Tuesday"
                                      isChecked={
                                        el.Tue_flag === 1 ? true : false
                                      }
                                      value={el.Tue_flag}
                                      onChange={(
                                        selected: any,
                                        eventType: EventType,
                                        targetId?: string
                                      ) =>
                                        this.onCheckSelect(
                                          selected,
                                          eventType,
                                          targetId,
                                          index
                                        )
                                      }
                                    />
                                  </Grid>

                                  {el.Tue_flag ? (
                                    <Grid item xs={4}>
                                      <TextInputField
                                        isDisabled={isData}
                                        type="number"
                                        id="Tue_hrs"
                                        value={el.Tue_hrs}
                                        onChange={(event: any) =>
                                          this.onInputChange(event, index)
                                        }
                                        placeholder="Hours"
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                                <Grid item container xs={4}>
                                  <Grid item xs={5}>
                                    <SingleCheckBox
                                      disabled={isData}
                                      id="wed_flag"
                                      label="Wednesday"
                                      isChecked={
                                        el.wed_flag === 1 ? true : false
                                      }
                                      value={el.wed_flag}
                                      onChange={(
                                        selected: any,
                                        eventType: EventType,
                                        targetId?: string
                                      ) =>
                                        this.onCheckSelect(
                                          selected,
                                          eventType,
                                          targetId,
                                          index
                                        )
                                      }
                                    />
                                  </Grid>

                                  {el.wed_flag ? (
                                    <Grid item xs={4}>
                                      <TextInputField
                                        isDisabled={isData}
                                        type="number"
                                        id="wed_hrs"
                                        value={el.wed_hrs}
                                        onChange={(event: any) =>
                                          this.onInputChange(event, index)
                                        }
                                        placeholder="Hours"
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container item columnSpacing={7}>
                                <Grid item container xs={4}>
                                  <Grid item xs={4}>
                                    <SingleCheckBox
                                      disabled={isData}
                                      id="thrus_flag"
                                      label="Thursday"
                                      isChecked={
                                        el.thrus_flag === 1 ? true : false
                                      }
                                      value={el.thrus_flag}
                                      onChange={(
                                        selected: any,
                                        eventType: EventType,
                                        targetId?: string
                                      ) =>
                                        this.onCheckSelect(
                                          selected,
                                          eventType,
                                          targetId,
                                          index
                                        )
                                      }
                                    />
                                  </Grid>

                                  {el.thrus_flag ? (
                                    <Grid item xs={4}>
                                      <TextInputField
                                        isDisabled={isData}
                                        type="number"
                                        id="thrus_hrs"
                                        value={el.thrus_hrs}
                                        onChange={(event: any) =>
                                          this.onInputChange(event, index)
                                        }
                                        placeholder="Hours"
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                                <Grid item container xs={4}>
                                  <Grid item xs={4}>
                                    <SingleCheckBox
                                      disabled={isData}
                                      id="fri_flag"
                                      label="Friday"
                                      isChecked={
                                        el.fri_flag === 1 ? true : false
                                      }
                                      value={el.fri_flag}
                                      onChange={(
                                        selected: any,
                                        eventType: EventType,
                                        targetId?: string
                                      ) =>
                                        this.onCheckSelect(
                                          selected,
                                          eventType,
                                          targetId,
                                          index
                                        )
                                      }
                                    />
                                  </Grid>
                                  {el.fri_flag ? (
                                    <Grid item xs={4}>
                                      <TextInputField
                                        isDisabled={isData}
                                        type="number"
                                        id="fri_hrs"
                                        value={el.fri_hrs}
                                        onChange={(event: any) =>
                                          this.onInputChange(event, index)
                                        }
                                        placeholder="Hours"
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          {el.sat_flag ? (
                            <>
                              <Grid container item columnSpacing={7}>
                                <Grid item container xs={4}>
                                  <Grid item xs={4}>
                                    <SingleCheckBox
                                      disabled={isData}
                                      id="sat_flag"
                                      label="Saturday"
                                      isChecked={true}
                                      value={el.sat_flag}
                                      onChange={(
                                        selected: any,
                                        eventType: EventType,
                                        targetId?: string
                                      ) =>
                                        this.onCheckSelect(
                                          selected,
                                          eventType,
                                          targetId,
                                          index
                                        )
                                      }
                                    />
                                  </Grid>
                                  {el.sat_flag ? (
                                    <Grid item xs={5}>
                                      <TextInputField
                                        isDisabled={isData}
                                        type="number"
                                        id="sat_hrs"
                                        value={el.sat_hrs}
                                        onChange={(event: any) =>
                                          this.onInputChange(event, index)
                                        }
                                        placeholder="Hours"
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          {el.sun_flag ? (
                            <>
                              <Grid container item columnSpacing={7}>
                                <Grid item container xs={4}>
                                  <Grid item xs={4}>
                                    <SingleCheckBox
                                      disabled={isData}
                                      id="sun_flag"
                                      label="Sunday"
                                      isChecked={true}
                                      value={el.sun_flag}
                                      onChange={(
                                        selected: any,
                                        eventType: EventType,
                                        targetId?: string
                                      ) =>
                                        this.onCheckSelect(
                                          selected,
                                          eventType,
                                          targetId,
                                          index
                                        )
                                      }
                                    />
                                  </Grid>
                                  {el.sun_flag ? (
                                    <Grid item xs={5}>
                                      <TextInputField
                                        isDisabled={isData}
                                        type="number"
                                        id="sun_hrs"
                                        value={el.sun_hrs}
                                        onChange={(event: any) =>
                                          this.onInputChange(event, index)
                                        }
                                        placeholder="Hours"
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          {el.total_price_yearly ? (
                            <>
                              <Grid container item columnSpacing={7}>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    No. Of Days/Week
                                  </FormLabel>
                                  <Typography>
                                    {el.no_of_days_per_week}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    No. Of Days/Year
                                  </FormLabel>
                                  <Typography>
                                    {el.no_of_days_per_year}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel sx={{ fontSize: "0.9em" }}>
                                    Total Price/Year
                                  </FormLabel>
                                  <Typography>
                                    {el.total_price_yearly.toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>

                        <Grid
                          container
                          item
                          marginTop={5}
                          justifyContent="flex-end"
                        >
                          <Grid item marginRight={2}>
                            {agreementFields.length > 1 && !isData ? (
                              <Button
                                variant="outlined"
                                style={{
                                  cursor: "pointer",
                                  textTransform: "capitalize",
                                }}
                                key={index}
                                onClick={() => this.handleRemoveClick(index)}
                              >
                                Remove
                              </Button>
                            ) : null}
                          </Grid>
                          <Grid item>
                            {agreementFields.length - 1 === index && !isData ? (
                              <Button
                                variant="contained"
                                style={{
                                  cursor: "pointer",
                                  textTransform: "capitalize",
                                }}
                                onClick={this.handleAddMoreClick}
                              >
                                + Add More
                              </Button>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                {isData ? (
                  <Grid container marginTop={5} item justifyContent="flex-end">
                    <BlueButton
                      onClick={() => {
                        this.setState({ isData: false });
                      }}
                      label="Edit"
                    />
                  </Grid>
                ) : (
                  <Grid container marginTop={5} item justifyContent="flex-end">
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={this.handleCancelClick}
                      style={{ textTransform: "capitalize", marginLeft: 8 }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </form>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withRouter(ServiceAgreement);
