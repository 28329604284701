import React from "react";
import { Grid, Button, Typography } from "@mui/material";

import ModalDialog from "../Modal/ModelDialog";
import WhiteButton from "./WhiteButton";

type ButtonType = 'None' | 'Dialog';

interface Props {
    id?: string;
    label: string;
    disabled: boolean;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    startIcon?: React.ReactNode;
    buttonType: ButtonType;
    dialogMessage?: string;
}
interface State {
    isDialogOpen: boolean;
}

class RedButton extends React.Component<Props, State> {
    public static defaultProps = {
        buttonType: 'None',
        disabled: false,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isDialogOpen: false
        }
    }
    render() {
        const { id, label, disabled, onClick, startIcon, buttonType, dialogMessage } = this.props;
        const { isDialogOpen } = this.state;

        const style: React.CSSProperties = {textTransform: 'none'};

        if(!disabled) {
            style.backgroundColor = '#EE3A29';
            style.color = '#FFFFFF';
        }

        switch(buttonType) {
            case 'None': 
            return (
                <Button disabled={disabled} id={id} startIcon={startIcon} size='medium' variant="outlined"
                style={style} onClick={onClick}>{label}</Button>
            );
            case 'Dialog':
                return (
                    <>
                    <ModalDialog title={'Alert'} dialogWidth="sm"
                    isOpen={isDialogOpen} onClose={this.handleCancel}
                    dialogAction={
                        <Grid container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{marginRight: 20}}>
                            <Grid item >
                                <WhiteButton label="No" onClick={this.handleCancel}/>
                            </Grid>
                            <Grid item >
                                <RedButton label="Yes" onClick={this.onDialogButtonClick}/>
                            </Grid>
                        </Grid>
                    }>
                        <Typography style={{fontSize: 16, fontWeight: 400, color: '#383838'}}>
                            {dialogMessage}</Typography>
                    </ModalDialog>
                    <Button disabled={disabled} id={id} startIcon={startIcon} size="medium" variant="outlined" style={style}
                    onClick={this.handleDialogOpen}>{label}</Button>
                    </>
                );
                default:
                    return <></>;

        }
    }

    onDialogButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.handleCancel();

        if(this.props.onClick) {
            this.props.onClick(event);
        }
    }

    handleDialogOpen = () => {
        this.setState({ isDialogOpen: true});
    }

    handleCancel = () => {
        this.setState({ isDialogOpen: false});
    }
}

export default RedButton;