import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableContainer,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import { UploadDocumentModel } from "../../Models/DocumentUploadModel";
import {
  AddStaffTrainingNameModel,
  StaffTrainingDocumentModel,
  StaffTrainingModel,
} from "../../Models/StaffTrainingModel";
import StaffService from "../../Services/StaffService";

import DatePicker from "../../Components/DatePicker/DatePicker";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import TextInputField from "../../Components/TextInputField/TextInputField";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import {
  faCaretDown,
  faCircleInfo,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const StaffServices = new StaffService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  staffId: number;
  DocumentInfo: UploadDocumentModel;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  fileName: string;
  documentsName: StaffTrainingDocumentModel[];
  uploadedFile: any;
  filesName: any;
  staffDocId: any;
  docStatus: any;
  uploadedDate: any;
  docNameObj: any;
  docPathObj: any;
  isTrainingOpen: boolean;
  issueDateObj: any;
  expiryDateObj: any;
  trainingInfo: StaffTrainingModel[];
  isOpen: boolean;
  provider: string;
  userInfo: any;
  isAddTraining: boolean;
  addTraining: AddStaffTrainingNameModel;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#FFFFFF",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    maxWidth: "none",
    cursor: "pointer",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 20,
  },
}));

class StaffTraining extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      staffId: 0,
      DocumentInfo: {} as UploadDocumentModel,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      fileName: "",
      documentsName: [],
      uploadedFile: {},
      filesName: {},
      staffDocId: {},
      docStatus: {},
      uploadedDate: {},
      docNameObj: {},
      docPathObj: {},
      isTrainingOpen: false,
      issueDateObj: {},
      expiryDateObj: {},
      trainingInfo: [],
      isOpen: false,
      provider: "",
      userInfo: {},
      isAddTraining: false,
      addTraining: {} as AddStaffTrainingNameModel,
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var staffId = parseInt(match[1]);
        this.getAllTrainings(staffId, JSON.parse(userInfo).provider_id);
        this.setState({
          userInfo: JSON.parse(userInfo),
          addTraining: {
            ...this.state.addTraining,
            provider_id: JSON.parse(userInfo).provider_id,
            staff_id: staffId,
          },
        });
      }
    }
  }
  getAllTrainings = async (staffId: number, providerId: number) => {
    const documentName = await StaffServices.getTrainingDocumentsName(
      staffId,
      providerId
    );
    const newData: StaffTrainingModel[] = documentName.data.map(
      (el: StaffTrainingDocumentModel) => {
        return {
          staff_id: staffId,
          provider_id: providerId,
          training_name: el.training_name,
          training_code: el.training_code,
          training_id: el.staff_traininglist_id,
          timeframe: el.timeframe,
          status: "",
          date_training_attended: "",
          training_provider: el.training_provider,
          soft_copy_attendance: "",
          training_cost: 0,
          is_mandatory: el.is_madatory,
          included_in_pip: "",
          follow_up_notes: "",
        };
      }
    );
    const getTrainingData = await StaffServices.getTraining(
      staffId,
      providerId
    );

    if (getTrainingData.data.length > 0) {
      const newArray = newData.filter(
        (item) =>
          !getTrainingData.data.some(
            (obj: any) => obj.training_name === item.training_name
          )
      );

      const mergedArray = [...newArray, ...getTrainingData.data];
      this.setState({
        documentsName: documentName.data,
        staffId,
        trainingInfo: mergedArray,
      });
    } else {
      this.setState({
        documentsName: documentName.data,
        staffId,
        trainingInfo: newData,
      });
    }
  };
  onTrainingDocOpen = () => {
    this.setState({ isTrainingOpen: !this.state.isTrainingOpen });
  };
  onDropDownChange = async (
    selected: SelectModel,
    targetId?: string,
    index?: number
  ) => {
    const { trainingInfo: trainingField } = this.state;

    if (targetId) {
      this.setState({
        trainingInfo: trainingField.map((el: StaffTrainingModel, ind) => {
          if (ind === index) {
            return {
              ...el,
              [targetId]: selected.value,
            };
          }
          return el;
        }),
      });
    }
  };
  onDateChange = (value: Date | null, index: number) => {
    const { trainingInfo: trainingField } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        trainingInfo: trainingField.map((el: StaffTrainingModel, ind) => {
          if (ind === index) {
            return {
              ...el,
              date_training_attended: value.toLocaleDateString("en-CA"),
            };
          }
          return el;
        }),
      });
    }
  };
  onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { trainingInfo: trainingField } = this.state;
    this.setState({
      trainingInfo: trainingField.map((el: StaffTrainingModel, ind) => {
        if (ind === index) {
          return {
            ...el,
            [event.target.id]: event.target.value,
          };
        }
        return el;
      }),
    });
  };
  handleOpenClick = (message: string) => {
    this.setState({ provider: message }, () => {
      this.setState({ isOpen: true });
    });
  };
  onSubmit = async (event: React.FormEvent, index: number) => {
    event.preventDefault();
    const { trainingInfo, userInfo } = this.state;
    try {
      const valueArr: any = [];
      trainingInfo.map((el: StaffTrainingModel, ind) => {
        if (ind === index) {
          valueArr.push(el);
        }
        return el;
      });
      const response = await StaffServices.createTraining({
        ...valueArr[0],
        status: "completed",
      });
      if (response) {
        this.getAllTrainings(this.state.staffId, userInfo.provider_id);
        this.setState({
          openAlert: true,
          alertMsg: "Training Added Successfully",
          alertType: "success",
        });
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };

  onAddTrainingInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      addTraining: {
        ...this.state.addTraining,
        [event.target.id]: event.target.value,
      },
    });
  };
  onAddTrainDropDownChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        addTraining: {
          ...this.state.addTraining,
          [targetId]: selected.value,
        },
      });
    }
  };
  handleSaveDocName = async () => {
    const { addTraining, userInfo, staffId } = this.state;

    const response = await StaffServices.addTrainingName(addTraining);

    this.getAllTrainings(staffId, userInfo.provider_id);

    if (response) {
      this.setState({
        openAlert: true,
        alertMsg: "Training Added Successfully",
        alertType: "success",
        isAddTraining: false,
      });
    }
  };
  render() {
    const {
      openAlert,
      alertMsg,
      alertType,
      trainingInfo,
      isOpen,
      provider,
      isAddTraining,
      addTraining,
    } = this.state;
    //console.log(this.state.trainingInfo);
    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <ModalDialog
          isOpen={isOpen}
          dialogWidth="sm"
          title="View Training Provider"
          onClose={() => {
            this.setState({
              isOpen: false,
            });
          }}
        >
          <Grid item>
            <Typography>
              <span style={{ color: "GrayText", marginRight: 10 }}>
                Training Provider :
              </span>
              {provider}
            </Typography>
          </Grid>
        </ModalDialog>
        <ModalDialog
          isOpen={isAddTraining}
          dialogWidth="md"
          title="Add Training Name"
          onClose={() => {
            this.setState({
              isAddTraining: false,
            });
          }}
        >
          <Grid item container>
            <Grid item xs={3.5} mr={3}>
              <TextInputField
                id="training_name"
                value={addTraining.training_name}
                onChange={this.onAddTrainingInputChange}
                placeholder="Enter Training Name"
              />
            </Grid>
            <Grid item xs={3.5} mr={3}>
              <TextInputField
                id="training_provider"
                value={addTraining.training_provider}
                onChange={this.onAddTrainingInputChange}
                placeholder="Enter Training Provider"
              />
            </Grid>
            <Grid item xs={3.5} mr={3}>
              <TextInputField
                id="training_code"
                value={addTraining.training_code}
                onChange={this.onAddTrainingInputChange}
                placeholder="Enter Training Code"
              />
            </Grid>
          </Grid>
          <Grid item container mt={2.5}>
            <Grid item xs={3.5} mr={3}>
              <TextInputField
                id="timeframe"
                value={addTraining.timeframe}
                onChange={this.onAddTrainingInputChange}
                placeholder="Enter Timeframe"
              />
            </Grid>
            <Grid item xs={3.5} mr={3}>
              <SingleSelect
                customOptions="Select Mandatory"
                value={addTraining.is_madatory}
                id="is_madatory"
                size="small"
                values={[
                  { text: "Yes", value: "Yes" },
                  { text: "No", value: "No" },
                ]}
                onChange={this.onAddTrainDropDownChange}
              />
            </Grid>

            <Grid item xs={2}>
              <Button
                disabled={addTraining.training_name?.length > 0 ? false : true}
                style={{
                  height: "2.5rem",
                  textTransform: "none",
                  marginLeft: 5,
                }}
                variant="contained"
                onClick={this.handleSaveDocName}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </ModalDialog>
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container justifyContent="space-between">
              <Typography
                marginBottom={1}
                style={{ fontSize: "1rem", fontWeight: 550 }}
              >
                Training
              </Typography>
              <Button
                style={{
                  height: "2.5rem",
                  textTransform: "none",
                  marginLeft: 5,
                }}
                variant="contained"
                onClick={() => {
                  this.setState({
                    isAddTraining: true,
                  });
                }}
              >
                + Add Training Name
              </Button>
            </Grid>
            <hr />
            <TableContainer component={Paper}>
              <Grid
                container
                item
                flexDirection={"column"}
                marginTop={3}
                paddingLeft={2}
                paddingRight={2}
              >
                <Grid container item columnSpacing={5}>
                  <Grid item xs={1.5}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Training Name
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Training Code
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Is Mandatory?
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Training Attended
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Training Provider
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Sofy Copy Attendance
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Training Cost
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Training related/included in PIP
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Follow up Notes
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      marginBottom={1}
                      style={{ fontSize: "0.95rem", fontWeight: 550 }}
                    >
                      Action
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <hr />
              {trainingInfo.map((el: StaffTrainingModel, index: number) => {
                return (
                  <Grid
                    container
                    item
                    flexDirection={"column"}
                    paddingLeft={2}
                    paddingRight={2}
                    key={index}
                  >
                    <Grid container item mt={5} columnSpacing={5}>
                      <Grid item xs={1.5}>
                        <Typography
                          marginBottom={1}
                          style={{
                            fontSize: "1rem",
                            fontWeight: 400,
                            marginRight: 2,
                          }}
                        >
                          {el.training_name}
                          {"  "}
                          <LightTooltip
                            placement="bottom"
                            title={`Timeframe : ${el.timeframe}`}
                            arrow
                          >
                            <FontAwesomeIcon
                              style={{ marginLeft: 2, color: "grey" }}
                              icon={faCircleInfo}
                            />
                          </LightTooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 400 }}
                        >
                          {el.training_code ? el.training_code : "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 400 }}
                        >
                          {el.is_mandatory}
                        </Typography>
                      </Grid>
                      <Grid item xs={1.5}>
                        <DatePicker
                          value={new Date(el.date_training_attended)}
                          onChange={(value) => this.onDateChange(value, index)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          variant="text"
                          style={{ textTransform: "none" }}
                          onClick={() =>
                            this.handleOpenClick(el.training_provider)
                          }
                        >
                          View
                        </Button>
                      </Grid>
                      <Grid item xs={1}>
                        <SingleSelect
                          value={el.soft_copy_attendance}
                          id="soft_copy_attendance"
                          size="small"
                          values={[
                            { text: "Yes", value: "Yes" },
                            { text: "No", value: "No" },
                          ]}
                          onChange={(
                            selected: SelectModel,
                            targetId?: string | undefined
                          ) => this.onDropDownChange(selected, targetId, index)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextInputField
                          id="training_cost"
                          value={el.training_cost}
                          onChange={(event: any) =>
                            this.onInputChange(event, index)
                          }
                          placeholder="Cost"
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <SingleSelect
                          value={el.included_in_pip}
                          id="included_in_pip"
                          size="small"
                          values={[
                            { text: "Yes", value: "Yes" },
                            { text: "No", value: "No" },
                          ]}
                          onChange={(
                            selected: SelectModel,
                            targetId?: string | undefined
                          ) => this.onDropDownChange(selected, targetId, index)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextAreaInputField
                          minRows={1}
                          isRequired={false}
                          id="follow_up_notes"
                          value={el.follow_up_notes}
                          onChange={(event: any) =>
                            this.onInputChange(event, index)
                          }
                          placeholder="Notes"
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          marginBottom={1}
                          style={{ fontSize: "1rem", fontWeight: 400 }}
                        >
                          {el.status ? el.status : "Pending"}
                        </Typography>
                      </Grid>
                      {el.status === "completed" ? null : (
                        <Grid item xs={1}>
                          <Button
                            disabled={el.date_training_attended ? false : true}
                            style={{
                              height: "2.5rem",
                              textTransform: "none",
                              marginLeft: 5,
                            }}
                            variant="contained"
                            onClick={(event) => this.onSubmit(event, index)}
                          >
                            Save
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(StaffTraining);
