import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IconType } from "../../Components/Button/IconButton";

import SelectModel from "../../Components/Select/SelectModel";
import DataLoader from "../../Components/Table/DataLoader";

import ModalDialog from "../../Components/Modal/ModelDialog";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { AssociatedContactModel } from "../../Models/AssociatedContact";

import AssociateContactService from "../../Services/AssociatedContactService";
const AssociateContactServices = new AssociateContactService();

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  showEdit: boolean;
  isAddContact: boolean;
  recordMarked: any;
  searchText: string;
  contactInfo: AssociatedContactModel;
  countryData: SelectModel[];
  stateData: SelectModel[];
  cityData: SelectModel[];
  roleData: SelectModel[];
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isEdit: boolean;
  filterData: any;
  clientId: any;
  userInfo: any;
}

class AssociatedContactData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      showEdit: false,
      isAddContact: false,
      recordMarked: {},
      searchText: "",
      contactInfo: {} as AssociatedContactModel,
      countryData: [],
      stateData: [],
      cityData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isEdit: false,
      filterData: [],
      roleData: [],
      clientId: "",
      userInfo: {},
    };
  }

  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        const response = await AssociateContactServices.getAllAssociateContact(
          clientId,
          JSON.parse(userInfo).provider_id
        );

        const data = response.data.map((el: AssociatedContactModel) => {
          return {
            Id: el.client_assocontact_id,
            name: el.name,
            organisation: el.organisation,
            phone: el.phone,
            relationship: el.relationship,
            status: el.status,
            email: el.email,
          };
        });
        //console.log(data);
        this.setState({
          tableData: data,
          totalRecordsCount: data.length,
          clientId: clientId,
          userInfo: JSON.parse(userInfo),
        });
      }
    }
  }

  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        const response = await AssociateContactServices.getAssociateById(
          record.Id,
          this.state.userInfo.provider_id
        );
        this.setState({
          contactInfo: response.data,
          isAddContact: true,
          isEdit: true,
        });
        break;
      case "Delete":
        const deleteResponse =
          await AssociateContactServices.deleteAssociatedContact(
            this.state.clientId,
            record.Id,
            this.state.userInfo.provider_id
          );
        if (deleteResponse) {
          const response =
            await AssociateContactServices.getAllAssociateContact(
              this.state.clientId,
              this.state.userInfo.provider_id
            );

          const data = response.data.map((el: AssociatedContactModel) => {
            return {
              Id: el.client_assocontact_id,
              name: el.name,
              organisation: el.organisation,
              phone: el.phone,
              relationship: el.relationship,
              status: el.status,
              email: el.email,
            };
          });
          //console.log(data);
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Contact Deleted Successfully",
            alertType: "success",
          });
        }
        break;
      case "Clone":
        break;
    }
  };

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      contactInfo: {
        ...this.state.contactInfo,
        [event.target.id]: event.target.value,
      },
    });
    if (event.target.id === "phone") {
      this.setState({
        contactInfo: {
          ...this.state.contactInfo,
          phone: Number(event.target.value),
        },
      });
    }
  };

  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    console.log(targetId);
    if (targetId) {
      this.setState({
        contactInfo: {
          ...this.state.contactInfo,
          [targetId]: selected.value,
          client_id: this.state.clientId,
        },
      });
    }
  };

  onAssociateContactSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { contactInfo, isEdit, userInfo } = this.state;
      if (isEdit) {
        const updatedResponse =
          await AssociateContactServices.UpdateAssociatedContact({
            ...contactInfo,
            provider_id: userInfo.provider_id,
          });
        if (updatedResponse) {
          const response =
            await AssociateContactServices.getAllAssociateContact(
              this.state.clientId,
              this.state.userInfo.provider_id
            );

          const data = response.data.map((el: AssociatedContactModel) => {
            return {
              Id: el.client_assocontact_id,
              name: el.name,
              organisation: el.organisation,
              phone: el.phone,
              relationship: el.relationship,
              status: el.status,
              email: el.email,
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Contact Updated Successfully",
            alertType: "success",
            isAddContact: false,
            contactInfo: {
              ...this.state.contactInfo,
              status: "",
              relationship: "",
              name: "",
              organisation: "",
              email: "",
              phone: 0,
            },
          });
        }
      } else {
        const response = await AssociateContactServices.createAssociatedContact(
          {
            ...contactInfo,
            provider_id: userInfo.provider_id,
          }
        );
        if (response) {
          const response =
            await AssociateContactServices.getAllAssociateContact(
              this.state.clientId,
              this.state.userInfo.provider_id
            );

          const data = response.data.map((el: AssociatedContactModel) => {
            return {
              Id: el.client_assocontact_id,
              name: el.name,
              organisation: el.organisation,
              phone: el.phone,
              relationship: el.relationship,
              status: el.status,
              email: el.email,
            };
          });
          //console.log(data);
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Contact Created Successfully",
            alertType: "success",
            isAddContact: false,
            contactInfo: {
              ...this.state.contactInfo,
              status: "",
              relationship: "",
              name: "",
              organisation: "",
              email: "",
              phone: 0,
            },
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onCancelClick = () => {
    this.setState({
      contactInfo: {
        ...this.state.contactInfo,
        status: "",
        relationship: "",
        name: "",
        organisation: "",
        email: "",
        phone: 0,
      },
      isAddContact: false,
    });
  };
  relationshipValue: SelectModel[] = [
    { text: "Support Coordinator", value: "Support Coordinator" },
    { text: "Plan Manager", value: "Plan Manager" },
    { text: "Family Member", value: "Family Member" },
    { text: "Friend", value: "Friend" },
    { text: "Public Guardian", value: "Public Guardian" },
    { text: "Support Worker (External)", value: "Support Worker (External)" },
    {
      text: "Behaviour Support Practitioner",
      value: "Behaviour Support Practitioner",
    },
    { text: "Therapist", value: "Therapist" },
    { text: "GP", value: "GP" },
    { text: "Specialist", value: "Specialist" },
    { text: "Advocate", value: "Advocate" },
    { text: "SIL provider contact", value: "SIL provider contact" },
    { text: "SDA Provider contact", value: "SDA Provider contact" },
    {
      text: "External NDIS Provider contact",
      value: "External NDIS Provider contact",
    },
    { text: "Other (Specify)", value: "Other (Specify)" },
  ];
  statusValue: SelectModel[] = [
    { text: "Current", value: "Current" },
    { text: "Previous", value: "Previous" },
  ];
  render() {
    const {
      tableData,
      totalRecordsCount,
      rows,
      page,
      isAddContact,
      contactInfo,
      openAlert,
      alertMsg,
      isEdit,
      alertType,
    } = this.state;

    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <ModalDialog
          isOpen={isAddContact}
          dialogWidth="sm"
          title={isEdit ? "Edit Associated Contact" : "Add Associated Contact"}
          onClose={() => {
            this.setState({
              isAddContact: false,
              isEdit: false,
              contactInfo: {} as AssociatedContactModel,
            });
          }}
        >
          <form onSubmit={this.onAssociateContactSave}>
            <Grid container item flexDirection={"column"}>
              <Grid container item marginTop={3}>
                <Grid item xs={3}>
                  <FormLabel sx={{ fontSize: "0.9em" }}>Status :</FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <SingleSelect
                    value={contactInfo.status}
                    id="status"
                    size="small"
                    values={this.statusValue}
                    onChange={this.onDropDownChange}
                  />
                </Grid>
              </Grid>
              <Grid container item marginTop={3}>
                <Grid item xs={3}>
                  <FormLabel sx={{ fontSize: "0.9em" }}>
                    Relationship :
                  </FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <SingleSelect
                    value={contactInfo.relationship}
                    id="relationship"
                    size="small"
                    values={this.relationshipValue}
                    onChange={this.onDropDownChange}
                  />
                </Grid>
              </Grid>
              <Grid container item marginTop={3}>
                <Grid item xs={3}>
                  <FormLabel sx={{ fontSize: "0.9em" }}>Name :</FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextInputField
                    id="name"
                    value={contactInfo.name}
                    onChange={this.onInputChange}
                    placeholder="Name"
                  />
                </Grid>
              </Grid>
              <Grid container item marginTop={3}>
                <Grid item xs={3}>
                  <FormLabel sx={{ fontSize: "0.9em" }}>
                    Organisation :
                  </FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextInputField
                    id="organisation"
                    value={contactInfo.organisation}
                    onChange={this.onInputChange}
                    placeholder="Organisation"
                  />
                </Grid>
              </Grid>
              <Grid container item marginTop={3}>
                <Grid item xs={3}>
                  <FormLabel sx={{ fontSize: "0.9em" }}>Email :</FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextInputField
                    id="email"
                    value={contactInfo.email}
                    onChange={this.onInputChange}
                    placeholder="Email"
                  />
                </Grid>
              </Grid>
              <Grid container item marginTop={3}>
                <Grid item xs={3}>
                  <FormLabel sx={{ fontSize: "0.9em" }}>
                    Contact Number :
                  </FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextInputField
                    id="phone"
                    value={contactInfo.phone}
                    onChange={this.onInputChange}
                    placeholder="contact"
                  />
                </Grid>
              </Grid>

              <Grid container item marginTop={5} justifyContent="flex-end">
                {isEdit ? (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                )}
                <Button
                  onClick={this.onCancelClick}
                  style={{ textTransform: "capitalize", marginLeft: 7 }}
                  variant="outlined"
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Grid item container>
              <Grid item xs={9}>
                <Typography
                  marginBottom={1}
                  style={{ fontSize: "1rem", fontWeight: 550 }}
                >
                  Associated Contacts
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                <Button
                  style={{ textTransform: "capitalize" }}
                  variant="contained"
                  onClick={() => {
                    this.setState({ isAddContact: true });
                  }}
                >
                  + Add Associated Contacts
                </Button>
              </Grid>
            </Grid>
            <hr />

            <Box marginBottom={2}>
              <DataLoader
                totalRecordsCount={totalRecordsCount}
                onChangePage={this.onChangePage}
                onChangeRow={this.onChangeRow}
                onActionClick={this.onActionClick}
                tableData={
                  rows > 0
                    ? tableData.slice(page * rows, page * rows + rows)
                    : tableData
                }
                actionArray={["Edit", "Delete"]}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(AssociatedContactData);
