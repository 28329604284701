import { Grid } from "@mui/joy";
import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

interface RichTextEditorState {
  content: string;
}

interface Props {
  value: string;
  onChange: (content: string) => void;
}

class RichTextEditor extends React.Component<Props, RichTextEditorState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      content: "",
    };
  }

  handleChange = (content: string) => {
    this.props.onChange(content);
  };

  render() {
    const { content } = this.state;
    const { value } = this.props;
    return (
      <Grid>
        <ReactQuill
          style={{ height: "13rem" }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "color",
            "background",
            "align",
          ]}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "strike", "blockquote"],

              [{ align: ["right", "center", "justify"] }],
              [{ list: "bullet" }],

              [{ color: ["red", "#785412", "black"] }],
              [{ background: ["red", "#785412", "#f8e653a1"] }],
            ],
          }}
          value={value}
          onChange={this.handleChange}
        />
      </Grid>
    );
  }
}

export default RichTextEditor;
