import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
} from "@mui/material";

import { EventType } from "../../Common/Enums";
import CheckBoxModel from "./CheckBoxModel";

interface Props {
  id?: string;
  value: any;
  label: string;
  disabled?: boolean;
  isChecked?: boolean;

  onChange: (selected: any, eventType: EventType, targetId?: string) => void;
}

interface State {
  checked: boolean;
}

class SingleCheckBox extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      checked: false,
    };
  }
  componentDidMount() {
    if (this.props.isChecked) {
      this.setState({ checked: true });
    }
  }
  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const { id } = this.props;
    this.setState({ checked: event.target.checked });

    const eventType = this.state.checked ? EventType.ADD : EventType.REMOVE;
    this.props.onChange(value, eventType, id);
  };

  render() {
    const { value, label, disabled, id, isChecked } = this.props;

    return (
      <FormControl>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  id={id}
                  value={value}
                  checked={this.state.checked}
                  onChange={this.handleCheckboxChange}
                  disabled={disabled}
                />
              }
              label={label}
            />
          </Grid>
        </Grid>
      </FormControl>
    );
  }
}

export default SingleCheckBox;
