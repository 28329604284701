import { URIs } from "../Common/Constants";
import GenericAPIService from "./GenericAPIService";

const { GET_SERVICE_DROPDOWN, GET_STAFF_ROSTER, ROSTER_CLIENT, ROSTER_STAFF } =
  URIs;

class NewRosterService extends GenericAPIService {
  async getClientDropDownValue(client_id: number) {
    console.log(GET_SERVICE_DROPDOWN);
    const data: any = await this.getAsync(
      `${GET_SERVICE_DROPDOWN}?provider_id=${client_id}`
    );
    //console.log("data",data)
    return data;
  }

  async getStaffDropDownValue(providerId: number) {
    const data: any = await this.getAsync(
      `${GET_STAFF_ROSTER}?provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }

  async getStaffData(staffId: number,providerId: number) {
    const data: any = await this.getAsync(
      `${ROSTER_STAFF}?staff_id=${staffId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
  async getClientData(clientId: number,providerId: number) {
    const data: any = await this.getAsync(
      `${ROSTER_CLIENT}?client_id=${clientId}&provider_id=${providerId}`
    );
    //console.log("data",data)
    return data;
  }
}

export default NewRosterService;
