import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import DatePicker from "../../Components/DatePicker/DatePicker";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { NDISPlanModel } from "../../Models/NDISPlanModel";
import NDISService from "../../Services/NDISService";

const NDISServices = new NDISService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  clientId: string | null;
  NDISInfo: NDISPlanModel;
  isEdit: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  userInfo: any;
}

class NDISPlan extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      clientId: "",
      NDISInfo: {} as NDISPlanModel,
      isEdit: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      userInfo: {},
    };
  }
  async componentDidMount() {
    if (this.props.location) {
      var url = this.props.location.pathname.toString();
      var regex = /\/(\d+)$/; // Match one or more digits at the end of the URL
      var match = url.match(regex);
      const userInfo = sessionStorage.getItem("userInfo");

      if (match && userInfo) {
        var clientId = parseInt(match[1]);
        this.setState({
          NDISInfo: {
            ...this.state.NDISInfo,
            client_id: clientId,
            provider_id: JSON.parse(userInfo).provider_id,
          },
          userInfo: JSON.parse(userInfo),
        });

        const response = await NDISServices.getSelectedNDIS(
          clientId,
          JSON.parse(userInfo).provider_id
        );
        if (Object.keys(response.data).length > 0) {
          this.setState({
            NDISInfo: response.data,
            isEdit: true,
          });
        }
      }
    }
  }
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      NDISInfo: {
        ...this.state.NDISInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        NDISInfo: {
          ...this.state.NDISInfo,
          [targetId]: selected.value,
        },
      });
    }
  };
  onDateChange = (value: Date | null) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        NDISInfo: {
          ...this.state.NDISInfo,
          ndisplan_startdate: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };
  onReviewDateChange = (value: Date | null) => {
    if (value) {
      var date1 = new Date(value.toLocaleDateString());
      var date2 = new Date();

      var differenceMs = date1.getTime() - date2.getTime();

      // Calculate the difference in days
      var differenceDays = Math.floor(differenceMs / (1000 * 3600 * 24));
      console.log(differenceDays);
      if (differenceDays < 0) {
        this.setState({
          NDISInfo: {
            ...this.state.NDISInfo,
            ndisplan_reviewdate: value.toLocaleDateString("en-CA"),
            ndis_plan_expired: "Yes",
          },
        });
      } else {
        this.setState({
          NDISInfo: {
            ...this.state.NDISInfo,
            ndisplan_reviewdate: value.toLocaleDateString("en-CA"),
            ndis_plan_expired: "No",
          },
        });
      }
    }
  };
  onNDISPlanSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { NDISInfo, isEdit, userInfo } = this.state;

      if (isEdit) {
        const updatedResponse = await NDISServices.UpdateNDIS({
          ...NDISInfo,
          provider_id: userInfo.provider_id,
        });
        if (updatedResponse) {
          this.setState({
            isEdit: true,
            openAlert: true,
            alertMsg: "NDIS Plan Updated Successfully",
            alertType: "success",
          });
        }
      } else {
        const response = await NDISServices.createNDIS({
          ...NDISInfo,
          provider_id: userInfo.provider_id,
        });
        if (response) {
          this.setState({
            isEdit: true,
            openAlert: true,
            alertMsg: "NDIS Plan Created Successfully",
            alertType: "success",
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onCancelClick = () => {
    this.setState({ NDISInfo: {} as NDISPlanModel });
  };
  onDeleteClick = async () => {
    const response = await NDISServices.deleteNDIS(
      this.state.NDISInfo.client_id,
      this.state.userInfo.provider_id
    );
    if (response) {
      this.onCancelClick();
      this.setState({
        isEdit: false,
        openAlert: true,
        alertMsg: "NDIS Plan Deleted Successfully",
        alertType: "success",
      });
    }
  };
  ndisPlanValue: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
    { text: "Unknown", value: "Unknown" },
  ];
  ndisPlanExpiredValue: SelectModel[] = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];
  fundingTypeValue: SelectModel[] = [
    { text: "NDIA", value: "NDIA" },
    { text: "Plan", value: "No" },
    { text: "Self", value: "Self" },
    { text: "Other", value: "Other" },
  ];
  render() {
    const { NDISInfo, isEdit, openAlert, alertMsg, alertType } = this.state;

    return (
      <Box>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Card
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
          }}
        >
          <CardContent>
            <Typography
              marginBottom={1}
              style={{ fontSize: "1rem", fontWeight: 550 }}
            >
              NDIS Plan
            </Typography>
            <hr />
            <form onSubmit={this.onNDISPlanSave}>
              <Grid container marginTop={5}>
                <Grid container item>
                  <Grid item xs={2}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      NDIS Plan Number :
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <TextInputField
                      id="ndisplan_number"
                      value={NDISInfo.ndisplan_number}
                      onChange={this.onInputChange}
                      placeholder="Enter NDIS Plan Number"
                    />
                  </Grid>
                </Grid>
                <Grid container item marginTop={4}>
                  <Grid item xs={2}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      NDIS Plan :
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <SingleSelect
                      value={
                        NDISInfo.ndisplan === "Yes"
                          ? "Yes"
                          : NDISInfo.ndisplan === "No"
                          ? "No"
                          : NDISInfo.ndisplan === "Unknown"
                          ? "Unknown"
                          : ""
                      }
                      id="ndisplan"
                      size="small"
                      values={this.ndisPlanValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item marginTop={4}>
                  <Grid item xs={2}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      NDIS Plan Start Date :
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      width="18.3rem"
                      value={new Date(NDISInfo.ndisplan_startdate)}
                      onChange={(value) => this.onDateChange(value)}
                    />
                  </Grid>
                </Grid>
                <Grid container item marginTop={4}>
                  <Grid item xs={2}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      NDIS Plan Review Date :
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      width="18.3rem"
                      value={new Date(NDISInfo.ndisplan_reviewdate)}
                      onChange={(value) => this.onReviewDateChange(value)}
                    />
                  </Grid>
                </Grid>
                <Grid container item marginTop={4}>
                  <Grid item xs={2}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Funding Type :
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <SingleSelect
                      value={
                        NDISInfo.funding_type === "Yes"
                          ? "Yes"
                          : NDISInfo.funding_type === "No"
                          ? "No"
                          : NDISInfo.funding_type === "Unsure"
                          ? "Unsure"
                          : ""
                      }
                      id="funding_type"
                      size="small"
                      values={this.fundingTypeValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item marginTop={4}>
                  <Grid item xs={2}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Is NDIS plan expired ?:
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <SingleSelect
                      isDisabled={true}
                      value={
                        NDISInfo.ndis_plan_expired === "Yes"
                          ? "Yes"
                          : NDISInfo.ndis_plan_expired === "No"
                          ? "No"
                          : ""
                      }
                      id="ndis_plan_expired"
                      size="small"
                      values={this.ndisPlanExpiredValue}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item marginTop={5} justifyContent="flex-start">
                {isEdit ? (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                )}
                <Button
                  onClick={this.onCancelClick}
                  style={{ textTransform: "capitalize", marginLeft: 7 }}
                  variant="outlined"
                >
                  Clear
                </Button>
                {isEdit ? (
                  <Button
                    onClick={this.onDeleteClick}
                    style={{ textTransform: "capitalize", marginLeft: 7 }}
                    variant="outlined"
                  >
                    delete
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withRouter(NDISPlan);
