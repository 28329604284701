import {
  AlertColor,
  Box,
  Button,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IconType } from "../../Components/Button/IconButton";

import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import DataLoader from "../../Components/Table/DataLoader";

import SearchFilter from "../../Components/Search/SearchFilter";

import DatePicker from "../../Components/DatePicker/DatePicker";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { StaffModel } from "../../Models/StaffModel";
import ClientServices from "../../Services/ClientServices";
import StaffServices from "../../Services/StaffService";
const StaffService = new StaffServices();
const ClientService = new ClientServices();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  tableData: any;
  page: number;
  rows: number;
  totalRecordsCount: number;
  showEdit: boolean;
  isAddStaff: boolean;
  recordMarked: any;
  searchText: string;
  staffInfo: StaffModel;
  countryData: SelectModel[];
  stateData: SelectModel[];
  cityData: SelectModel[];
  roleData: SelectModel[];
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isEdit: boolean;
  filterData: any;
  userInfo: any;
}

class ContactData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableData: [],
      page: 0,
      rows: 5,
      totalRecordsCount: 0,
      showEdit: false,
      isAddStaff: false,
      recordMarked: {},
      searchText: "",
      staffInfo: {} as StaffModel,
      countryData: [],
      stateData: [],
      cityData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isEdit: false,
      filterData: [],
      roleData: [],
      userInfo: {},
    };
  }

  async componentDidMount() {
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      const response = await StaffService.getAllStaffs(
        JSON.parse(userInfo).provider_id
      );
      const roleResponse = await StaffService.getAllStaffsRole();

      const countryDataResponse = await ClientService.getCountry();
      this.setState({
        staffInfo: {
          ...this.state.staffInfo,
          Provider_id: JSON.parse(userInfo).provider_id,
        },
        countryData: countryDataResponse.data.map((el: any) => {
          return {
            text: el,
            value: el,
          };
        }),
        roleData: roleResponse.data.map((el: any) => {
          return {
            text: el,
            value: el,
          };
        }),
      });
      const data = response.data.map((el: StaffModel) => {
        return {
          Id: el.Id,
          name: el.Name,
          employment_type: el.Employmenttype,
          phone: el.Phone,
          email: el.Email,
          gender: el.Gender,
          role: el.Role,
        };
      });
      //console.log(data);
      this.setState({
        tableData: data,
        totalRecordsCount: data.length,
        userInfo: JSON.parse(userInfo),
      });
    }
  }

  resetPageNumber = () => {
    this.setState({ page: 0, rows: 5 });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row });
  };
  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        const response = await StaffService.getSelectedStaff(
          record.Id,
          this.state.userInfo.provider_id
        );
        this.setState({
          staffInfo: response.data,
          isAddStaff: true,
          isEdit: true,
        });
        break;
      case "Delete":
        const deleteResponse = await StaffService.deleteStaff(
          record.Id,
          this.state.userInfo.provider_id
        );
        if (deleteResponse) {
          const updatedTableData = await StaffService.getAllStaffs(
            this.state.userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: StaffModel) => {
            return {
              Id: el.Id,
              name: el.Name,
              employment_type: el.Employmenttype,
              phone: el.Phone,
              email: el.Email,
              gender: el.Gender,
              role: el.Role,
            };
          });
          //console.log(data);

          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Staff Deleted Successfully",
            alertType: "success",
          });
        }
        break;
      case "Clone":
        break;
    }
  };

  onClickName = (_fieldClicked: string, record: any) => {
    if (record.Id) {
      this.props.history.push(`/viewstaff/${record.Id}`);
    }
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      staffInfo: {
        ...this.state.staffInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  onSearchChange = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const filterData = this.state.tableData.filter((el: any) =>
      el.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({
      filterData,
    });
    this.setState({ searchText: event.target.value });
  };
  currencyValues: SelectModel[] = [
    { text: "INR", value: "INR" },
    { text: "USD", value: "USD" },
    { text: "AUD", value: "AUD" },
  ];
  genderValues: SelectModel[] = [
    { text: "Male", value: "Male" },
    { text: "Female", value: "Female" },
    { text: "Intersex", value: "Intersex" },
    { text: "Non-binary", value: "Non-binary" },
    { text: "Unspecified", value: "Unspecified" },
    { text: "Prefer not to say", value: "Prefer not to say" },
  ];
  roleValues: SelectModel[] = [
    { text: "Admin", value: "Admin" },
    { text: "Coordinator", value: "Coordinator" },
    { text: "HR", value: "HR" },
    { text: "Office Support", value: "Office Support" },
    { text: "Carer", value: "Carer" },
    { text: "Ops", value: "Ops" },
    { text: "Kiosk", value: "Kiosk" },
  ];
  employmentValues: SelectModel[] = [
    { text: "Employee", value: "Employee" },
    { text: "Contractor", value: "Contractor" },
  ];
  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    console.log(targetId);
    if (targetId) {
      this.setState({
        staffInfo: {
          ...this.state.staffInfo,
          [targetId]: selected.value,
        },
      });
    }
    if (targetId === "Country") {
      this.getState(selected.value);
    }
    if (targetId === "State") {
      this.getCity(selected.value, this.state.staffInfo.Country);
    }
  };

  getState = async (country: string) => {
    const response = await ClientService.getState(country);
    this.setState({
      stateData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };
  getCity = async (state: string, country: string) => {
    const response = await ClientService.getCity(state, country);
    this.setState({
      cityData: response.data.map((el: any) => {
        return {
          text: el,
          value: el,
        };
      }),
    });
  };

  onDateChange = (value: Date | null) => {
    //const { staffInfo } = this.state;
    //console.log(value?.format('YYYY-MM-DD h:mm:ss A'));
    if (value) {
      this.setState({
        staffInfo: {
          ...this.state.staffInfo,
          DateOfBirth: value.toLocaleDateString("en-CA"),
        },
      });
    }
  };

  onStaffSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const { staffInfo, isEdit, userInfo } = this.state;
      if (isEdit) {
        const updatedResponse = await StaffService.UpdateStaff({
          ...staffInfo,
          Provider_id: userInfo.provider_id,
        });
        if (updatedResponse) {
          const updatedTableData = await StaffService.getAllStaffs(
            this.state.userInfo.provider_id
          );
          const data = updatedTableData.data.map((el: StaffModel) => {
            return {
              Id: el.Id,
              name: el.Name,
              employment_type: el.Employmenttype,
              phone: el.Phone,
              email: el.Email,
              gender: el.Gender,
              role: el.Role,
            };
          });
          //console.log(data);
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Staff Updated Successfully",
            alertType: "success",
            isAddStaff: false,
            staffInfo: {} as StaffModel,
            isEdit: false,
          });
        }
      } else {
        const response = await StaffService.createStaff({
          ...staffInfo,
          Provider_id: userInfo.provider_id,
        });
        if (response) {
          const response = await StaffService.getAllStaffs(
            this.state.userInfo.provider_id
          );
          const data = response.data.map((el: StaffModel) => {
            return {
              Id: el.Id,
              name: el.Name,
              employment_type: el.Employmenttype,
              phone: el.Phone,
              email: el.Email,
              gender: el.Gender,
              role: el.Role,
            };
          });
          //console.log(data);
          this.setState({
            tableData: data,
            totalRecordsCount: data.length,
            openAlert: true,
            alertMsg: "Staff Created Successfully",
            alertType: "success",
            isAddStaff: false,
            staffInfo: {} as StaffModel,
            isEdit: false,
          });
        }
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
        isAddStaff: false,
        staffInfo: {} as StaffModel,
        isEdit: false,
      });
    }
  };
  onCancelClick = () => {
    this.setState({ staffInfo: {} as StaffModel, isAddStaff: false });
  };
  render() {
    const {
      tableData,
      totalRecordsCount,
      rows,
      page,
      searchText,
      isAddStaff,
      staffInfo,
      countryData,
      stateData,
      cityData,
      openAlert,
      alertMsg,
      isEdit,
      alertType,
      filterData,
      roleData,
    } = this.state;
    console.log(staffInfo);
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <ModalDialog
          isOpen={isAddStaff}
          title={isEdit ? "Edit Staff" : "Add New Staff"}
          onClose={() => {
            this.setState({
              isAddStaff: false,
              isEdit: false,
              staffInfo: {} as StaffModel,
            });
          }}
        >
          <form onSubmit={this.onStaffSave}>
            <Grid container item flexDirection={"column"}>
              <Grid container item rowSpacing={3}>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Name</FormLabel>
                    <TextInputField
                      id="Name"
                      value={staffInfo.Name}
                      onChange={this.onInputChange}
                      placeholder="Name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Phone</FormLabel>
                    <TextInputField
                      id="Phone"
                      value={staffInfo.Phone}
                      onChange={this.onInputChange}
                      placeholder="Phone"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Email</FormLabel>
                    <TextInputField
                      id="Email"
                      value={staffInfo.Email}
                      onChange={this.onInputChange}
                      placeholder="Email"
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <Grid>
                      <FormLabel sx={{ fontSize: "0.9em" }}>
                        Date of Birth
                      </FormLabel>
                    </Grid>
                    <Grid>
                      <DatePicker
                        width="21.5rem"
                        value={new Date(staffInfo.DateOfBirth)}
                        onChange={(value) => this.onDateChange(value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Gender</FormLabel>
                    <SingleSelect
                      value={staffInfo.Gender}
                      id="Gender"
                      size="small"
                      values={this.genderValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Role</FormLabel>
                    <SingleSelect
                      value={staffInfo.Role}
                      id="Role"
                      size="small"
                      values={roleData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={8}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Address</FormLabel>
                    <TextInputField
                      id="Address"
                      value={staffInfo.Address}
                      onChange={this.onInputChange}
                      placeholder="Address"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>
                      Employment Type
                    </FormLabel>
                    <SingleSelect
                      value={staffInfo.Employmenttype}
                      id="Employmenttype"
                      size="small"
                      values={this.employmentValues}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={7}>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>Country</FormLabel>
                    <SingleSelect
                      value={staffInfo.Country}
                      id="Country"
                      size="small"
                      values={countryData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>State</FormLabel>
                    <SingleSelect
                      customOptions={
                        staffInfo.Country ? "" : "Select Country First"
                      }
                      value={staffInfo.State}
                      id="State"
                      size="small"
                      values={stateData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel sx={{ fontSize: "0.9em" }}>City</FormLabel>
                    <SingleSelect
                      customOptions={
                        staffInfo.State ? "" : "Select State First"
                      }
                      value={staffInfo.City}
                      id="City"
                      size="small"
                      values={cityData}
                      onChange={this.onDropDownChange}
                    />
                  </Grid>
                </Grid>

                <Grid container item justifyContent="flex-end">
                  {isEdit ? (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    onClick={this.onCancelClick}
                    style={{ textTransform: "capitalize", marginLeft: 7 }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>

        <Box style={{ display: "flex" }}>
          <Typography
            marginBottom={5}
            style={{ fontSize: "1.7rem", fontWeight: 550 }}
          >
            Staff
          </Typography>
        </Box>
        <Grid container marginBottom={4}>
          <Grid item xs={6}>
            <SearchFilter
              id="searchText"
              placeholder="Search"
              value={searchText}
              onChange={this.onSearchChange}
            />
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              onClick={() => {
                this.setState({ isAddStaff: true });
              }}
            >
              + Add New Staff
            </Button>
          </Grid>
        </Grid>
        <Box>
          <DataLoader
            totalRecordsCount={
              searchText ? filterData.length : totalRecordsCount
            }
            onChangePage={this.onChangePage}
            onChangeRow={this.onChangeRow}
            onActionClick={this.onActionClick}
            tableData={
              searchText
                ? rows > 0
                  ? filterData.slice(page * rows, page * rows + rows)
                  : filterData
                : rows > 0
                ? tableData.slice(page * rows, page * rows + rows)
                : tableData
            }
            onClickLink={this.onClickName}
            actionArray={["Edit", "Delete"]}
          />
        </Box>
      </Box>
    );
  }
}

export default withRouter(ContactData);
